import { oids } from 'configs';

export default class fhirFormat {
  static Practitioner(professional) {
    const practitioner = {
      id: professional.reference,
      resourceType: 'Practitioner',
      name: [...professional.name],
      active: true,
      telecom: [...professional.telecom],
      birthDate: professional.birthDate,
      identifier: [...professional.identifier],
    };
    return JSON.stringify(practitioner);
  }

  static Slot(body, scheduleId) {
    const slot = {
      resourceType: 'Slot',
      ...body,
      schedule: {
        reference: `Schedule/${scheduleId}`,
      },
    };
    return JSON.stringify(slot);
  }

  static Schedule(body) {
    const schedule = {
      resourceType: 'Schedule',
      active: true,
      specialty: body.specialty ? [...body.specialty] : null,
    };

    if (body.professional) {
      schedule.actor = [
        {
          reference: `Practitioner/${body.professional.reference}`,
        },
        {
          reference: `Location/${body.office}`,
        },
      ];
    } else {
      schedule.actor = [{ reference: `Location/${body.office}` }];
    }
    if (body.id) schedule.id = body.id;
    return JSON.stringify(schedule);
  }

  static PractitionerRole(organizationReference, professional) {
    const practitionerRole = {
      resourceType: 'PractitionerRole',
      identifier: professional.identifier,
      practitioner: {
        reference: `Practitioner/${professional.reference}`,
      },
      organization: {
        reference: `Organization/${oids.organization}-${organizationReference}`,
      },
      specialty: [...professional.specialty],
    };
    if (professional.practitionerRole)
      practitionerRole.id = professional.practitionerRole;

    return JSON.stringify(practitionerRole);
  }

  static Location(office, organizationReference, id = null) {
    const location = {
      resourceType: 'Location',
      status: 'active',
      name: office.name,
      managingOrganization: {
        reference: `Organization/${oids.organization}-${organizationReference}`,
      },
      hoursOfOperation: {
        allDay: true,
        daysOfWeek: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
        openingTime: null,
        closingTime: null,
      },
    };

    if (id) location.id = id;
    return JSON.stringify(location);
  }

  static Bundle({ method, entry }) {
    let bundleEntry = [];
    entry = entry.filter((item) => item !== null);
    if (method === 'POST') {
      bundleEntry = entry.map((recurso) => {
        const obj = {
          resource: recurso,
          request: {
            method,
            url: recurso.resourceType,
          },
        };
        return obj;
      });
    } else if (method === 'GET') {
      bundleEntry = entry.map((urlGet) => {
        const obj = {
          request: {
            method,
            url: urlGet,
          },
        };
        return obj;
      });
    } else if (method === 'DELETE') {
      bundleEntry = entry.map((urlDelete) => {
        const obj = {
          request: {
            method,
            url: urlDelete,
          },
        };
        return obj;
      });
    } else if (method === 'PUT') {
      bundleEntry = entry.map((resource) => {
        const obj = {
          resource,
          request: {
            method,
            url: `${resource.resourceType}/${resource.id}`,
          },
        };
        return obj;
      });
    }

    const bundle = {
      resourceType: 'Bundle',
      type: 'batch',
      entry: [...bundleEntry],
    };

    return JSON.stringify(bundle);
  }
}
