import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, TableCell, TableContainer, TableHead, TableRow, Table,
} from '@material-ui/core';
import fhirRequests from 'services/fhirRequests';
import { Button, SimpleModal } from 'components';
// import { toast } from 'react-toastify';
import { setISODateToLocale } from 'utils/dateFunctions';

function FilesTable({
  files,
  //  reloadTable,
}) {
  const [modal, setModal] = useState({ visible: false, cancelButton: 'Voltar' });

  // const deleteDocument = async ({ documentReferenceId, binaryId }) => {
  //   const deleteResponse = await fhirRequests
  //     .deleteDocumentReference({
  //       documentReferenceId,
  //       binaryId,
  //     });
  //   setModal({ visible: false });
  //   if (typeof deleteResponse !== 'string') toast
  //     .success('Arquivo deletado com sucesso!');
  //   else toast.error('Ocorreu um erro. Tente novamente.');
  //   reloadTable();
  // };

  // const openDeleteDocumentModal = async ({
  //   documentReferenceId, binaryId,
  // }) => {
  //   setModal({
  //     ...modal,
  //     title: 'Atenção!',
  //     body: 'Essa ação é irreversível. Deseja excluir esse arquivo?',
  //     footer: (
  //       <Button
  //         color="secondary" variant="contained"
  //         onClick={() => deleteDocument({
  //           documentReferenceId, binaryId,
  //         })}
  //       >
  //         Excluir
  //       </Button>
  //     ),
  //     maxWidth: 'sm',
  //     visible: true,
  //   });
  // };

  const openBinary = async ({ binaryId }) => {
    const binary = await fhirRequests.getBinary({ binaryId });

    const binaryType = binary?.contentType;
    const binaryData = binary?.data;
    const binarySrc = `data:${binaryType};base64,${binaryData}`;
    setModal({
      ...modal,
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Ver Arquivo
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.open(binarySrc)}
          >
            Download
          </Button>
        </div>),
      body: (
        <embed
          src={binarySrc}
          type={binaryType} width="100%"
          height="450rem"
        />
      ),
      footer: null,
      maxWidth: 'xl',
      visible: true,
    });
  };

  const openDocumentButton = ({ binaryId }) => (
    <Button
      color="custom"
      variant="contained"
      onClick={() => openBinary({ binaryId })}
    >
      Abrir
    </Button>
  );

  // const deleteDocumentButton = ({ documentReferenceId, binaryId }) => (
  //   <Button
  //     color="secondary"
  //     variant="contained"
  //     onClick={() => {
  //       openDeleteDocumentModal({ documentReferenceId, binaryId });
  //     }}
  //   >
  //     Excluir
  //   </Button>
  // );

  return (
    <>
      <SimpleModal modal={modal} setModal={setModal} />
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                Data
              </TableCell>
              <TableCell>
                Grupo de Atendimento
              </TableCell>
              <TableCell>
                Opções
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { Array.isArray(files)
            && files.map((file) => (
              <TableRow>
                <TableCell>
                  {file?.creationTime ? setISODateToLocale(file.creationTime) : ''}
                </TableCell>
                <TableCell>
                  {file?.groupName}
                </TableCell>
                <TableCell>
                  {openDocumentButton({ binaryId: file.binaryId })}
                  {/* {deleteDocumentButton({
                    binaryId: file.binaryId,
                    documentReferenceId: file.id,
                  })} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

FilesTable.defaultProps = {
  // reloadTable: () => null,
};

FilesTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.node).isRequired,
  // reloadTable: PropTypes.func,
};

export { FilesTable };
