import React from 'react';
import PropTypes from 'prop-types';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { docTypes } from 'configs/env';
import { useTranslation } from 'react-i18next';

function TimelineFilterSelector({ onChooseType, display }) {
  const { t } = useTranslation('PatientSummary');
  let filterTypes = [];
  docTypes.map((item) => {
    if (item.name !== 'Resultados de Exames') {
      filterTypes.push(item);
    }
  });
  return (
    <DropdownButton
      variant="outline-dark"
      title={display}
      id="bg-nested-dropdown"
    >
      <Dropdown.Item
        variant="outline-dark"
        eventKey="0"
        onClick={() => {
          onChooseType({
            types: [],
            display: 'Todos',
          });
        }}
      >
        {t('TimelineDrawer-patientSummary-timeline-chooseFilter-all')}
      </Dropdown.Item>
      {
        filterTypes.map((item, i) => (
          <Dropdown.Item
            key={item.name}
            variant="outline-dark"
            eventKey={i.toString()}
            onClick={() => {
              onChooseType({
                types: [...item.codes],
                display: item.name,
              });
            }}
          >
            {item.name}
          </Dropdown.Item>
        ))
      }
    </DropdownButton>
  );
}

TimelineFilterSelector.propTypes = {
  onChooseType: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
};

export { TimelineFilterSelector };
