import hash from 'object-hash';
import base64 from 'base-64';
import moment from 'moment';
import uuid from 'uuid';
import {
  retrieveData,
  fetchWithTimeout,
  verifyCPF,
  logDebug,
} from 'utils/AuxiliarFunctions';
import { endpoints, oids } from 'configs';
import xdsToken from './xdsToken';
import {
  policy_opt_out,
  policy_opt_in,
  policy_request,
  appc_consent_request_fhir,
} from './requestTemplate';

const { CONSENT_api, APPCFHIR } = endpoints;

export default class policyRequests {
  static catchError = (err, from) => {
    logDebug(`ERROR in policyRequests: ${from}: `, err);
    switch (parseInt(err.message, 10)) {
      case 500:
        return 'internal_error';
      case 504:
        return 'timeout';
      case 400:
        return 'bad_request';
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 409:
        return 'conflict';
      default:
        return 'network_error';
    }
  };

  static deepCopy(oldObj) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
      newObj =
        Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
      for (const i in oldObj) newObj[i] = policyRequests.deepCopy(oldObj[i]);
    }
    return newObj;
  }

  static async getPolicyRequest(patient_id) {
    const url = `${CONSENT_api}/recurso/${patient_id}/politica`;
    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const consent_request = {
      headers: {
        Authorization: `Bearer ${applicationToken}`,
        'Content-Type': 'application/json',
        'X-User-Token': `${token}`,
      },
    };

    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      consent_request,
    )
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => policyRequests.catchError(err, 'getPolicyRequest'));

    if (typeof fetchWithTimeout_response === 'string')
      return fetchWithTimeout_response;

    const responseJson = await fetchWithTimeout_response.json();

    // eslint-disable-next-line no-restricted-syntax
    // logDebug('responseJson1', responseJson);
    if (responseJson.politicaRES.includes('Opt-in'))
      return { politicaRES: 'Opt-in' };

    return { politicaRES: 'Opt-out' };
  }

  static async setPolicyRequest(policy, cpf) {
    // const url = XDSB_domain + REPOSITORY_uri;
    const patient_id = cpf;

    const url = APPCFHIR;
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';
    // logDebug('policy', policy);
    const appc_request = policyRequests.deepCopy(policy_request);

    const rootVal = `${verifyCPF(patient_id) ? oids.cpf : oids.cns}`;

    const role = {
      $: {
        classificationScheme: 'urn:uuid:cccf5598-8b07-4b77-a05e-ae952c785ead',
        classifiedObject: 'doc1',
        id: 'cl9',
        objectType:
          'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
        nodeRepresentation: '223565',
      },
      'rim:Slot': [
        {
          $: {
            name: 'codingScheme',
          },
          'rim:ValueList': [
            {
              'rim:Value': ['1.3.6.1.4.1.54443.1.1.6.1'],
            },
          ],
        },
      ],
      'rim:Name': [
        {
          'rim:LocalizedString': [
            {
              $: {
                value: 'Enfermeiro da estratégia de saúde da família',
              },
            },
          ],
        },
      ],
    };
    const submission_slot = {
      $: {
        name: 'submissionTime',
      },
      'rim:ValueList': [
        {
          'rim:Value': [`${moment(new Date()).format('YYYYMMDDHHmmss')}`],
        },
      ],
    };
    const submission_id = {
      $: {
        identificationScheme: 'urn:uuid:96fdda7c-d067-4183-912e-bf5ee74998a8',
        id: 'ei3',
        objectType:
          'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
        value: `1.42.20130403134532.123.${moment(new Date()).format(
          'YYYYMMDDHHmmss',
        )}.1`,
        registryObject: 'ss1',
      },
      'rim:Name': [
        {
          'rim:LocalizedString': [
            {
              $: {
                value: 'XDSSubmissionSet.uniqueId',
              },
            },
          ],
        },
      ],
    };
    const doc_id = {
      $: {
        identificationScheme: 'urn:uuid:2e82c1f6-a085-4c72-9da3-8640a32e42ab',
        value: `1.42.20130403134532.123.${moment(new Date()).format(
          'YYYYMMDDHHmmss',
        )}`,
        id: 'ei5',
        objectType:
          'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
        registryObject: 'doc1',
      },
      'rim:Name': [
        {
          'rim:LocalizedString': [
            {
              $: {
                value: 'XDSDocumentEntry.uniqueId',
              },
            },
          ],
        },
      ],
    };
    const creation_time = {
      $: {
        name: 'creationTime',
      },
      'rim:ValueList': [
        {
          'rim:Value': [`${moment(new Date()).format('YYYYMMDDHHmmss')}`],
        },
      ],
    };
    const start_time = {
      $: {
        name: 'serviceStartTime',
      },
      'rim:ValueList': [
        {
          'rim:Value': [
            `${moment(new Date() - 10800000).format('YYYYMMDDHHmmss')}`,
          ],
        },
      ],
    };
    const stop_time = {
      $: {
        name: 'serviceStopTime',
      },
      'rim:ValueList': [
        {
          'rim:Value': [
            `${moment(new Date() - 7200000).format('YYYYMMDDHHmmss')}`,
          ],
        },
      ],
    };

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:ExtrinsicObject'
    ][0]['rim:Classification'].push(role);

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:RegistryPackage'
    ][0]['rim:Slot'][0] = submission_slot;

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:RegistryPackage'
    ][0]['rim:ExternalIdentifier'].push(submission_id);

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:ExtrinsicObject'
    ][0]['rim:ExternalIdentifier'].push(doc_id);

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:ExtrinsicObject'
    ][0]['rim:Slot'].push(creation_time);

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:ExtrinsicObject'
    ][0]['rim:Slot'].push(start_time);

    appc_request['soap:Envelope']['soap:Body'][0][
      'urn:ProvideAndRegisterDocumentSetRequest'
    ][0]['lcm:SubmitObjectsRequest'][0]['rim:RegistryObjectList'][0][
      'rim:ExtrinsicObject'
    ][0]['rim:Slot'].push(stop_time);

    appc_request['soap:Envelope']['soap:Header'][0][
      'wsa:MessageID'
    ][0]._ = `uuid:${uuid.v1()}`;

    let appc_policy;
    switch (policy) {
      case 'Opt-out':
        appc_policy = policyRequests.deepCopy(policy_opt_out);
        break;
      case 'Opt-in':
        appc_policy = policyRequests.deepCopy(policy_opt_in);
        break;
      default:
        throw new Error('Policy Unrecognized');
    }

    appc_policy.PolicySet.Policy[0].$.PolicyId = `urn:uuid:${uuid.v1()}`;
    appc_policy.PolicySet.Policy[0].Rule[0].$.RuleId = `urn:uuid:${uuid.v1()}`;

    appc_policy.PolicySet.$.PolicySetId = `urn:uuid:${uuid.v1()}`;

    const xml2js = require('xml2js');
    const builder = new xml2js.Builder();
    let appc_xml = builder.buildObject(appc_policy);
    appc_xml = appc_xml.replace(/#{#Project#patient-id}/g, cpf);
    appc_xml = appc_xml.replace(/#{#Project#patient-id-oid}/g, rootVal);
    const xml = appc_xml;

    const header = {
      Authorization: `Bearer ${applicationToken}`,
      'X-User-Token': token,
      'Content-type': 'application/fhir+xml',
      Accept: 'application/fhir+xml',
    };
    let newBody = appc_consent_request_fhir;
    const timeMili = new Date().getTime();
    let currentDateTimeISO = new Date();
    currentDateTimeISO = currentDateTimeISO.toISOString();

    const documentManifestUUID = uuid.v1(timeMili + Math.random() * 9999);
    const documentReferenceUUID = uuid.v1(timeMili + Math.random() * 9999);
    const encodedDocument = base64.encode(xml);

    const hashh = hash(encodedDocument);
    const docsize = hashh.length;

    const organizationId = '9911936';
    const organizationOID = oids.organization;

    const substValues = [
      rootVal,
      patient_id,
      documentManifestUUID,
      organizationOID,
      organizationId,
      timeMili,
      currentDateTimeISO,
      documentReferenceUUID,
      docsize,
      hashh,
      encodedDocument,
    ];

    for (let i = 11; i > 0; i -= 1) {
      const ra = new RegExp(`(${'\\$'}${i})+`, 'g');
      newBody = String(newBody).replace(ra, substValues[i - 1]);
    }

    const request = {
      method: 'POST',
      headers: header,
      body: newBody,
    };

    // logDebug('requestPRINTADO :>> ', request);
    const APPC_response = await fetchWithTimeout(url, request)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => policyRequests.catchError(err, 'setPolicyRequest'));

    if (typeof APPC_response === 'string') return 'error';

    const responseJson = await APPC_response.text();
    return responseJson;
  }
}
