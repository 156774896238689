import React from 'react';
import Year from './Year';
import './Timeline.css';

function Timeline({ data, selectReport, examPdfCallback, isPdf = false }) {
  return (
    <div className="reports-container">
      {data.map((year, index) => (
        <div key={index}>
          <p className={"year-title year-title-full-size-title"}>
            {year.title}
          </p>
          <div style={{ marginLeft: '-40px' }}>
            <Year year={year.data} selectReport={selectReport} examPdfCallback={examPdfCallback} isPdf={isPdf}/>
          </div>
        </div>
      ))}
      <div style={{ margin: '10%' }} />
    </div>
  );
}

export default Timeline;
