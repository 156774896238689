export default function formatToKeyValText(list) {
  if (!list || typeof list === 'string') return null;

  const listFinal = list.map((item) => {
    try {
      const org = {
        key: `${item.id}`,
        value: `${item.resource.id.split('-')[1]}-${item.resource.alias[0]}`,
        text: `${item.resource.alias[0]}`,
      };
      return org;
    } catch (err) {
      return '';
    }
  });

  return listFinal;
}

export const checkWord = (text) => {
  if (text.length >= 2) return true;
  return false;
};
