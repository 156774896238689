import React, {
  Fragment, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import {  Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import fhirRequests from 'services/fhirRequests';
import { getFromPromiseAllSettled, logDebug } from 'utils/AuxiliarFunctions';
import { isFuture } from 'utils/dateFunctions';
import { getIbgeCityById } from 'utils/externalAPIs';
import { formatDateTimeStr } from 'utils/dateFunctions';
import { checkResponseFhir, getNextLink } from 'utils/fhirParser';
import { LoadMoreScroll, Button } from 'components';
import { useUserContext } from 'store/UserContext';
import { EligibilityFileManager } from 'components/EligibilityFileManager/EligibilityFileManager';

const BorderLinearProgress = withStyles((theme) => ({
  //TODO: Trocar por Loading padrao?
  root: {
  },
  colorPrimary: {
    backgroundColor: '#d0d0d0',
  },
  bar: {
    backgroundColor: '#1cb636',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
  },
  inline: {
    display: 'inline',
  },
  listText: {
    width: '100%',
    justifyContent: 'center',
  },
  linearProgress: {
    root: {
      backgroundColor: 'green',
    },
    colorPrimary: {
      color: 'green',
    },
  },
}));

function AppointmentList({ cityGroups }) {
  const [appointments, setAppointments] = useState();
  const [loading, setLoading] = useState(true);
  const [nextUrl, setNextUrl] = useState(false);
  const [modal, setModal] = useState(false);

  // TODO: tipo do apontamento

  // TODO: PM AM TO 13:00 14:00 etc
  const [user] = useUserContext();
  const userData = user?.userData;
  const classes = useStyles();

  async function getAppointments({filter = null}) {
    try {
      const userId = userData?.mhd?.id;
      const statusFilter = filter || 'fulfilled,booked,checked-in';
      const appointmentResponse = await fhirRequests.getResources({
        type: 'Appointment',
        query: `patient=Patient/${userId}&_sort=-date`
          // `date=gt${date.toISOString()}`+
          + `&_count=5&status=${statusFilter}`,
      });
      checkResponseFhir(appointmentResponse);
      const links = appointmentResponse.link;
      const nextLink = getNextLink(links);
      if (!nextLink) setNextUrl(false);
      else setNextUrl(nextLink);
      const appointmentEntry = appointmentResponse.entry;
      const appointmentEntryWithLocation = await setAppointmentsLocationsObj(appointmentEntry);
      setAppointments([...appointmentEntryWithLocation]);
    } catch (err) {
      logDebug('err :>> ', err);
    }
  }

  async function getMoreAppointments() {
    try {
      const appointmentResponse = await fhirRequests.getResources({
        nextPage: nextUrl.split('hapi/fhir')[1],
      });
      checkResponseFhir(appointmentResponse);
      const links = appointmentResponse.link;
      const nextLink = getNextLink(links);
      if (!nextLink) setNextUrl(false);
      else setNextUrl(nextLink);
      const appointmentEntry = appointmentResponse.entry;
      const appointmentEntryWithLocation = await setAppointmentsLocationsObj(appointmentEntry);
      setAppointments([...appointments, ...appointmentEntryWithLocation]);
    } catch (err) {
      console.warn('err :>> ', err);
    }
  }

  async function setAppointmentsLocationsObj(appointmentsEntry) {
    const arrayPromises = await Promise.allSettled(appointmentsEntry.map(async (appointment) => {
      const locationActor = appointment?.resource?.participant?.filter((participant) => participant?.actor?.reference?.includes('Location'));
      try {
        const locationID = locationActor[0]?.actor?.reference?.split('Location/')[1];
        const location = await getLocation(locationID);
        const cityCode = location.address.city;
        const stateAcronym = location.address.state;
        const cityObj = await getIbgeCityById({state_acronym: stateAcronym, city_id: cityCode});
        location.address = {
          ...location.address,
          cityObj,
        }
        return {
          appointment,
          location,
        };
      } catch (err) {
        console.warn(err);
        return { appointment };
      }
    }));
    return getFromPromiseAllSettled(arrayPromises);
  }

  async function getLocation(id) {
    try {
      const location = await fhirRequests.getResource({ id, type: 'Location' });
      checkResponseFhir(location);
      return location;
    } catch (err) {
      console.warn(err);
    }
  }

  async function initialize() {
    setLoading(true);
    await getAppointments({filter: null});
  }

  const onCancel = async (appointment) => {
    setModal({ 
      title: 'Deseja cancelar o agendamento?', 
      body: 'Uma vez cancelado não será possível desfazer esta ação', 
      confirmButton: true, 
      cancelButton: true, 
      visible: true,
      danger: true,
      onConfirm: () => cancelAppointment(appointment) });
  };

  const getSlotsFromAppoitnment = async (appointment)  => {
    try{
      const slotReferred = appointment?.resource.slot[0]?.reference;
      const slotID = slotReferred.split('Slot/')[1];
      const slotReturn = await fhirRequests.getResource({id: slotID, type: 'Slot'});
      const slotObj = slotReturn;
      slotObj.status = 'free';
      return slotObj;

    } catch(e) {
      logDebug('Error in getSlotsFromAppoitnment: Não tem slot', e)
      return {}
    }
  } 



  const cancelAppointment = async (appointment) => {
    try {
      const slot = await getSlotsFromAppoitnment(appointment);
      const newAppointment = {
        ...appointment.resource,
        status: 'cancelled',
      }
      let responseSlot;
      if(slot?.id) {
        responseSlot = await fhirRequests.putResource({
          id: slot.id,
          type: 'Slot',
          objectString: JSON.stringify(slot)
        });
      }
      const responseAppointment = await fhirRequests.putResource({
        id: newAppointment.id,
        type: 'Appointment',
        objectString: JSON.stringify(newAppointment)
      });
      checkResponseFhir(responseSlot);
      checkResponseFhir(responseAppointment);
      if (typeof resp !== 'string')
        setModal({ visible: true, title: 'Cancelado com Sucesso', body: 'O agendamento foi cancelado com sucesso', okButton: true });
      else
        setModal({ visible: true, title: 'Houve um problema', body: 'Houve um problema na tentativa de cancelar o agendamento', okButton: true });

    } catch(err) {
      console.warn(err);
      setModal({ visible: true, title: 'Houve um problema', body: 'Houve um problema na tentativa de cancelar o agendamento', okButton: true });
    }
  }

  const SimpleModal = (props) => (
    <Dialog
      open={props.show}
      onClose={() => setModal({
        ...modal,
        visible: false,
      })}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth
      // fullScreen
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DialogTitle id="form-dialog-title">
        {modal.title}
      </DialogTitle>
      <DialogContent>
        {modal.body}
      </DialogContent>
      <DialogActions>
        {modal.footer}
        {modal.cancelButton &&
          (<Button
            variant="outlined"
            color="secondary"
            onClick={() => setModal({
              ...modal,
              visible: false,
            })}
          >
            {modal?.danger ? "Voltar" : "Cancelar"}
          </Button>)}
        {modal.confirmButton &&
          (<Button
            variant="contained"
            color={modal?.danger ? "secondary" : "primary"}
            onClick={() => modal.onConfirm(props.appointment)}
          >
            {modal?.danger ? "Cancelar" : "Confirmar"}
          </Button>)}
        {modal.okButton &&
          (<Button
            variant="outlined"
            color="primary"
            onClick={() => setModal({
              ...modal,
              visible: false,
            }) }
            >
              Ok
            </Button>)}
      </DialogActions>
    </Dialog>
  );

  useEffect(() => {
    if(!modal?.visible) initialize().then(() => setLoading(false));
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  if (loading) {
    return (
      <List className={classes.root}>
        <ListItem alignItems="center">
          <ListItemText>
            <BorderLinearProgress />
          </ListItemText>
        </ListItem>
      </List>
    );
  }

  if (!appointments) {
    return (
      <Typography
        align="center"
        variant="h5"
        color="textPrimary"
      >
        Nenhum agendamento disponível.
      </Typography>
    );
  }

  return (
    <List className={classes.root}>
      <LoadMoreScroll
        hasNext={!!nextUrl}
        action={
          async () => {
            await getMoreAppointments();
          }
        }
      >

        {appointments?.map((entry) => {
          const { appointment, location } = entry;
          const appointmentResource = appointment.resource;
          const appointmentGroups = appointmentResource?.meta?.tag;
          let groups = [];
          if(Array.isArray(appointmentGroups)) {
            groups = cityGroups
            .filter(
              (item) => item.code.coding[0].code === appointmentGroups[0].code);
          }
          const showCancel = appointment?.resource?.status?.includes('booked')
           &&
            (isFuture(appointment?.resource?.start))
          
          const { time: timeStart, date: dateStart, amPm: amPmStart } = formatDateTimeStr(appointmentResource?.start);
          const { time: timeEnd, amPm: amPmEnd } = formatDateTimeStr(appointmentResource?.end);
          return (
            <Fragment key={appointmentResource?.id}>
              <SimpleModal
                appointment={appointment}
                show={modal.visible} onHide={() => setModal({
                  ...modal,
                  visible: false,
                })}
              />
              <ListItem alignItems="center">
                <ListItemAvatar>
                  {appointmentResource?.status?.includes('fulfilled')
                    ? <CheckCircleIcon fontSize="large" style={{ color: 'green' }} />
                    : <AccessAlarmIcon fontSize="large" style={{ color: 'red' }} />}
                </ListItemAvatar>

                <ListItemText
                  className={classes.listText}
                  primary="Agendamento"
                  secondary={(
                    <>
                      {location && <Typography
                        variant="h5"
                        color="colorSecondary"
                      >
                        {location?.name}
                      </Typography>}
                      <Typography
                        variant="h5"
                        color="textPrimary"
                      >
                        {`Data: ${dateStart}`}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                      >
                        {`Horário: ${timeStart}${amPmStart} - ${timeEnd}${amPmEnd} `}
                      </Typography>
                      {location && <Typography
                        variant="h6"
                        color="colorTextSecondary"
                      >
                        {`Endereço: ${location?.address?.line[0] || ''},
                      ${location?.address?.line[1] || ''},
                      ${location?.address?.line[2] ? `${location?.address?.line[2]},` : ''}
                       ${location?.address?.cityObj?.nome || ''},
                      ${location?.address?.state || ''},
                      CEP: ${location?.address?.postalCode || ''}`}
                      </Typography>}
                      {
                        <EligibilityFileManager
                        cityGroups={cityGroups}
                        patientName={user.name}
                        disabled={!groups.length}
                        patientId={user.cpf} 
                        groups={groups}
                        />
                      }
                      {showCancel && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => onCancel(appointment)}>
                          Cancelar
                        </Button>
                      )}
                    </>
                  )}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          );
        })}

      </LoadMoreScroll>

    </List>
  );
}

AppointmentList.propTypes = {
  cityGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      active: PropTypes.string.isRequired,
      code: PropTypes.shape({
        coding: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string.isRequired,
            display: PropTypes.string.isRequired,
            system: PropTypes.string.isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,

    }).isRequired,
  ).isRequired,
}

export { AppointmentList };