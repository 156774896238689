import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

import './DeployVersion.css';

const styles = makeStyles({
  typography: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
});

export function DeployVersion({ variant }) {
  const version = process.env.REACT_APP_VERSION_TEXT;
  const classes = styles();

  if (variant === 'header-bottom-lateral') {
    return (
      <div className="DeployVersion_HeaderBottomLateral">
        <Typography classes={classes.typography} variant="overline">
          {version}
        </Typography>
      </div>
    );
  }
  if (variant === 'login') {
    return (
      <div className="DeployVersion_Login">
        <Typography classes={classes.typography} variant="overline">
          {version}
        </Typography>
      </div>
    );
  }

  return <></>;
}

DeployVersion.propTypes = {
  variant: PropTypes.string.isRequired,
};
