import { AppBar } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from 'configs';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  appBar: {
    position: 'relative',
    backgroundColor: colors.accentColor,
  },
  title: {
    color: 'white',
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export const StyledAppBar = withStyles({
  colorPrimary: {
    background: `linear-gradient(45deg, ${colors.accentColor} 30%,${colors.accentColorDarker} 90%)`,
    border: 0,
    color: 'white',
  },
  label: {
    textTransform: 'capitalize',
  },
})(AppBar);
