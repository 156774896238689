import {
  retrieveData,
  chunk,
  logDebug,
  verifyCPF,
  fetchWithTimeout,
} from 'utils/AuxiliarFunctions';
import fhirFormat from 'utils/fhirObjects/fhirFormat';
import { oids } from 'utils/Constants';

import { endpoints } from 'configs';
import AdminFhirRequests from 'services/AdminFhirRequests';
import xdsToken from 'services/xdsToken';
import makeRequest from './makeRequestFHIR';

const { FHIR, USER_FHIR } = endpoints;

function getNext(res) {
  try {
    let findNextLink = res?.link?.filter((link) => link.relation === 'next');
    findNextLink = findNextLink[0].url.split(
      'http://localhost:8081/hapi/fhir',
    )[1];
    return findNextLink;
  } catch (err) {
    logDebug('err :>> ', err);
    return false;
  }
}

class scheduleRequests {
  static catchError = (err) => {
    switch (parseInt(err.message, 10)) {
      case 500:
        return 'internal_error';
      case 504:
        return 'timeout';
      case 400:
        return 'bad_request';
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 404:
        return 'not_found';
      case 409:
        return 'conflict';
      default:
        return 'network_error';
    }
  };

  static async postResource(path, body) {
    const token = await retrieveData('token');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/fhir+json',
        'X-User-Token': token,
      },
      body,
      redirect: 'follow',
    };
    const res = await makeRequest(path, requestOptions, token);

    return res;
  }

  static async getRecursive({ path, query = null }) {
    const token = await retrieveData('token');
    if (query) path = `${path}?${query}`;
    else path = `${path}?`;

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    let resources = [];
    let res = null;
    let next = true;
    let url = `${path}`;
    while (next) {
      const newUrl = url || next;
      res = await makeRequest(newUrl, requestOptions, token);
      next = getNext(res);
      if (next) url = false;
      if (res?.resourceType === 'Bundle' && res?.entry) {
        const entry = res.entry.map((item) => item.resource);
        resources = [...resources, ...entry];
      }
    }

    return resources;
  }

  static async getResource(path, query = null) {
    const token = await retrieveData('token');
    if (query) path = `${path}?${query}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/fhir+json',
        'X-User-Token': token,
        Accept: 'application/fhir+json',
      },
    };
    const res = await makeRequest(path, requestOptions, token);
    if (res.resourceType === 'Bundle') {
      if (res.total === 0) return [];
      return res.entry.map((item) => item.resource);
    }
    return res;
  }

  static async putResource(path, body, id, params = null) {
    const token = await retrieveData('token');

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/fhir+json' },
      body,
      redirect: 'follow',
    };
    const pathComplete = params ? `${path}/${id}?${params}` : `${path}/${id}`;
    const res = await makeRequest(pathComplete, requestOptions, token);
    return res;
  }

  static async deleteTag(type, id, tag) {
    const token = await retrieveData('token');

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/fhir+json' },
      body: JSON.stringify({
        resourceType: 'Parameters',
        parameter: [
          {
            name: 'meta',
            valueMeta: {
              tag,
            },
          },
        ],
      }),
      redirect: 'follow',
    };
    const pathComplete = `${type}/${id}/$meta-delete`;
    const res = await makeRequest(pathComplete, requestOptions, token);
    return res;
  }

  static async deleteResource(path, id) {
    const token = await retrieveData('token');
    const requestOptions = {
      method: 'DELETE',
      headers: {},
      redirect: 'follow',
    };
    const res = await makeRequest(`${path}/${id}`, requestOptions, token);
    return res;
  }

  static async getSlots({
    specialty = null,
    professional = null,
    date = null,
    schedule = null,
    startPeriod = null,
    endPeriod = null,
    status = null,
  }) {
    const token = await retrieveData('token');
    let path = 'Slot?_format=json&_pretty=true&_sort=start';
    if (specialty) path = `${path}&specialty=${specialty}`;
    if (professional) path = `${path}&${professional}`;
    if (date) path = `${path}&start=${date}`;
    if (startPeriod) path = `${path}&start=ge${startPeriod}`;
    if (endPeriod) path = `${path}&start=le${endPeriod}`;
    if (schedule) path = `${path}&schedule=${schedule}`;
    if (status) path = `${path}&status=${status}`;
    const requestOptions = {
      method: 'GET',
      headers: {},
      redirect: 'follow',
    };
    const res = await makeRequest(`${path}`, requestOptions, token);

    return res;
  }

  static async getLocations({ organization = null }) {
    const token = await retrieveData('token');
    let path = 'Location?_format=json&_pretty=true';
    if (organization)
      path = `${path}&organization=${oids.organization}-${organization}`;
    const requestOptions = {
      method: 'GET',
      headers: {},
      redirect: 'follow',
    };
    const res = await makeRequest(`${path}`, requestOptions, token);

    return res;
  }

  static async getSchedules(location = null, specialty = null) {
    const token = await retrieveData('token');
    let path = 'Schedule?_format=json&_pretty=true';
    if (location) path = `${path}&actor=Location/${location}`;
    if (specialty) path = `${path}&specialty=${specialty}`;
    const requestOptions = {
      method: 'GET',
      headers: {},
      redirect: 'follow',
    };
    const res = await makeRequest(`${path}`, requestOptions, token);

    return res;
  }

  static async writeSlot(slot) {
    const token = await retrieveData('token');
    const { schedule, start, end, status, resourceType, id } = slot.resource;

    const path = `Slot/${id}?_format=json&_pretty=true`;
    let body = {
      resourceType,
      schedule,
      status,
      start,
      end,
      id,
    };
    body = JSON.stringify(body);

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
      body,
    };
    const res = await makeRequest(`${path}`, requestOptions, token);
    return res;
  }

  static async bookAppointment(slot) {
    const token = await retrieveData('token');
    const { specialty, start, end, status, resourceType, id } = slot.resource;

    const path = `Appointment/${id}?_format=json&_pretty=true`;
    let body = {
      resourceType,
      specialty,
      status: status === 'busy' ? 'booked' : 'cancelled',
      start,
      end,
      id,
    };
    body = JSON.stringify(body);

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
      body,
    };
    const res = await makeRequest(`${path}`, requestOptions, token);
    return res;
  }

  static async cancelAppointments(slot) {
    const appointments = await this.getRecursive({
      path: 'Appointment',
      query: `slot=${slot.id}`,
    });
    const appointmentsSplitted = chunk(appointments, 100);

    await Promise.allSettled(
      appointmentsSplitted.map(async (appointmentSplit) => {
        const bundleAppointment = {
          method: 'PUT',
          entry: appointmentSplit.map((appointment) => {
            const auxApp = { ...appointment };
            if (auxApp.status === 'booked') {
              auxApp.status = 'cancelled';
              auxApp.cancelationReason = {
                coding: [
                  {
                    system:
                      'http://terminology.hl7.org/CodeSystem/appointment-cancellation-reason',
                    code: 'prov',
                    display: 'Provider',
                  },
                ],
                text: 'Cancelado pela Organização',
              };
              return auxApp;
            }
            return null;
          }),
        };
        const bundleFhir = fhirFormat.Bundle(bundleAppointment);
        // const bundle = await this.postResource('', bundleFhir);
        await this.postResource('', bundleFhir);
      }),
    );
  }

  static async changeAppointmentStatus(appointment, status) {
    const token = await retrieveData('token');
    const path = `Appointment/${appointment.id}`;
    let body = {
      ...appointment,
      status,
    };
    body = JSON.stringify(body);

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
      body,
    };
    const res = await makeRequest(`${path}`, requestOptions, token);
    return res;
  }

  static async deleteSlot(slot) {
    await this.cancelAppointments(slot);
    await this.deleteResource('Slot', slot.id);
  }

  static async getAppointmentsFromSlots(slots) {
    let appointments = [];
    await Promise.allSettled(
      slots.map(async (slotSplit) => {
        const bundleGetAppointments = {
          method: 'GET',
          entry: slotSplit.map((slot) => `Appointment?slot=${slot.id}`),
        };
        const bundleFhir = fhirFormat.Bundle(bundleGetAppointments);
        const bundle = await this.postResource('', bundleFhir);

        if (bundle.entry && bundle.entry.length > 0) {
          let auxAppointments = bundle.entry.map((entry) => {
            if (entry.resource && entry.resource.entry)
              return entry.resource.entry;
            return [];
          });
          auxAppointments = auxAppointments.filter((item) => item.length > 0);

          auxAppointments.map((app) => {
            appointments = [
              ...appointments,
              ...app.map((app2) => app2.resource),
            ];
            return app;
          });
        }
      }),
    );
    return appointments;
  }

  static async cancelBookedAppointments(appointments) {
    if (appointments.length > 0) {
      appointments = appointments.filter((app) => app.status === 'booked');
      const appointmentsSplitted = chunk(appointments, 100);

      const results = await Promise.allSettled(
        appointmentsSplitted.map(async (appointmentSplit) => {
          const bundleAppointment = {
            method: 'PUT',
            entry: appointmentSplit.map((appointment) => {
              const auxApp = { ...appointment };
              auxApp.status = 'cancelled';
              auxApp.cancelationReason = {
                coding: [
                  {
                    system:
                      'http://terminology.hl7.org/CodeSystem/appointment-cancellation-reason',
                    code: 'prov',
                    display: 'Provider',
                  },
                ],
                text: 'Cancelado pela Organização',
              };
              return auxApp;
            }),
          };
          const bundleFhir = fhirFormat.Bundle(bundleAppointment);
          const bundle = await this.postResource('', bundleFhir);
          return [JSON.parse(bundleFhir), bundle];
        }),
      );
      logDebug('cancelBookedAppointments results :>> ', results);
    }
  }

  static async deleteSlots(slots) {
    // Bundle para deletar slots
    await Promise.allSettled(
      slots.map(async (slotSplit) => {
        const bundleSlots = {
          method: 'DELETE',
          entry: slotSplit.map((slot) => `Slot/${slot.id}`),
        };
        const bundleFhir = fhirFormat.Bundle(bundleSlots);
        const bundle = await this.postResource('', bundleFhir);
        return [JSON.parse(bundleFhir), bundle];
      }),
    );
  }

  static async deleteSchedule(schedule, clearSchedule = true) {
    let slots = await this.getRecursive({
      path: 'Slot',
      query: `schedule=${schedule.id}`,
    });

    slots = chunk(slots, 100);
    const appointments = await this.getAppointmentsFromSlots(slots);
    await this.cancelBookedAppointments(appointments);

    await this.deleteSlots(slots);

    // Deletando a schedule
    if (clearSchedule) await this.deleteResource('Schedule', schedule.id);
  }

  static async deleteLocation(location) {
    const schedules = await this.getRecursive({
      path: 'Schedule',
      query: `actor=Location/${location.id}`,
    });
    await Promise.allSettled(
      schedules.map(async (schedule) => {
        const res = await this.deleteSchedule(schedule);
        return res;
      }),
    );
    await this.deleteResource('Location', location.id);
  }

  static async getComorbidityGroups() {
    const token = await retrieveData('token');
    const path = '';
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
    };
    let res = await makeRequest(path, requestOptions, token);

    if (typeof res !== 'string') {
      res = res?.expansion?.contains;
      // logDebug('res', res)
      if (res.length > 0) {
        const filteredEntry = res.filter(
          (item) => item.code !== '00' && item.code !== '999999',
        );
        return filteredEntry;
      }
    }

    return res?.expansion?.contains;
  }

  static async getFunctionalGroups() {
    const token = await retrieveData('token');
    const path = '';
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
    };
    let res = await makeRequest(path, requestOptions, token);
    if (typeof res !== 'string') {
      res = res?.expansion?.contains;
      if (res.length > 0) {
        const filteredEntry = res.filter(
          (item) => item.code !== '00' && item.code !== '999999',
        );
        return filteredEntry;
      }
    }

    return res;
  }

  static async addUserToGroup({ patientId, groupId }) {
    const url = `${USER_FHIR}/Group/${groupId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/xml-patch+xml');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const oid = verifyCPF(patientId) ? oids.cpf : oids.cns;
    const body = `<diff xmlns:fhir="http://hl7.org/fhir">
      <add sel="/fhir:Group">
        <fhir:member>
          <fhir:entity>
            <fhir:reference value="Patient/${oid}-${patientId}"/>
          </fhir:entity>
        </fhir:member>
      </add>
    </diff>`;

    const request = {
      method: 'PATCH',
      headers: myHeaders,
      body,
    };

    // logDebug('requestPRINTADO :>> ', request);
    const PractitionerRolesResponse = await fetchWithTimeout(url, request)
      .then((response) => {
        if (response.status < 200 || response.status > 299)
          throw new Error(response.status);
        return response;
      })
      .catch((err) =>
        AdminFhirRequests.catchError(err, 'putPractitionerRoles'),
      );

    if (typeof PractitionerRolesResponse === 'string')
      return PractitionerRolesResponse;

    const responseJson = {
      response: await PractitionerRolesResponse.text(),
    };
    return responseJson;
  }

  // static async addUserToGroup({ patientId, groupId, first = false }) {
  //   const token = await retrieveData('token');
  //   const oid = verifyCPF(patientId) ? oids.cpf : oids.cns;
  //   const entry = JSON.stringify([{
  //     op: 'add',
  //     path: first ? '/member' : '/member/0',
  //     value: first ? [{
  //       entity: { reference: `Patient/${oid}-${patientId}` },
  //     }]
  //       : { entity: { reference: `Patient/${oid}-${patientId}` } },
  //   }]);
  //   const body = {
  //     resourceType: 'Bundle',
  //     type: 'transaction',
  //     entry: [{
  //       resource: {
  //         resourceType: 'Binary',
  //         contentType: 'application/json-patch+json',
  //         data: base64.encode(entry),
  //       },
  //       request: {
  //         method: 'PATCH',
  //         url: `Group/${groupId}`,
  //       },
  //     }],
  //   };
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/fhir+json' },
  //     redirect: 'follow',
  //     body: JSON.stringify(body),
  //   };
  //   const res = await makeRequest('', requestOptions, token);

  //   return res;
  // }

  static async countGroupMembers({ groupId }) {
    const token = await retrieveData('token');
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
    };
    const path = `Group?_content=Patient&_id=${groupId}&_summary=true`;
    const res = await makeRequest(path, requestOptions, token);

    return res;
  }

  static async removePatientFromGroup({ patientId, id }) {
    const token = await retrieveData('token');
    const body = `<diff xmlns:fhir="http://hl7.org/fhir"><remove sel="/fhir:Group/fhir:member[fhir:entity/fhir:reference/@value='Patient/${
      verifyCPF(patientId) ? oids.cpf : oids.cns
    }-${patientId}']"/></diff>`;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/xml-patch+xml' },
      body,
      redirect: 'follow',
    };
    const path = `Group/${id}`;
    const res = await makeRequest(path, requestOptions, token);
    return res;
  }

  static async getCityGroups(cityId) {
    // example id = 220040
    const token = await retrieveData('token');
    const path = `Group?_elements=id,extension,active,code&characteristic-value=1.3.6.1.4.1.54413.1.1.6.12%7C$${cityId}&characteristic-value=${oids.programHealthCareService}%7C$ImunizacaoCovid19`;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
    };
    const res = await makeRequest(path, requestOptions, token);
    if (typeof res !== 'string') {
      if (res?.entry?.length > 0) {
        const filteredEntry = res.entry.filter(
          (item) =>
            item.resource.code.coding[0].code !== '00' &&
            item.resource.code.coding[0].code !== '999999',
        );
        return {
          ...res,
          entry: filteredEntry,
        };
      }
    }
    return res;
  }

  static async changeAppointmentTag(appointment, group) {
    const token = await retrieveData('token');
    const path = `Appointment/${appointment.id}`;
    let body = {
      ...appointment,
      meta: {
        tag: [group],
      },
    };
    body = JSON.stringify(body);

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/fhir+json' },
      redirect: 'follow',
      body,
    };
    const res = await makeRequest(`${path}`, requestOptions, token);
    return res;
  }

  static async createSchedule({
    locationId,
    startPeriodSchedule,
    endPeriodSchedule,
  }) {
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/Schedule`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      active: true,
      actor: [
        {
          display: 'Location',
          reference: `Location/${locationId}`,
        },
        // {
        //     "display": "Healthcare",
        //     "reference": "HealthcareService/{{healthcareID}}"
        // }
      ],
      planningHorizon: {
        end: endPeriodSchedule,
        start: startPeriodSchedule,
      },
      resourceType: 'Schedule',
      serviceCategory: [
        {
          coding: [
            {
              code: '000',
              display: 'Categoria teste',
            },
          ],
        },
      ],
      serviceType: [
        {
          coding: [
            {
              code: '000',
              display: 'Tipo teste',
            },
          ],
        },
      ],
      specialty: [
        {
          coding: [
            {
              code: '000',
              display: 'Especialidade teste',
            },
          ],
        },
      ],
    };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201)
          throw new Error(response.status);
        return response.json();
      })
      .catch((err) => scheduleRequests.catchError(err, 'createSchedule'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }

  static async createSlot({ slot, scheduleId }) {
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    const url = `${FHIR}/Slot`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = {
      appointmentType: {
        coding: [
          {
            code: 'ROUTINE',
            display: 'Exame de Rotina',
            system: '0',
          },
        ],
      },
      resourceType: 'Slot',
      schedule: {
        reference: `Schedule/${scheduleId}`,
      },
      start: `${slot.start}`,
      end: `${slot.end}`,
      status: 'free',
    };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: 'follow',
    };

    const fhirResponse = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201)
          throw new Error(response.status);
        return response.json();
      })
      .catch((err) => scheduleRequests.catchError(err, 'createSchedule'));

    if (typeof fhirResponse === 'string') return fhirResponse;
    return fhirResponse;
  }
}

export default scheduleRequests;
