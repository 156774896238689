import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, LinearProgress } from '@material-ui/core';
import { colors } from 'configs';
import styles from './Loading.css';

const Loading = ({
  type, color, size, variant,
}) => {
  const useStyles = makeStyles((theme) => ({
    colorPrimary: {
      color: color || colors.loadingColor,
    },
    circle: {
      strokeLinecap: 'round',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    progressBottom: {
      backgroundColor: 'white',
    },
    barColorPrimary: {
      backgroundColor: color || colors.modal_title,
    },
  }));

  const classes = useStyles();
  let circular;
  let scale;
  let variation;

  if (type === 'linear') circular = false;
  else circular = true;

  switch (size) {
    case 'mini':
      scale = '2rem';
      break;
    case 'medium':
      scale = '7rem';
      break;
    case 'large':
      scale = '10rem';
      break;
    case 'small':
      scale = '3rem';
      break;
    default:
      scale = size;
  }

  if (variant === 'determinate') variation = 'determinate';
  else variation = 'indeterminate';

  return (
    <div className={styles.loading}>
      {circular
      && (
        <>
          <CircularProgress
            className={classes.colorPrimary}
            classes={{
              circle: classes.circle,
            }}
            size={scale}
            variant={variation}
          />
        </>
      )}

      {!circular
      && (
      <LinearProgress
        classes={{
          colorPrimary: classes.progressBottom,
          barColorPrimary: classes.barColorPrimary,
        }}
        variant={variation}
      />
      )}

    </div>
  );
};
Loading.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

export { Loading };
