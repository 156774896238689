import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import { Controller, useForm } from 'react-hook-form';

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value + 1); // update the state to force render
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function SearchBar({ onSearch, update }) {
  const classes = useStyles();
  const [val, setVal] = useState('');
  const forceUpdate = useForceUpdate();

  const {
    handleSubmit, control, register,
  } = useForm({
    mode: 'all',
  });

  const onSubmit = (e) => {
    onSearch(e.search);
  };

  return (
    <Paper component="form" className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <FilterIcon />
      </IconButton>
      <Controller
        name="search"
        control={control}
        defaultValue={val}
        render={({ onChange }) => (
          <>
            <InputBase
              className={classes.input}
              placeholder="Filtrar por Nome"
              value={val}
              fullWidth
              ref={register}
              inputProps={{ 'aria-label': 'search google maps' }}
              onChange={(e) => {
                setVal(e.target.value);
                onChange(e.target.value);
                update(e.target.value);
                forceUpdate();
              }}
            />
          </>
        )}
      />

      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
      {/* <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export { SearchBar };
