import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'theme/logo+name.png';

import './NoMatch.css';

const NoMatch = () => (
  <div className="noMatch-container">
    <img className="noMatch-logo" src={logo} alt="Logo" />
    <div className="noMatch-link">
      <Link to="/">
        Ir para a Página Principal
      </Link>
    </div>
  </div>
);
export { NoMatch };
