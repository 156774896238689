import React, { Component } from 'react';
import { IconContext } from 'react-icons';
import {
  FaUserAlt, FaMailBulk, FaIdCard, FaPhone,
} from 'react-icons/fa';
import './SignUp.css';
import {
  formatPhoneNumber, logDebug,
} from 'utils/AuxiliarFunctions';
import { formatDate, formatDateWhileTyping, verifyDate } from 'utils/dateFunctions';

const iconName = <FaUserAlt />;
const iconCpf = <FaIdCard />;
const iconPhone = <FaPhone />;
const iconEmail = <FaMailBulk />;

export default class FirstForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      cpf: '',
      phone: '',
      email: '',
      birthday: '',
      type: '',
    };
  }

  render() {
    const {
      name, email, cpf, phone, birthday, type,
    } = this.state;

    logDebug('this.state :>> ', this.state);
    return (
      <div>
        {/* _______________________________NAME_______________________________________________ */}
        <div>
          <div className="SignUpDivText">
            <h6>
              Para prosseguir, insira seus dados abaixo. Por motivos de segurança
              eles serão verificados no Cartão Nacional de Saúde (CNS).
              Todos estes campos são obrigatórios.
            </h6>
          </div>
          <div className="SignUpRow">
            <div>
              <IconContext.Provider value={{ className: 'SignUpIcons' }}>
                {iconName}
              </IconContext.Provider>
            </div>
            <div>
              <input
                className="SignUpInput"
                type="text"
                value={name}
                placeholder="Nome Completo"
                onChange={(event) => this.setState({ name: event.target.value })}
              />
            </div>
          </div>
        </div>
        {/* _______________________________CPF________________________________________________ */}
        <div>
          <div className="SignUpRow">
            <div>
              <IconContext.Provider value={{ className: 'SignUpIcons' }}>
                {iconCpf}
              </IconContext.Provider>
            </div>
            <div>
              <input
                className="SignUpInput"
                type="text"
                value={cpf}
                placeholder="CPF"
                onChange={(event) => this.setState({ cpf: event.target.value })}
              />
            </div>
          </div>
          {/* <div className={css(styles.inputLine)} /> */}
        </div>
        <div>
          <div className="SignUpRow">
            <div>
              <IconContext.Provider value={{ className: 'SignUpIcons' }}>
                {iconCpf}
              </IconContext.Provider>
            </div>
            <div>
              {navigator.language === 'pt-br'
                ? (
                  <input
                    // tabIndex="3"
                    className="SignUpInput"
                    placeholder="Data de nascimento*"
                    // type="date"
                    // className="textbox-n"
                    type={type}
                    onFocus={() => { this.setState({ type: 'date' }); }}
                    onBlur={() => {
                      if (!birthday) this.setState({ type: 'text' });
                    }}
                    // value={birthday}
                    onChange={(event) => {
                    // logDebug('birthday', this.state.birthday);
                      const temp = formatDate(event.target.value);
                      // const temp = formatDateWhileTyping(event.target.value);
                      let formatedDate = temp.split('/');
                      formatedDate = `${formatedDate[2]}${formatedDate[1]}${formatedDate[0]}`;

                      this.setState({ birthday: temp, type: 'date' });
                      if (verifyDate(formatedDate) === false) this.setState({ dataAlert: 'A data digitada é inválida, digite no formato dd/mm/aaaa!', showAlert: true });
                      if (verifyDate(formatedDate) === true) this.setState({ showAlert: false });
                    }}
                  />
                )
                : (
                  <input
                    // tabIndex="3"
                    className="SignUpInput"
                    type="text"
                    value={birthday}
                    placeholder="Data de nascimento"
                    onChange={(event) => {
                      const temp = formatDateWhileTyping(event.target.value);
                      let formatedDate = temp.split('/');
                      formatedDate = `${formatedDate[2]}${formatedDate[1]}${formatedDate[0]}`;
                      // logDebug('temp: ', formatedDate);
                      this.setState({ birthday: temp });
                      if (verifyDate(formatedDate) === false) this.setState({ dataAlert: 'A data digitada é inválida, digite no formato dd/mm/aaaa!', showAlert: true });
                      if (verifyDate(formatedDate) === true) this.setState({ showAlert: false });
                    }}
                  />
                )}
            </div>
          </div>
          {/* <div className={css(styles.inputLine)} /> */}
        </div>

        {/* _______________________________PHONE_______________________________________________ */}
        <div>
          <div className="SignUpRow">
            <div>
              <IconContext.Provider value={{ className: 'SignUpIcons' }}>
                {iconPhone}
              </IconContext.Provider>
            </div>
            <div>
              <input
                className="SignUpInput"
                type="text"
                value={phone}
                placeholder="(00)00000-0000"
                onChange={(event) => {
                  const temp = formatPhoneNumber(event.target.value);
                  this.setState({ phone: temp });
                }}
              />
            </div>
          </div>
        </div>
        {/* _______________________________EMAIL______________________________________________ */}
        <div>
          <div className="SignUpRow">
            <div>
              <IconContext.Provider value={{ className: 'SignUpIcons' }}>
                {iconEmail}
              </IconContext.Provider>
            </div>
            <div>
              <input
                className="SignUpInput"
                type="text"
                value={email}
                placeholder="Email"
                onChange={(event) => this.setState({ email: event.target.value })}
              />
            </div>
          </div>
          <div className="SignUpButtonDiv">
            <div className="SignUpIcons"> </div>
            <button
              className="SignUpInput SignUpButton"
              id="continueSignUp"
              type="button"
              onClick={async () => {
                this.props.triggerUpdateParent(name, birthday, cpf, phone, email);
              }}
            >
              Continuar
            </button>
          </div>
        </div>

        {/* _______________________________END_____________________________________________ */}

        {/* <p>{error}</p> */}
      </div>
    );
  }
}
