import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import fhirRequests from 'services/fhirRequests';
import formatToKeyValText, { checkWord } from './dataProcess';

import './styles.css';

export function DropdownSearchOrganization({
  placeholder, onChoose, disabled, outsideValue = null, cityId,
}) {
  const [val, setVal] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState('');
  const [loading, setLoading] = useState('');
  const [onlineSearch, setOnlineSearch] = useState(true);

  async function searchHandler(filter) {
    let results;
    if (parseInt(filter, 10)) results = await fhirRequests.getOrganizationList({ cityId, organization: filter });
    else results = await fhirRequests.getOrganizationList({ cityId, name: filter });
    // TODO - verificação de erro
    if (results.entry) {
      results = formatToKeyValText(results.entry);
      if (parseInt(filter, 10)) for (const r of results) r.text = r.value;
      if (typeof results !== 'string') setOptions(results);
      return results;
    }
    return [];
  }

  useEffect(() => {
    const asyncHandler = async () => {
      if (
        checkWord(search)
        && onlineSearch) {
        setLoading(true);
        setOnlineSearch(false);
        setSearch(search);
        await searchHandler(search);
        setLoading(false);
        setOnlineSearch(true);
      } else if (search === '' || !options) setOnlineSearch(true);
    };

    asyncHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSelect = (e, { value }) => {
    setVal(value);
    setSearch(value);
    const retVal = value.split(' - ');
    onChoose(retVal);
  };

  const handleSearchChange = (e, { searchQuery }) => {
    setSearch(searchQuery);
  };

  return (
    <div className="container-dropdown">
      <Dropdown
        autoComplete="off"
        loading={loading}
        fluid
        compact
        clearable
        onChange={handleSelect}
        onSearchChange={handleSearchChange}
        options={options}
        placeholder={placeholder}
        search
        disabled={disabled}
        selection
        searchQuery={search}
        text={outsideValue}
        value={val}
        noResultsMessage="Pesquise pelo termo que deseja"
      />
    </div>
  );
}

DropdownSearchOrganization.defaultProps = {
  outsideValue: null,
};

DropdownSearchOrganization.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChoose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  cityId: PropTypes.bool.isRequired,
  outsideValue: PropTypes.string,
};
