const endpoints = (mode) => {
  const projectEndpoints = {
    paciente: () => ({
      principalDomain: process.env.REACT_APP_ENDPOINT_principalDomain,
      REPOSITORY_uri: process.env.REACT_APP_ENDPOINT_REPOSITORY_uri,
      XDSB_domain: process.env.REACT_APP_ENDPOINT_XDSB_domain,
      REGISTRY_uri: process.env.REACT_APP_ENDPOINT_REGISTRY_uri,
      CONSENT_api: process.env.REACT_APP_ENDPOINT_CONSENT_api,
      PIX_domain: process.env.REACT_APP_ENDPOINT_PIX_domain,
      PIXPDQ_domain: process.env.REACT_APP_ENDPOINT_PIXPDQ_domain,
      token_api: process.env.REACT_APP_ENDPOINT_token_api,
      Scim2: process.env.REACT_APP_ENDPOINT_Scim2,
      SignUPURL: process.env.REACT_APP_ENDPOINT_SignUPURL,
      dataValidatorUrl: process.env.REACT_APP_ENDPOINT_dataValidatorUrl,
      PassRecovery: process.env.REACT_APP_ENDPOINT_PassRecovery,
      OAUTH: process.env.REACT_APP_ENDPOINT_OAUTH,
      FHIR: process.env.REACT_APP_ENDPOINT_FHIR,
      APPCFHIR: process.env.REACT_APP_ENDPOINT_APPCFHIR,
      TERMINOLOGY: process.env.REACT_APP_ENDPOINT_TERMINOLOGY,
      MHD: process.env.REACT_APP_ENDPOINT_MHD,
      portalConsumerKey: process.env.REACT_APP_PORTAL_PACIENTE_CONSUMER_KEY,
      portalConsumerSecret: process.env.REACT_APP_PORTAL_PACIENTE_CONSUMER_SECRET,
      SignUP_c_key: process.env.REACT_APP_SIGNUP_CONSUMER_KEY,
      SignUP_c_secret: process.env.REACT_APP_SIGNUP_CONSUMER_SECRET,
      PassRecoveryBearer: process.env.REACT_APP_ENDPOINT_PassRecoveryBearer,
      IBGE_domain: process.env.REACT_APP_ENDPOINT_IBGE_domain,
      termsUri: process.env.REACT_APP_ENDPOINT_termsUri,
      MPI_FHIR: process.env.REACT_APP_ENDPOINT_MPI_FHIR,
      USER_FHIR: process.env.REACT_APP_ENDPOINT_USER_FHIR,
      ADMFHIR: process.env.REACT_APP_ENDPOINT_ADMFHIR,
    }),
    profissional: () => ({
      principalDomain: process.env.REACT_APP_ENDPOINT_principalDomain,
      REPOSITORY_uri: process.env.REACT_APP_ENDPOINT_REPOSITORY_uri,
      XDSB_domain: process.env.REACT_APP_ENDPOINT_XDSB_domain,
      REGISTRY_uri: process.env.REACT_APP_ENDPOINT_REGISTRY_uri,
      CONSENT_api: process.env.REACT_APP_ENDPOINT_CONSENT_api,
      PIX_domain: process.env.REACT_APP_ENDPOINT_PIX_domain,
      PIXPDQ_domain: process.env.REACT_APP_ENDPOINT_PIXPDQ_domain,
      token_api: process.env.REACT_APP_ENDPOINT_token_api,
      Scim2: process.env.REACT_APP_ENDPOINT_Scim2,
      SignUPURL: process.env.REACT_APP_ENDPOINT_SignUPURL,
      dataValidatorUrl: process.env.REACT_APP_ENDPOINT_dataValidatorUrl,
      PassRecovery: process.env.REACT_APP_ENDPOINT_PassRecovery,
      OAUTH: process.env.REACT_APP_ENDPOINT_OAUTH,
      FHIR: process.env.REACT_APP_ENDPOINT_FHIR,
      APPCFHIR: process.env.REACT_APP_ENDPOINT_APPCFHIR,
      TERMINOLOGY: process.env.REACT_APP_ENDPOINT_TERMINOLOGY,
      MHD: process.env.REACT_APP_ENDPOINT_MHD,
      portalConsumerKey: process.env.REACT_APP_PORTAL_PROFISSIONAL_CONSUMER_KEY,
      portalConsumerSecret: process.env.REACT_APP_PORTAL_PROFISSIONAL_CONSUMER_SECRET,
      SignUP_c_key: process.env.REACT_APP_SIGNUP_CONSUMER_KEY,
      SignUP_c_secret: process.env.REACT_APP_SIGNUP_CONSUMER_SECRET,
      PassRecoveryBearer: process.env.REACT_APP_ENDPOINT_PassRecoveryBearer,
      IBGE_domain: process.env.REACT_APP_ENDPOINT_IBGE_domain,
      termsUri: process.env.REACT_APP_ENDPOINT_termsUri,
      MPI_FHIR: process.env.REACT_APP_ENDPOINT_MPI_FHIR,
      USER_FHIR: process.env.REACT_APP_ENDPOINT_USER_FHIR,
      ADMFHIR: process.env.REACT_APP_ENDPOINT_ADMFHIR,
    }),
  };

  try {
    return projectEndpoints[mode]();
  } catch (err) {
    return projectEndpoints.standard();
  }
};

const features = (mode) => {
  const projectFeatures = {
    paciente: () => ({
      chatbot: false,
      consent: true,
      policy: true,
      profile: true,
      reports: true,
      schedule: true,
      terms: true,
      loginWithGovBr: false,
      regularLogin: true,
      loginWithOffice365: false,
      address: true,
      partnership: true,
      searchPatients: true,
      checkIn: true,
      vaccinatePatients: true,
      cpfValidator: true,
      doNotGetPractitionerRoles: true,
    }),
    profissional: () => ({
      chatbot: false,
      consent: true,
      policy: true,
      profile: true,
      reports: true,
      schedule: true,
      terms: true,
      loginWithGovBr: true,
      regularLogin: process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging',
      loginWithOffice365: false,
      address: true,
      partnership: true,
      searchPatients: true,
      checkIn: true,
      vaccinatePatients: true,
      cpfValidator: true,
      doNotGetPractitionerRoles: false,
    }),
  };

  try {
    return projectFeatures[mode]();
  } catch (err) {
    return projectFeatures.standard();
  }
};

const roles = () => ({
  ROLE_PRACTITIONER: 'isProfessional',
});

const docTypes = () => [
  {
    name: 'Resultados de Exames',
    alias: 'RE',
    codes: [
      '1.3.6.1.4.1.54413.1.1.2.3.1',
      'ResultadoExames_FESF_v0.1',
      'Resultado de Exame',
    ],
  },
  {
    name: 'Sumário de Alta',
    alias: 'SA',
    codes: ['SumárioAlta.v4.0'],
  },
  {
    name: 'Registro de Atendimento Clínico',
    alias: 'RAC',
    codes: ['RegistroAtendimentoClínico.v4.1'],
  },
  {
    name: 'Notificação de Caso Suspeito de COVID-19',
    alias: 'NCSC',
    codes: ['NotificacaoCasoSuspeitoCOVID-19_FESF_V1.0'],
  },
  {
    name: 'Coleta de Dados Simplificada',
    alias: 'NCSC',
    codes: ['ColetaDadosSimplificada.v4.1'],
  },
  {
    name: 'Registro de Imunobiológico',
    alias: 'RI',
    codes: ['RegistroImunobiologico_FESF_v1.0', 'RegistroImunobiológico_V3'],
  },
];

const languagesList = ['en', 'pt-BR'];
const initialLanguage = languagesList[1];

const oids = () => ({
  organization: '2.16.840.1.113883.13.36',
  cpf: '2.16.840.1.113883.13.237',
  cns: '2.16.840.1.113883.13.236',
  practitionerRoles: '1.3.6.1.4.1.54413.1.1.2.1',
  programHealthCareService: '1.3.6.1.4.1.54413.1.1.2.2',
  categoryHealthCareService: '1.3.6.1.4.1.54413.1.1.6.8',
  susAttendance: '1.3.6.1.4.1.54413.1.1.6.7',
  admType: '1.3.6.1.4.1.54413.1.1.6.6',
  legalNature: '1.3.6.1.4.1.54413.1.1.6.4',
  orgType: '1.3.6.1.4.1.54413.1.1.6.3',
  immunobiological: '1.3.6.1.4.1.54413.1.1.6.10',
  doseCovidVaccine: '1.3.6.1.4.1.54413.1.1.6.14',
  immunizationEffects: '1.3.6.1.4.1.54413.1.1.6.13',
  documentPdf: '1.3.6.1.4.1.54413.1.1.2.3.1',
  council: '2.16.840.1.113883.13.243',
});

const settings = {
  endpoints,
  features,
  roles,
  docTypes,
  languagesList,
  oids,
  initialLanguage,
};

export {
  settings, endpoints, features, roles, docTypes, languagesList, oids, initialLanguage,
};
