export const registry_set_request = {
  'soap:Envelope': {
    $: {
      'xmlns:soap': 'http://www.w3.org/2003/05/soap-envelope',
    },
    'soap:Header': [

      {
        $: {
          'xmlns:wsa': 'http://www.w3.org/2005/08/addressing',
        },
        'wsa:Action': {
          _: 'urn:ihe:iti:2007:RegistryStoredQuery',
          $: {
            'soap:mustUnderstand': '1',
          },
        },

        'wsa:ReplyTo': [
          {
            $: {
              'soap:mustUnderstand': '1',
            },
            'wsa:Address': [
              'http://www.w3.org/2005/08/addressing/anonymous',
            ],

          },
        ],
        'wsa:MessageID': {
          _: 'uuid:c4f4c427-b81b-48ff-80d4-f6471d47646a',
          $: {
            'soap:mustUnderstand': '1',
          },
        },

        'wsa:To': {
          _: 'http://core-dev-barramentoregional-docker:8280/services/DocumentRegistry',
          $: {
            'soap:mustUnderstand': '1',
          },
        },
      },
    ],
    'soap:Body': [
      {
        'query:AdhocQueryRequest': [
          {
            $: {
              'xsi:schemaLocation': 'urn:oasis:names:tc:ebxml-regrep:xsd:query:3.0 ../../schema/ebRS/query.xsd',
              'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
              'xmlns:query': 'urn:oasis:names:tc:ebxml-regrep:xsd:query:3.0',
              'xmlns:rim': 'urn:oasis:names:tc:ebxml-regrep:xsd:rim:3.0',
              'xmlns:rs': 'urn:oasis:names:tc:ebxml-regrep:xsd:rs:3.0',
            },
            'query:ResponseOption': [
              {
                $: {
                  returnComposedObjects: 'true',
                  returnType: 'LeafClass',
                },
              },
            ],
            'rim:AdhocQuery': [
              {
                $: {
                  id: 'urn:uuid:14d4debf-8f97-4251-9a74-a90016b0af0d',
                },
                'rim:Slot': [
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export const consent_cnes = {
  PolicySet: {
    $: {
      PolicySetId: 'concat("urn:uuid:",#{#Project#uuid})',
      PolicyCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:policy-combining-algorithm:deny-overrides',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      'xmlns:hl7': 'urn:hl7-org:v3',
      xmlns: 'urn:oasis:names:tc:xacml:2.0:policy:schema:os',
      'xsi:schemaLocation': 'urn:oasis:names:tc:xacml:2.0:policy:schema:os ihe-appc-xacml-combined-schema-1.0.xsd',
    },
    Description: [
      'Consentimento a uma organizacao de saude',
    ],
    Target: [
      {
        Subjects: [
          {
            Subject: [
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:II-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                        },
                        'hl7:InstanceIdentifier': [
                          {
                            $: {
                              extension: '#{#Project#organization-id}',
                              root: '2.16.840.1.113883.13.36',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:organization-id',
                          DataType: 'http://www.w3.org/2001/XMLSchema#anyURI',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        Resources: [
          {
            Resource: [
              {
                ResourceMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:II-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                        },
                        'hl7:InstanceIdentifier': [
                          {
                            $: {
                              root: '#{#Project#patient-id-oid}',
                              extension: '#{#Project#patient-id}',
                            },
                          },
                        ],
                      },
                    ],
                    ResourceAttributeDesignator: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                          AttributeId: 'urn:ihe:iti:ser:2016:patient-id',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    Policy: [
      {
        $: {
          PolicyId: 'concat("urn:uuid:",#{#Project#uuid})',
          RuleCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:rule-combining-algorithm:deny-overrides',
        },
        Target: [
          '',
        ],
        Rule: [
          {
            $: {
              RuleId: 'urn:uuid:fab9b3a4-4d09-11ea-8a80-7598024794b3',
              Effect: 'Permit',
            },
          },
        ],
      },
    ],
  },
};

export const consent_revoked_cnes = {
  PolicySet: {
    $: {
      PolicySetId: 'concat("urn:uuid:",#{#Project#uuid})',
      PolicyCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:policy-combining-algorithm:deny-overrides',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      'xmlns:hl7': 'urn:hl7-org:v3',
      xmlns: 'urn:oasis:names:tc:xacml:2.0:policy:schema:os',
      'xsi:schemaLocation': 'urn:oasis:names:tc:xacml:2.0:policy:schema:os ihe-appc-xacml-combined-schema-1.0.xsd',
    },
    Description: [
      'Consentimento revogado a uma organizacao de saude',
    ],
    Target: [
      {
        Subjects: [
          {
            Subject: [
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:II-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                        },
                        'hl7:InstanceIdentifier': [
                          {
                            $: {
                              extension: '#{#Project#organization-id}',
                              root: '2.16.840.1.113883.13.36',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:organization-id',
                          DataType: 'http://www.w3.org/2001/XMLSchema#anyURI',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        Resources: [
          {
            Resource: [
              {
                ResourceMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:II-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                        },
                        'hl7:InstanceIdentifier': [
                          {
                            $: {
                              root: '#{#Project#patient-id-oid}',
                              extension: '#{#Project#patient-id}',
                            },
                          },
                        ],
                      },
                    ],
                    ResourceAttributeDesignator: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                          AttributeId: 'urn:ihe:iti:ser:2016:patient-id',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    Policy: [
      {
        $: {
          PolicyId: 'concat("urn:uuid:",#{#Project#uuid})',
          RuleCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:rule-combining-algorithm:deny-overrides',
        },
        Target: [
          '',
        ],
        Rule: [
          {
            $: {
              RuleId: 'urn:uuid:fab9b3a4-4d09-11ea-8a80-7598024794b3',
              Effect: 'Deny',
            },
          },
        ],
      },
    ],
  },
};

export const appc_consent = {
  PolicySet: {
    $: {
      PolicySetId: 'urn:uuid:29e64cce-19f6-43c4-9cc9-0227cb361ba1',
      PolicyCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:policy-combining-algorithm:deny-overrides',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      'xmlns:hl7': 'urn:hl7-org:v3',
      xmlns: 'urn:oasis:names:tc:xacml:2.0:policy:schema:os',
      'xsi:schemaLocation': 'urn:oasis:names:tc:xacml:2.0:policy:schema:os ihe-appc-xacml-combined-schema-1.0.xsd',
    },
    Description: [
      'Descrição',
    ],
    Target: [
      {
        Subjects: [
          '',
        ],
        Resources: [
          '',
        ],
      },
    ],
    Policy: [
      {
        $: {
          PolicyId: 'urn:uuid:001248b7-ca84-417c-a3e7-ecd2b4687f01',
          // RuleCombiningAlgId: 'urn:uuid:1d6cab72-ad79-11e9-a2a3-2a2ae2dbcce4',
          RuleCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:rule-combining-algorithm:deny-overrides', // 'urn:uuid:1d6cab72-ad79-11e9-a2a3-2a2ae2dbcce4',
        },
        Target: [
          '',
        ],
        Rule: [
          {
            $: {
              RuleId: 'urn:uuid:9a8575d5-fc15-4d9a-87e8-955624dea03c',
              Effect: 'Permit',
            },
            Target: [
              '',
            ],
          },
        ],
      },
    ],
  },
};

export const appc_consent_request_fhir_org = `<Bundle xmlns="http://hl7.org/fhir">
<meta>
    <profile value="http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle"/>
</meta>
<type value="transaction"/>
<entry>
    <resource>
        <DocumentManifest>
            <id value="$3"/>
            <contained>
                <Organization>
                    <id value="organization1"/>
                    <identifier>
                        <system value="$4"/>
                        <value value="$5"/>
                    </identifier>
                </Organization>
            </contained>
            <masterIdentifier>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:oid:1.3.6.1.4.1.54413.1.1.5.1.$6.1"/>
            </masterIdentifier>
            <identifier>
                <use value="official"/>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:uuid:$3"/>
            </identifier>
            <status value="current"/>
            <type>
                <coding>
                    <system value="urn:oid:2.16.840.1.113883.6.96"/>
                    <code value="404684003"/>
                    <display value="Clinical finding"/>
                </coding>
                <text value="Clinical finding"/>
            </type>
            <subject>
                <reference value="Patient/$1-$2"/>
            </subject>
            <created value="$7"/>
            <author>
                <reference value="#organization1"/>
            </author>
            <source value="urn:oid:1.3.6.1.4.1.54413.1.1.5.1"/>
            <content>
                <reference value="DocumentReference/$8"/>
            </content>
        </DocumentManifest>
    </resource>
    <request>
        <method value="PUT"/>
        <url value="DocumentManifest/$3"/>
    </request>
</entry>
<entry>
    <resource>
        <DocumentReference>
          <contained>
              <Practitioner>
                  <id value="#practitioner1"/>
                  <identifier>
                      <system value="2.16.840.1.113883.13.237"/>
                      <value value="$2"/>
                  </identifier>
              </Practitioner>
          </contained>
            <id value="$8"/>
            <contained>
                <Organization>
                    <id value="organization1"/>
                    <identifier>
                        <system value="$4"/>
                        <value value="$5"/>
                    </identifier>
                </Organization>
            </contained>
            <contained>
                <Patient>
                    <id value="patient1"/>
                    <identifier>
                        <system value="$1"/>
                        <value value="$2"/>
                    </identifier>
                </Patient>
            </contained>
            <masterIdentifier>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:oid:1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
            </masterIdentifier>
            <identifier>
                <use value="official"/>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:uuid:$8"/>
            </identifier>
            <status value="current"/>
            <type>
                <coding>
                    <system value="2.16.840.1.113883.6.1"/>
                    <code value="57016-8"/>
                    <display value="Privacy Policy Acknowledgement"/>
                </coding>
            </type>
            <category>
                <coding>
                    <system value="1.3.6.1.4.1.19376.1.2.3"/>
                    <code value="urn:ihe:iti:appc:2016:consent"/>
                    <display value="Consent Document"/>
                </coding>
            </category>
            <subject>
                <reference value="Patient/$1-$2"/>
            </subject>
            <date value="$7"/>
            <author>
                <reference value="#organization1"/>
            </author>
            <author>
              <reference value="Organization/$4-$5"/>
            </author>
            <author>
              <reference value="#practitioner1"/>
            </author>
            <author>
              <reference value="Practitioner/2.16.840.1.113883.13.237-$12"/>
            </author>
            <securityLabel>
                <coding>
                    <system value="2.16.840.1.113883.5.25"/>
                    <code value="N"/>
                    <display value="Normal"/>
                </coding>
            </securityLabel>
            <content>
                <attachment>
                    <contentType value="text/xml"/>
                    <language value="pt-BR"/>
                    <url value="Binary/1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
                    <size value="$9"/>
                    <hash value="$10"/>
                    <creation value="$7"/>
                </attachment>
                <format>
                    <system value="1.3.6.1.4.1.19376.1.2.3"/>
                    <code value="urn:ihe:iti:appc:2016:consent"/>
                    <display value="Consent Document"/>
                </format>
            </content>
        </DocumentReference>
    </resource>
    <request>
        <method value="PUT"/> 
        <url value="DocumentReference/$8"/>
    </request>
</entry>
<entry>
    <resource>
        <Binary>
            <id value="1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
            <contentType value="text/xml"/>
            <data value="$11"/>
        </Binary>
    </resource>
    <request>
        <method value="PUT"/>
        <url value="Binary/1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
    </request>
</entry>
</Bundle>`;

export const appc_consent_request_fhir = `<Bundle xmlns="http://hl7.org/fhir">
<meta>
    <profile value="http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle"/>
</meta>
<type value="transaction"/>
<entry>
    <resource>
        <Patient>
            <id value="$1-$2"/>
            <identifier>
                <system value="$1"/>
                <value value="$2"/>
            </identifier>
        </Patient>
    </resource>
    <request>
        <method value="PUT"/>
        <url value="Patient/$1-$2"/>
    </request>
</entry>
<entry>
    <resource>
        <DocumentManifest>
            <id value="$3"/>
            <contained>
                <Organization>
                    <id value="organization1"/>
                    <identifier>
                        <system value="$4"/>
                        <value value="$5"/>
                    </identifier>
                </Organization>
            </contained>
            <masterIdentifier>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:oid:1.3.6.1.4.1.54413.1.1.5.1.$6.1"/>
            </masterIdentifier>
            <identifier>
                <use value="official"/>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:uuid:$3"/>
            </identifier>
            <status value="current"/>
            <type>
                <coding>
                    <system value="urn:oid:2.16.840.1.113883.6.96"/>
                    <code value="404684003"/>
                    <display value="Clinical finding"/>
                </coding>
                <text value="Clinical finding"/>
            </type>
            <subject>
                <reference value="Patient/$1-$2"/>
            </subject>
            <created value="$7"/>
            <author>
                <reference value="#organization1"/>
            </author>
            <source value="urn:oid:1.3.6.1.4.1.54413.1.1.5.1"/>
            <content>
                <reference value="DocumentReference/$8"/>
            </content>
        </DocumentManifest>
    </resource>
    <request>
        <method value="PUT"/>
        <url value="DocumentManifest/$3"/>
    </request>
</entry>
<entry>
    <resource>
        <DocumentReference>
            <id value="$8"/>
            <contained>
                <Organization>
                    <id value="organization1"/>
                    <identifier>
                        <system value="$4"/>
                        <value value="$5"/>
                    </identifier>
                </Organization>
            </contained>
            <contained>
                <Patient>
                    <id value="patient1"/>
                    <identifier>
                        <system value="$1"/>
                        <value value="$2"/>
                    </identifier>
                </Patient>
            </contained>
            <masterIdentifier>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:oid:1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
            </masterIdentifier>
            <identifier>
                <use value="official"/>
                <system value="urn:ietf:rfc:3986"/>
                <value value="urn:uuid:$8"/>
            </identifier>
            <status value="current"/>
            <type>
                <coding>
                    <system value="2.16.840.1.113883.6.1"/>
                    <code value="57016-8"/>
                    <display value="Privacy Policy Acknowledgement"/>
                </coding>
            </type>
            <category>
                <coding>
                    <system value="1.3.6.1.4.1.19376.1.2.3"/>
                    <code value="urn:ihe:iti:appc:2016:consent"/>
                    <display value="Consent Document"/>
                </coding>
            </category>
            <subject>
                <reference value="Patient/$1-$2"/>
            </subject>
            <date value="$7"/>
            <author>
                <reference value="#organization1"/>
            </author>
            <securityLabel>
                <coding>
                    <system value="2.16.840.1.113883.5.25"/>
                    <code value="N"/>
                    <display value="Normal"/>
                </coding>
            </securityLabel>
            <content>
                <attachment>
                    <contentType value="text/xml"/>
                    <language value="pt-BR"/>
                    <url value="Binary/1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
                    <size value="$9"/>
                    <hash value="$10"/>
                    <creation value="$7"/>
                </attachment>
                <format>
                    <system value="1.3.6.1.4.1.19376.1.2.3"/>
                    <code value="urn:ihe:iti:appc:2016:consent"/>
                    <display value="Consent Document"/>
                </format>
            </content>
        </DocumentReference>
    </resource>
    <request>
        <method value="PUT"/> 
        <url value="DocumentReference/$8"/>
    </request>
</entry>
<entry>
    <resource>
        <Binary>
            <id value="1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
            <contentType value="text/xml"/>
            <data value="$11"/>
        </Binary>
    </resource>
    <request>
        <method value="PUT"/>
        <url value="Binary/1.3.6.1.4.1.54413.1.1.5.1.$6.2"/>
    </request>
</entry>
</Bundle>`;

export const appc_consent_request = {
  'soap:Envelope': {
    $: {
      'xmlns:soap': 'http://www.w3.org/2003/05/soap-envelope',
      'xmlns:urn': 'urn:ihe:iti:xds-b:2007',
      'xmlns:urn1': 'urn:oasis:names:tc:ebxml-regrep:xsd:lcm:3.0',
      'xmlns:urn2': 'urn:oasis:names:tc:ebxml-regrep:xsd:rs:3.0',
      'xmlns:urn3': 'urn:oasis:names:tc:ebxml-regrep:xsd:rim:3.0',
    },
    'soap:Header': {
      $: {
        'xmlns:wsa': 'http://www.w3.org/2005/08/addressing',
      },
      'wsa:Action': [{
        _: 'urn:ihe:iti:2007:ProvideAndRegisterDocumentSet-b',
        $: {
          'soap:mustUnderstand': '1',
        },
      }],
      'wsa:ReplyTo': [{
        $: {
          'soap:mustUnderstand': '1',
        },
        'wsa:Address': ['http://www.w3.org/2005/08/addressing/anonymous'],
      }],
      'wsa:MessageID': [{
        _: 'uuid:4f870bb0-9efb-43bd-b987-bc3476fd30ab',
        $: {
          'soap:mustUnderstand': '1',
        },
      }],
      'wsa:To': [{
        _: 'http://35.208.201.6:8010/xdsrepositoryb',
        $: {
          'soap:mustUnderstand': '1',
        },
      }],
    },
    'soap:Body': [
      {
        'urn:ProvideAndRegisterDocumentSetRequest': [
          {
            'lcm:SubmitObjectsRequest': [
              {
                $: {
                  'xmlns:lcm': 'urn:oasis:names:tc:ebxml-regrep:xsd:lcm:3.0',
                },
                'rim:RegistryObjectList': [
                  {
                    $: {
                      'xmlns:rim': 'urn:oasis:names:tc:ebxml-regrep:xsd:rim:3.0',
                    },
                    'rim:Classification': [
                      {
                        $: {
                          classifiedObject: 'ss1',
                          classificationNode: 'urn:uuid:a54d6aa5-d40d-43f9-88c5-b4633d873bdd',
                          id: 'cl1',
                          objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                        },
                      },
                    ],
                    'rim:RegistryPackage': [
                      {
                        $: {
                          id: 'ss1',
                        },
                        'rim:Slot': [
                          {
                            $: {
                              name: 'submissionTime',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  '',
                                ],
                              },
                            ],
                          },
                        ],
                        'rim:Classification': [
                          {
                            $: {
                              classificationScheme: 'urn:uuid:a7058bb9-b4e4-4307-ba5b-e3f0ab85e12d',
                              classifiedObject: 'ss1',
                              id: 'cl2',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: '',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'authorPerson',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '',
                                    ],
                                  },
                                ],
                              },
                              {
                                $: {
                                  name: 'authorInstitution',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '',
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:aa543740-bdda-424e-8c96-df4873be8500',
                              classifiedObject: 'ss1',
                              id: 'cl3',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: '404684003',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '2.16.840.1.113883.6.96',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'Summarization of Episode Note',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        'rim:ExternalIdentifier': [
                          {
                            $: {
                              identificationScheme: 'urn:uuid:6b5aea1a-874d-4603-a4bc-96a0a7b38446',
                              value: '',
                              id: 'ei1',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                              registryObject: 'ss1',
                            },
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'XDSSubmissionSet.patientId',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              identificationScheme: 'urn:uuid:554ac39e-e3fe-47fe-b233-965d2a147832',
                              value: '1.3.6.1.4.1.54443.1.1.5.2',
                              id: 'ei2',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                              registryObject: 'ss1',
                            },
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'XDSSubmissionSet.sourceId',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              identificationScheme: 'urn:uuid:96fdda7c-d067-4183-912e-bf5ee74998a8',
                              id: 'ei3',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                              value: '',
                              registryObject: 'ss1',
                            },
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'XDSSubmissionSet.uniqueId',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    'rim:Association': [
                      {
                        $: {
                          id: 'as1',
                          objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Association',
                          associationType: 'urn:oasis:names:tc:ebxml-regrep:AssociationType:HasMember',
                          sourceObject: 'ss1',
                          targetObject: 'doc1',
                        },
                        'rim:Slot': [
                          {
                            $: {
                              name: 'SubmissionSetStatus',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  'Original',
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    'rim:ExtrinsicObject': [
                      {
                        $: {
                          id: 'doc1',
                          mimeType: 'text/xml',
                          objectType: 'urn:uuid:7edca82f-054d-47f2-a032-9b2a5b5186c1',
                        },
                        'rim:Slot': [
                          {
                            $: {
                              name: 'creationTime',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  '',
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              name: 'languageCode',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  'pt-BR',
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              name: 'serviceStartTime',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  '',
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              name: 'serviceStopTime',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  '',
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              name: 'sourcePatientId',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  '',
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              name: 'URI',
                            },
                            'rim:ValueList': [
                              {
                                'rim:Value': [
                                  '',
                                ],
                              },
                            ],
                          },
                        ],
                        'rim:Name': [
                          {
                            'rim:LocalizedString': [
                              {
                                $: {
                                  value: '',
                                },
                              },
                            ],
                          },
                        ],
                        'rim:Classification': [
                          {
                            $: {
                              classificationScheme: 'urn:uuid:93606bcf-9494-43ec-9b4e-a7748d1a838d',
                              classifiedObject: 'doc1',
                              id: 'cl4',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: '',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'authorPerson',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '',
                                    ],
                                  },
                                ],
                              },
                              {
                                $: {
                                  name: 'authorInstitution',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '',
                                    ],
                                  },
                                ],
                              },
                              {
                                $: {
                                  name: 'authorRole',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '@@',
                                    ],
                                  },
                                ],
                              },
                              {
                                $: {
                                  name: 'authorSpecialty',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '@@1224^^^&2.16.840.1.113883.13.236&ISO',
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:41a5887f-8865-4c09-adf7-e362475b143a',
                              classifiedObject: 'doc1',
                              id: 'cl5',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: 'urn:ihe:iti:appc:2016:consent',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '1.3.6.1.4.1.19376.1.2.3',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'Consent Document',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:f4f85eac-e6cb-4883-b524-f2705394840f',
                              classifiedObject: 'doc1',
                              id: 'cl6',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: 'N',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '2.16.840.1.113883.5.25',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'Normal',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:a09d5840-386c-46f2-b5ad-9c3699a4309d',
                              classifiedObject: 'doc1',
                              id: 'cl7',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: 'urn:ihe:iti:appc:2016:consent',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '1.3.6.1.4.1.19376.1.2.3',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'Consent Document',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:f33fb8ac-18af-42cc-ae0e-ed0b0bdb91e1',
                              classifiedObject: 'doc1',
                              id: 'cl8',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: 'PTRES',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '2.16.840.1.113883.1.11.17660',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'PTRES',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:cccf5598-8b07-4b77-a05e-ae952c785ead',
                              classifiedObject: 'doc1',
                              id: 'cl9',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: '',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '1.3.6.1.4.1.54443.1.1.6.1',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'Paciente',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              classificationScheme: 'urn:uuid:f0306f51-975f-434e-a61c-c59651d33983',
                              classifiedObject: 'doc1',
                              id: 'cl10',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                              nodeRepresentation: '57016-8',
                            },
                            'rim:Slot': [
                              {
                                $: {
                                  name: 'codingScheme',
                                },
                                'rim:ValueList': [
                                  {
                                    'rim:Value': [
                                      '2.16.840.1.113883.6.1',
                                    ],
                                  },
                                ],
                              },
                            ],
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'Privacy Policy Acknowledgement',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                        'rim:ExternalIdentifier': [
                          {
                            $: {
                              identificationScheme: 'urn:uuid:58a6f841-87b3-4a3e-92fd-a8ffeff98427',
                              value: '',
                              id: 'ei4',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                              registryObject: 'doc1',
                            },
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'XDSDocumentEntry.patientId',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            $: {
                              identificationScheme: 'urn:uuid:2e82c1f6-a085-4c72-9da3-8640a32e42ab',
                              value: '',
                              id: 'ei5',
                              objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                              registryObject: 'doc1',
                            },
                            'rim:Name': [
                              {
                                'rim:LocalizedString': [
                                  {
                                    $: {
                                      value: 'XDSDocumentEntry.uniqueId',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            REPLACE_ME: '',
          },
        ],
      },
    ],
  },
};

export const info_request = {
  'soap:Envelope': {
    $: {
      'xmlns:soap': 'http://www.w3.org/2003/05/soap-envelope',
      'xmlns:urn': 'urn:ihe:iti:xds-b:2007',
      'xmlns:urn1': 'urn:oasis:names:tc:ebxml-regrep:xsd:lcm:3.0',
      'xmlns:urn2': 'urn:oasis:names:tc:ebxml-regrep:xsd:rim:3.0',
      'xmlns:urn3': 'urn:ihe:iti:xds-b:2007',
    },
    'soap:Header': [
      {
        'wsse:Security': [
          {
            $: {
              'xmlns:wsse': 'http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd',
            },
            'wsse:UsernameToken': [
              {
                $: {
                  'wsu:Id': 'Id-0001334008436683-000000002c4a1908-1',
                  'xmlns:wsu': 'http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd',
                },
                'wsse:Username': [
                  'CADSUS.CNS.PDQ.PUBLICO',
                ],
                'wsse:Password': [
                  {
                    _: 'kUXNmiiii#RDdlOELdoe00966',
                    $: {
                      Type: 'http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#PasswordText',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    'soap:Body': [
      {
        PRPA_IN201305UV02: [
          {
            $: {
              'xsi:schemaLocation': 'urn:hl7-org:v3 ./schema/HL7V3/NE2008/multicacheschemas/PRPA_IN201305UV02.xsd',
              ITSVersion: 'XML_1.0',
              'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
              xmlns: 'urn:hl7-org:v3',
            },
            id: [
              {
                $: {
                  root: '2.16.840.1.113883.4.714',
                  extension: '123456',
                },
              },
            ],
            creationTime: [
              {
                $: {
                  value: '20070428150301',
                },
              },
            ],
            interactionId: [
              {
                $: {
                  root: '2.16.840.1.113883.1.6',
                  extension: 'PRPA_IN201305UV02',
                },
              },
            ],
            processingCode: [
              {
                $: {
                  code: 'T',
                },
              },
            ],
            processingModeCode: [
              {
                $: {
                  code: 'T',
                },
              },
            ],
            acceptAckCode: [
              {
                $: {
                  code: 'AL',
                },
              },
            ],
            receiver: [
              {
                $: {
                  typeCode: 'RCV',
                },
                device: [
                  {
                    $: {
                      classCode: 'DEV',
                      determinerCode: 'INSTANCE',
                    },
                    id: [
                      {
                        $: {
                          root: '2.16.840.1.113883.3.72.6.5.100.85',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            sender: [
              {
                $: {
                  typeCode: 'SND',
                },
                device: [
                  {
                    $: {
                      classCode: 'DEV',
                      determinerCode: 'INSTANCE',
                    },
                    id: [
                      {
                        $: {
                          root: '2.16.840.1.113883.3.72.6.2',
                        },
                      },
                    ],
                    name: [
                      'CADSUS',
                    ],
                  },
                ],
              },
            ],
            controlActProcess: [
              {
                $: {
                  classCode: 'CACT',
                  moodCode: 'EVN',
                },
                code: [
                  {
                    $: {
                      code: 'PRPA_TE201305UV02',
                      codeSystem: '2.16.840.1.113883.1.6',
                    },
                  },
                ],
                queryByParameter: [
                  {
                    queryId: [
                      {
                        $: {
                          root: '1.2.840.114350.1.13.28.1.18.5.999',
                          extension: '1840997084',
                        },
                      },
                    ],
                    statusCode: [
                      {
                        $: {
                          code: 'new',
                        },
                      },
                    ],
                    responseModalityCode: [
                      {
                        $: {
                          code: 'R',
                        },
                      },
                    ],
                    responsePriorityCode: [
                      {
                        $: {
                          code: 'I',
                        },
                      },
                    ],
                    parameterList: [
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export const policy_request = {
  'soap:Envelope': {
    $: {
      'xmlns:soap': 'http://www.w3.org/2003/05/soap-envelope',
      'xmlns:urn': 'urn:ihe:iti:xds-b:2007',
      'xmlns:urn1': 'urn:oasis:names:tc:ebxml-regrep:xsd:lcm:3.0',
      'xmlns:urn2': 'urn:oasis:names:tc:ebxml-regrep:xsd:rs:3.0',
      'xmlns:urn3': 'urn:oasis:names:tc:ebxml-regrep:xsd:rim:3.0',
    },
    'soap:Header': [{
      $: {
        'xmlns:wsa': 'http://www.w3.org/2005/08/addressing',
      },
      'wsa:Action': [{
        _: 'urn:ihe:iti:2007:ProvideAndRegisterDocumentSet-b',
        $: {
          'soap:mustUnderstand': '1',
        },
      }],
      'wsa:ReplyTo': [{
        $: {
          'soap:mustUnderstand': '1',
        },
        'wsa:Address': ['http://www.w3.org/2005/08/addressing/anonymous'],
      }],
      'wsa:MessageID': [{
        _: 'uuid:$uuid_header',
        $: {
          'soap:mustUnderstand': '1',
        },
      }],
      'wsa:To': [{
        _: 'http://core-dev-barramentoregional-docker:8280/services/DocumentRepository',
        $: {
          'soap:mustUnderstand': '1',
        },
      }],
    }],
    'soap:Body': [{
      'urn:ProvideAndRegisterDocumentSetRequest': [{
        'lcm:SubmitObjectsRequest': [{
          $: {
            'xmlns:lcm': 'urn:oasis:names:tc:ebxml-regrep:xsd:lcm:3.0',
          },
          'rim:RegistryObjectList': [{
            $: {
              'xmlns:rim': 'urn:oasis:names:tc:ebxml-regrep:xsd:rim:3.0',
            },
            'rim:Classification': [{
              $: {
                classifiedObject: 'ss1',
                classificationNode: 'urn:uuid:a54d6aa5-d40d-43f9-88c5-b4633d873bdd',
                id: 'cl1',
                objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
              },
            }],
            'rim:RegistryPackage': [{
              $: {
                id: 'ss1',
              },
              'rim:Slot': [],
              'rim:Classification': [
                {
                  $: {
                    classificationScheme: 'urn:uuid:a7058bb9-b4e4-4307-ba5b-e3f0ab85e12d',
                    classifiedObject: 'ss1',
                    id: 'cl2',
                    objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                    nodeRepresentation: '',
                  },
                  'rim:Slot': [{
                    $: {
                      name: 'authorPerson',
                    },
                    'rim:ValueList': [{
                      'rim:Value': ['#{#Project#patient-id}^^^^^^^^&#{#Project#patient-id-oid}&ISO'],
                    }],
                  }],
                }, {
                  $: {
                    classificationScheme: 'urn:uuid:aa543740-bdda-424e-8c96-df4873be8500',
                    classifiedObject: 'ss1',
                    id: 'cl3',
                    objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                    nodeRepresentation: '404684003',
                  },
                  'rim:Slot': [{
                    $: {
                      name: 'codingScheme',
                    },
                    'rim:ValueList': [{
                      'rim:Value': ['2.16.840.1.113883.6.96'],
                    }],
                  }],
                  'rim:Name': [{
                    'rim:LocalizedString': [{
                      $: {
                        value: 'Clinical finding',
                      },
                    }],
                  }],
                }],
              'rim:ExternalIdentifier': [{
                $: {
                  identificationScheme: 'urn:uuid:6b5aea1a-874d-4603-a4bc-96a0a7b38446',
                  value: '#{#Project#patient-id}^^^&#{#Project#patient-id-oid}&ISO',
                  id: 'ei1',
                  objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                  registryObject: 'ss1',
                },
                'rim:Name': [{
                  'rim:LocalizedString': [{
                    $: {
                      value: 'XDSSubmissionSet.patientId',
                    },
                  }],
                }],
              }, {
                $: {
                  identificationScheme: 'urn:uuid:554ac39e-e3fe-47fe-b233-965d2a147832',
                  value: '1.3.6.1.4.1.54443.1.1.5.2',
                  id: 'ei2',
                  objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                  registryObject: 'ss1',
                },
                'rim:Name': [{
                  'rim:LocalizedString': [{
                    $: {
                      value: 'XDSSubmissionSet.sourceId',
                    },
                  }],
                }],
              }],
            }],
            'rim:Association': [{
              $: {
                id: 'as1',
                objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Association',
                associationType: 'urn:oasis:names:tc:ebxml-regrep:AssociationType:HasMember',
                sourceObject: 'ss1',
                targetObject: 'doc1',
              },
              'rim:Slot': [{
                $: {
                  name: 'SubmissionSetStatus',
                },
                'rim:ValueList': [{
                  'rim:Value': ['Original'],
                }],
              }],
            }],
            'rim:ExtrinsicObject': [
              {
                $: {
                  id: 'doc1',
                  mimeType: 'text/xml',
                  objectType: 'urn:uuid:7edca82f-054d-47f2-a032-9b2a5b5186c1',
                },
                'rim:Slot': [
                  {
                    $: {
                      name: 'languageCode',
                    },
                    'rim:ValueList': [{
                      'rim:Value': ['pt-BR'],
                    }],
                  }, {
                    $: {
                      name: 'sourcePatientId',
                    },
                    'rim:ValueList': [{
                      'rim:Value': ['#{#Project#patient-id}^^^&#{#Project#patient-id-oid}&ISO'],
                    }],
                  }, {
                    $: {
                      name: 'URI',
                    },
                    'rim:ValueList': [{
                      'rim:Value': ['http://core-dev-barramentoregional-docker:8280/services/DocumentRepository'],
                    }],
                  },
                ],
                'rim:Name': [{
                  'rim:LocalizedString': [{
                    $: {
                      value: 'O paciente (CNS) consente acesso durante um episódio de cuidado. Este pode ser limitado ao profissional (CNS) ou Unidade de Saúde (CNES) e tem validade limitada.',
                    },
                  }],
                }],
                'rim:Classification': [
                  {
                    $: {
                      classificationScheme: 'urn:uuid:93606bcf-9494-43ec-9b4e-a7748d1a838d',
                      classifiedObject: 'doc1',
                      id: 'cl4',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                      nodeRepresentation: '',
                    },
                    'rim:Slot': [{
                      $: {
                        name: 'authorPerson',
                      },
                      'rim:ValueList': [{
                        'rim:Value': ['#{#Project#patient-id}^^^^^^^^#{#Project#patient-id-oid}&ISO'],
                      }],
                    }],
                  }, {
                    $: {
                      classificationScheme: 'urn:uuid:41a5887f-8865-4c09-adf7-e362475b143a',
                      classifiedObject: 'doc1',
                      id: 'cl5',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                      nodeRepresentation: 'urn:ihe:iti:appc:2016:consent',
                    },
                    'rim:Slot': [{
                      $: {
                        name: 'codingScheme',
                      },
                      'rim:ValueList': [{
                        'rim:Value': ['1.3.6.1.4.1.19376.1.2.3'],
                      }],
                    }],
                    'rim:Name': [{
                      'rim:LocalizedString': [{
                        $: {
                          value: 'Consent Document',
                        },
                      }],
                    }],
                  }, {
                    $: {
                      classificationScheme: 'urn:uuid:f4f85eac-e6cb-4883-b524-f2705394840f',
                      classifiedObject: 'doc1',
                      id: 'cl6',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                      nodeRepresentation: 'N',
                    },
                    'rim:Slot': [{
                      $: {
                        name: 'codingScheme',
                      },
                      'rim:ValueList': [{
                        'rim:Value': ['2.16.840.1.113883.5.25'],
                      }],
                    }],
                    'rim:Name': [{
                      'rim:LocalizedString': [{
                        $: {
                          value: 'Normal',
                        },
                      }],
                    }],
                  }, {
                    $: {
                      classificationScheme: 'urn:uuid:a09d5840-386c-46f2-b5ad-9c3699a4309d',
                      classifiedObject: 'doc1',
                      id: 'cl7',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                      nodeRepresentation: 'urn:ihe:iti:appc:2016:consent',
                    },
                    'rim:Slot': [{
                      $: {
                        name: 'codingScheme',
                      },
                      'rim:ValueList': [{
                        'rim:Value': ['1.3.6.1.4.1.19376.1.2.3'],
                      }],
                    }],
                    'rim:Name': [{
                      'rim:LocalizedString': [{
                        $: {
                          value: 'Consent Document',
                        },
                      }],
                    }],
                  }, {
                    $: {
                      classificationScheme: 'urn:uuid:f33fb8ac-18af-42cc-ae0e-ed0b0bdb91e1',
                      classifiedObject: 'doc1',
                      id: 'cl8',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                      nodeRepresentation: 'HOSP',
                    },
                    'rim:Slot': [{
                      $: {
                        name: 'codingScheme',
                      },
                      'rim:ValueList': [{
                        'rim:Value': ['2.16.840.1.113883.1.11.17660'],
                      }],
                    }],
                    'rim:Name': [{
                      'rim:LocalizedString': [{
                        $: {
                          value: 'Hospital',
                        },
                      }],
                    }],
                  }, {
                    $: {
                      classificationScheme: 'urn:uuid:f0306f51-975f-434e-a61c-c59651d33983',
                      classifiedObject: 'doc1',
                      id: 'cl10',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:Classification',
                      nodeRepresentation: '57016-8',
                    },
                    'rim:Slot': [{
                      $: {
                        name: 'codingScheme',
                      },
                      'rim:ValueList': [{
                        'rim:Value': ['2.16.840.1.113883.6.1'],
                      }],
                    }],
                    'rim:Name': [{
                      'rim:LocalizedString': [{
                        $: {
                          value: 'Privacy Policy Acknowledgement',
                        },
                      }],
                    }],
                  },
                ],
                'rim:ExternalIdentifier': [
                  {
                    $: {
                      identificationScheme: 'urn:uuid:58a6f841-87b3-4a3e-92fd-a8ffeff98427',
                      value: '#{#Project#patient-id}^^^&#{#Project#patient-id-oid}&ISO',
                      id: 'ei4',
                      objectType: 'urn:oasis:names:tc:ebxml-regrep:ObjectType:RegistryObject:ExternalIdentifier',
                      registryObject: 'doc1',
                    },
                    'rim:Name': [{
                      'rim:LocalizedString': [{
                        $: {
                          value: 'XDSDocumentEntry.patientId',
                        },
                      }],
                    }],
                  },
                ],
              }],
          }],
        }],
        REPLACE_ME: '',
      }],
    }],
  },
};

export const policy_opt_in = {
  PolicySet: {
    $: {
      PolicySetId: 'urn:uuid:fab9b3a5-4d09-11ea-8a80-7598024794b3',
      PolicyCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:policy-combining-algorithm:deny-overrides',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      'xmlns:hl7': 'urn:hl7-org:v3',
      xmlns: 'urn:oasis:names:tc:xacml:2.0:policy:schema:os',
      'xsi:schemaLocation': 'urn:oasis:names:tc:xacml:2.0:policy:schema:os ihe-appc-xacml-combined-schema-1.0.xsd',
    },
    Description: [
      'Politica de acesso por consentimento formal da plataforma',
    ],
    Target: [
      {
        Subjects: [
          {
            Subject: [
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:CV-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                        'hl7:CodedValue': [
                          {
                            $: {
                              code: 'Emergencia',
                              codeSystem: '1.3.6.1.4.1.54413.1.1.6.2',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:purposeofuse',
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:CV-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                        'hl7:CodedValue': [
                          {
                            $: {
                              code: 'Uso Secundario',
                              codeSystem: '1.3.6.1.4.1.54413.1.1.6.2',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:purposeofuse',
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        Resources: [
          {
            Resource: [
              {
                ResourceMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:II-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                        },
                        'hl7:InstanceIdentifier': [
                          {
                            $: {
                              root: '#{#Project#patient-id-oid}',
                              extension: '#{#Project#patient-id}',
                            },
                          },
                        ],
                      },
                    ],
                    ResourceAttributeDesignator: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                          AttributeId: 'urn:ihe:iti:ser:2016:patient-id',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    Policy: [
      {
        $: {
          PolicyId: 'urn:uuid:fab9b3a3-4d09-11ea-8a80-7598024794b3',
          RuleCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:rule-combining-algorithm:deny-overrides',
        },
        Target: [
          '',
        ],
        Rule: [
          {
            $: {
              RuleId: 'urn:uuid:fab9b3a4-4d09-11ea-8a80-7598024794b3',
              Effect: 'Permit',
            },
          },
        ],
      },
    ],
  },
};

export const policy_opt_out = {
  PolicySet: {
    $: {
      PolicySetId: 'urn:uuid:fab9b3a5-4d09-11ea-8a80-7598024794b3',
      PolicyCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:policy-combining-algorithm:deny-overrides',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      'xmlns:hl7': 'urn:hl7-org:v3',
      xmlns: 'urn:oasis:names:tc:xacml:2.0:policy:schema:os',
      'xsi:schemaLocation': 'urn:oasis:names:tc:xacml:2.0:policy:schema:os ihe-appc-xacml-combined-schema-1.0.xsd',
    },
    Description: [
      'Politica padrao da plataforma',
    ],
    Target: [
      {
        Subjects: [
          {
            Subject: [
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:CV-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                        'hl7:CodedValue': [
                          {
                            $: {
                              code: 'Atendimento',
                              codeSystem: '1.3.6.1.4.1.54413.1.1.6.2',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:purposeofuse',
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                      },
                    ],
                  },
                  // {
                  //   $: {
                  //     MatchId: 'urn:hl7-org:v3:function:CV-equal',
                  //   },
                  //   AttributeValue: [
                  //     {
                  //       _: '9911936',
                  //       $: {
                  //         DataType: 'http://www.w3.org/2001/XMLSchema#anyURI',
                  //       },
                  //     },
                  //   ],
                  //   SubjectAttributeDesignator: [
                  //     {
                  //       $: {
                  //         AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:organization-id',
                  //         DataType: 'http://www.w3.org/2001/XMLSchema#anyURI',
                  //       },
                  //     },
                  //   ],
                  // },
                ],
              },
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:CV-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                        'hl7:CodedValue': [
                          {
                            $: {
                              code: 'Emergencia',
                              codeSystem: '1.3.6.1.4.1.54413.1.1.6.2',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:purposeofuse',
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                SubjectMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:CV-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                        'hl7:CodedValue': [
                          {
                            $: {
                              code: 'Uso Secundario',
                              codeSystem: '1.3.6.1.4.1.54413.1.1.6.2',
                            },
                          },
                        ],
                      },
                    ],
                    SubjectAttributeDesignator: [
                      {
                        $: {
                          AttributeId: 'urn:oasis:names:tc:xspa:1.0:subject:purposeofuse',
                          DataType: 'urn:hl7-org:v3#CV',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        Resources: [
          {
            Resource: [
              {
                ResourceMatch: [
                  {
                    $: {
                      MatchId: 'urn:hl7-org:v3:function:II-equal',
                    },
                    AttributeValue: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                        },
                        'hl7:InstanceIdentifier': [
                          {
                            $: {
                              root: '#{#Project#patient-id-oid}',
                              extension: '#{#Project#patient-id}',
                            },
                          },
                        ],
                      },
                    ],
                    ResourceAttributeDesignator: [
                      {
                        $: {
                          DataType: 'urn:hl7-org:v3#II',
                          AttributeId: 'urn:ihe:iti:ser:2016:patient-id',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    Policy: [
      {
        $: {
          PolicyId: 'urn:uuid:fab9b3a3-4d09-11ea-8a80-7598024794b3',
          RuleCombiningAlgId: 'urn:oasis:names:tc:xacml:1.0:rule-combining-algorithm:deny-overrides',
        },
        Target: [
          '',
        ],
        Rule: [
          {
            $: {
              RuleId: 'urn:uuid:fab9b3a4-4d09-11ea-8a80-7598024794b3',
              Effect: 'Deny',
            },
          },
        ],
      },
    ],
  },
};
