import { endpoints } from 'configs';
import { storeData } from 'utils/AuxiliarFunctions';

const { principalDomain } = endpoints;

export const loginGOVBR = async () => {
  await storeData('provider', 'govbr');
  const route = window.location.href.split('//')[1].split('/')[1]; // Redireciona para o /vacinacao ou portal
  localStorage.setItem('redirectGov', route);
  const random = Math.floor(Math.random() * 1000000);
  const redirect = process.env.REACT_APP_GOVBR_REDIRECT_URI;
  const url = `${process.env.REACT_APP_GOVBR_CALLBACK}/authorize?response_type=code&client_id=${principalDomain}&scope=openid+email+phone+profile&redirect_uri=${redirect}&nonce=${random}&state=${random}`;
  window.location.replace(url);
};

export const loginOffice365 = async () => {
  await storeData('provider', 'microsoft');
  const clientId = process.env.REACT_APP_MICROSOFT_CREDENTIALS_CLIENT_ID;
  const scope = 'user.read openid profile offline_access';
  const redirect = process.env.REACT_APP_MICROSOFT_REDIRECT_URI;
  const clientRequest = process.env.REACT_APP_MICROSOFT_CREDENTIALS_CLIENT_REQUEST;
  const responseMode = 'query';
  const responseType = 'code';
  const url = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirect}&client-request-id=${clientRequest}&response_mode=${responseMode}&response_type=${responseType}&client_info=1`;

  window.location.replace(url);
};
