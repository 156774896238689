import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { IconContext } from 'react-icons';
import { FiFileMinus } from 'react-icons/fi';
import { colors, font_sizes } from './Style';
import './Visualizer.css';

const styles = StyleSheet.create({
  homeContainer: {
    height: window.innerHeight,
    backgroundColor: colors.opaque_white,
    margin: '2%',
    marginTop: '8%',
    marginBottom: '3%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'rgb(230, 230, 230)',
    borderRadius: 5,
  },
  homeText: {
    fontSize: font_sizes.level3Title,
    color: colors.dark_green,
    fontWeight: 'bold',
  },
});

const DocumentError = () => (
  // <div className={css(styles.homeContainer)}>
  <div className="Visualizer_documentViewerError">
    <IconContext.Provider value={{ color: colors.dark_green, size: '30%' }}>
      <FiFileMinus style={{ alignSelf: 'center' }} />
    </IconContext.Provider>
    <p className={css(styles.homeText)}>Documento inválido</p>
  </div>
);

export default DocumentError;
