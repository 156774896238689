import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { BackToLogin } from 'utils/AuxiliarFunctions';
import './styles.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  async componentDidCatch(error, info) {
    const { goHome } = this.props;
    // logDebug('Error :>> ', error);
    // logDebug('Info :>> ', info);
    if (goHome) BackToLogin();
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      if (fallback) return <Row><Col><p>{fallback}</p></Col></Row>; // renderiza o que vem como prop

      return (
        <div className="err-boundary-scr">
          <h1>
            Erro interno, favor tente novamente.
          </h1>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.defaultProps = {
  goHome: null,
  fallback: null,
};

ErrorBoundary.propTypes = {
  goHome: PropTypes.bool,
  fallback: PropTypes.string,
};
export { ErrorBoundary };
