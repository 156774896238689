import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { statesBrRequest } from 'utils/externalAPIs';
import { Loading, DropDownFilter } from 'components';

const ChooseUF = ({ onChooseUF, preSelectedUf }) => {
  // const [uf, setUF] = useState({});
  const [ufList, setUFList] = useState({});
  const [loading, setLoading] = useState(true);
  const [preSelectedId, setPreSelectedId] = useState(null);

  useEffect(() => {
    const getUFList = async () => {
      let ufs = await statesBrRequest();
      if (!ufs) return null;
      if (ufs) ufs = ufs.filter((state) => state.regiao.sigla === 'NE'); // filtra todos menos nordeste
      setUFList(ufs);
      if (preSelectedUf && ufs) {
        const ufId = ufs.filter((state) => state.sigla === preSelectedUf)[0].id;
        onChooseUF(ufId);
        setPreSelectedId(ufId);
      }
      setLoading(false);
      return null;
    };
    getUFList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading size="mini"/>;

  return (
    <div>
      <DropDownFilter
        items={ufList}
        handleClick={onChooseUF}
        placeholder="Escolha o Estado"
        type="estados"
        disabled={Boolean(preSelectedUf)}
        preSelectedId={preSelectedId}
      />

    </div>
  );
};
ChooseUF.propTypes = {
  onChooseUF: PropTypes.func.isRequired,
  preSelectedUf: PropTypes.string,
};

ChooseUF.defaultProps = {
  preSelectedUf: '',
};

export { ChooseUF };
