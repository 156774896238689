/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SignUp.css';

export default class SecondForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      govBr_logged: !!this.props.govBr_logged,
      // loadedCheckInputs: false,

    };
  }

  render() {
    const {
      password, confirmPassword,
      dataAlert, showAlert, govBr_logged,
    } = this.state;
    return (
      <div>
        <div className="SignUpDivText">
          <h6>
            Complete os dados do seu perfil, pois eles serão validados junto ao cadastro do Cartão Nacional de Saúde. Os dados com * são obrigatórios.
          </h6>
        </div>
        {govBr_logged
          && (
          <div>
            <p>
              Esta senha é para o método alternativo de login, sem a necessidade do GovBr
            </p>
          </div>
          )}
        <Container style={{ width: '100%', height: '100%' }}>
          <Col>
            <Row>
              <Col>
                <input
                  maxLength="50"
                  tabIndex="7"
                  className="SignUpInput"
                  type="password"
                  value={password}
                  placeholder="Senha*"
                  onChange={(event) => this.setState({ password: event.target.value })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  maxLength="50"
                  tabIndex="8"
                  className="SignUpInput"
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirme a senha*"
                  onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                />
              </Col>
            </Row>
          </Col>
        </Container>
        {showAlert === true ? <p style={{ color: 'red' }}>{dataAlert}</p> : null}
        <div className="SignUpButtonDiv">
          <button
            tabIndex="9"
            className="SignUpInput SignUpButton"
            type="button"
            onClick={() => {
              // eslint-disable-next-line react/destructuring-assignment
              this.props.triggerUpdateParent(password, confirmPassword);
            }}
          >
            Cadastrar
          </button>
        </div>
      </div>
    );
  }
}
