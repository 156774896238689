/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// /* eslint-disable no-nested-ternary */
// /* eslint-disable guard-for-in */
// /* eslint-disable no-restricted-syntax */
// /* eslint-disable class-methods-use-this */
// /* eslint-disable react/sort-comp */

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { Accordion, Card } from 'react-bootstrap';
import { FaReadme } from 'react-icons/fa';
import { retrieveData } from 'utils/AuxiliarFunctions';
import xdsToken from 'services/xdsToken';
import './PolicyPage.css';
import policyRequests from 'services/policyRequests';
import { Loading, SimpleModal } from 'components';
import i18n from 'i18n';

function t(translation) {
  return i18n.t(translation, { ns: 'PolicyPage' });
}

class PolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_policy: {},
      chosen_policy: {},
      loading: true,
      showAlert: false,
      dataAlert: [],
    };

    this.policy_options = [
      {
        key: 0,
        value: 'Opt-in',
        display: t('policyPage-policyOptions-optionPositiveDisplay'),
        description: t('policyPage-policyOptions-optionPositiveDescription'),
      },
      {
        key: 1,
        value: 'Opt-out',
        display: t('policyPage-policyOptions-optionNegativeDisplay'),
        description: t('policyPage-policyOptions-optionNegativeDescription'),
      },
    ];
    this.generateToken = this.generateToken.bind(this);
  }

  async componentDidMount() {
    this.onRefresh();
  }

  // eslint-disable-next-line class-methods-use-this
  async onRefresh() {
    this.setState({ loading: true });
    await this.getPolicy();
    await this.generateToken();
    this.setState({ loading: false });
  }

  async getPolicy() {
    const cpf = await retrieveData('username');
    const policy = await policyRequests.getPolicyRequest(cpf);
    this.setState({ loading: true });
    if (typeof policy === 'string') {
      this.setState({ loading: false });
      return;
    }
    this.setState({
      current_policy: this.policy_options.find((data) => data.value === policy.politicaRES),
      chosen_policy: this.policy_options.find((data) => data.value === policy.politicaRES),
      loading: false,
    });
  }

  async updatePolicy() {
    const { chosen_policy } = this.state;
    const user_logged = true;
    const cpf = await retrieveData('username');

    this.setState({ loading: true });
    const response = await policyRequests.setPolicyRequest(chosen_policy.value, cpf);

    if (response === 'error') this.setState({ dataAlert: [t('policyPage-alertMessage-textError'), t('policyPage-alertMessage-tryAgainLater')], showAlert: true });
    else if (user_logged) this.setState({ dataAlert: [t('policyPage-alertMessage-policyChange'), `${t('policyPage-alertMessage-newPolicy')} "${chosen_policy.display}"`], showAlert: true });

    this.setState({ loading: false });
  }

  async choosePolicy(policy) {
    await this.setState({ chosen_policy: policy });
    await this.updatePolicy();
  }

  // eslint-disable-next-line class-methods-use-this
  async generateToken() {
    await xdsToken.getBearerToken();
  }

  accordion() {
    const { loading } = this.state;

    if (loading) return (<div className="policyPage-Loading"><Loading size="mini" /></div>);

    return (
      <div className="accordion-terms">
        <Accordion defaultActiveKey="1">
          <Card>
            <Card.Header style={{ padding: '0', margin: '0' }}>
              <Accordion.Toggle
                eventKey="0" style={{
                  width: '100%', padding: '0', margin: '0',
                }}
                variant="outline-dark" as={Button}
              >
                <FaReadme size="2.5rem" />
                <p className="policy-terms-title">
                  {t('policyPage-infoPolicy-titleInfo')}
                </p>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="policy-terms-body">
                  <p className="policy-terms-text">

                    {t('policyPage-textPolicy-textInfo1paragraphe')}

                  </p>
                  <p className="policy-terms-text">

                    {t('policyPage-textPolicy-textInfo2paragraphe')}

                  </p>
                  <p className="policy-terms-text">
                    {t('policyPage-textPolicy-textInfo3paragraphe')}
                  </p>
                  <p className="policy-terms-text">
                    {t('policyPage-textPolicy-textInfo4paragraphe')}

                  </p>

                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }

  renderCard(policy) {
    const { chosen_policy } = this.state;
    if (chosen_policy === policy) {
      return (
        <button
          type="button"
          className="Policy_card_chosen"
          onClick={async () => this.choosePolicy(policy)}
        >
          <p className="Policy_title_chosen">
            {policy.display}
          </p>
          {/* <p className="Policy_card_info_chosen">{policy.description}</p> */}
        </button>
      );
    }
    return (
      <button
        type="button"
        className="Policy_card"
        onClick={async () => this.choosePolicy(policy)}
      >
        <p className="Policy_title">
          {policy.display}
        </p>
        {/* <p className="Policy_card_info">{policy.description}</p> */}
      </button>
    );
  }

  render() {
    const {
      loading,
      showAlert,
      dataAlert,
    } = this.state;

    const modal = {
      title: dataAlert[0],
      body: dataAlert[1],
      footer: '',
      visible: showAlert,
      cancelButton: t('policyPage-modalClose-buttomClose'),
    };

    if (loading) return <div className="policyPage-Loading"><Loading size="large" /></div>;

    return (
      <div className="policyPage-container">
        {/* <h1 className="Policy_h1">Política de Privacidade</h1> */}
        <p className="Policy_info">
          {t('policyPage-titlePage-textTitle')}
        </p>

        {this.renderCard(this.policy_options[0])}
        {this.renderCard(this.policy_options[1])}
        {this.accordion()}

        <SimpleModal fullWidth={false} setModal={() => this.setState({ showAlert: false })} modal={modal} />
      </div>
    );
  }
}

export default PolicyPage;
