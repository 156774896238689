/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */

import { endpoints } from 'configs';
import {
  CNSisValid, cpfValidator, logDebug, retrieveData,
} from 'utils/AuxiliarFunctions';
import { oids } from 'utils/Constants';
// import { getEntriesTimeline } from 'utils/fhirParser';
import AdverseEvent from 'utils/fhirObjects/AdverseEvent';
import xdsToken from './xdsToken';

const { XDSB_domain } = endpoints;

class userRequests {
    static catchError = (err, from) => {
      logDebug(`ERROR in userRequests: ${from}: `, err);
      switch (parseInt(err.message, 10)) {
        case (500):
          return 'internal_error';
        case (504):
          return 'timeout';
        case (400):
          return 'bad_request';
        case (401):
          return 'unauthorized';
        case (403):
          return 'forbidden';
        case (409):
          return 'conflict';
        default:
          return 'network_error';
      }
    }

    static async getPatient(userId) {
      const token = await retrieveData('token');
      const applicationToken = await xdsToken.getApplicationToken();

      const isCpf = cpfValidator(userId);
      if (!isCpf) {
        const isCns = CNSisValid(userId);
        if (!isCns) return 'invalid_user';
      }
      const url = `${XDSB_domain}/user/fhir/Patient/${isCpf ? oids.cpf : oids.cns}-${userId}`;
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${applicationToken}`);
      myHeaders.append('X-User-Token', token);
      myHeaders.append('Content-Type', 'application/fhir+json');
      myHeaders.append('Accept', 'application/fhir+json');

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const patient = await fetch(url, requestOptions)
        .then((response) => {
          if (response.status !== 200) throw new Error(response.status);
          return (response.json());
        })
        .catch((err) => userRequests.catchError(err, 'getPatient'));

      if (typeof patient === 'string') return patient;
      return patient;
    }

    static async patchPatient({
      uf, city,
      postalCode, number,
      district,
      address, user,
      complement,
    }) {
      const token = await retrieveData('token');
      const applicationToken = await xdsToken.getApplicationToken();

      const isCpf = cpfValidator(user);
      if (!isCpf) {
        const isCns = CNSisValid(user);
        if (!isCns) return 'invalid_user';
      }
      const url = `${XDSB_domain}/user/fhir/Patient/${isCpf ? oids.cpf : oids.cns}-${user}`;
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${applicationToken}`);
      myHeaders.append('X-User-Token', token);
      myHeaders.append('Content-Type', 'application/fhir+json');
      myHeaders.append('Accept', 'application/fhir+json');

      //   ('CEP', postalCode)
      //   ('Estado', uf.name)
      //   ('Cidade', city.name)
      //   ('Logradouro', address)
      //   ('Número', number)
      //   ('Tipo', type)
      //   ('Bairro', district)

      const body = [
        {
          op: 'add',
          path: '/address/0',
          value: {
            line: [
              address,
              number,
              complement || '',
              district,
            ],
            city,
            state: uf,
            postalCode,
            country: '010',
          },
        },
      ];
      logDebug('show request body', body);

      const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(body),
      };

      const patient = await fetch(url, requestOptions)
        .then((response) => {
          if (response.status !== 200) throw new Error(response.status);
          return (response.json());
        })
        .catch((err) => userRequests.catchError(err, 'patchPatient'));

      if (typeof patient === 'string') return patient;
      return patient;
    }

    static async reportAdverseEvent({
      effects, immunobiologicalId,
    }) {
      await xdsToken.getBearerToken();
      const token = await retrieveData('token');
      const applicationToken = await xdsToken.getApplicationToken();
      const url = `${XDSB_domain}/user/fhir`;
      const username = await retrieveData('username');

      const body = {
        resourceType: 'Bundle',
        entry: effects.map((effect) => ({
          resource: new AdverseEvent({
            name: effect.display, username, fromDate: effect.fromDate, immunobiologicalId,
          }).getJSON(),
          request: {
            method: 'POST',
            url: 'AdverseEvent',
          },
        })),
      };
      logDebug('body', body);

      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${applicationToken}`);
      myHeaders.append('X-User-Token', token);
      myHeaders.append('Content-Type', 'application/fhir+json');
      myHeaders.append('Accept', 'application/fhir+json');

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow',
      };

      const fhirResponse = await fetch(url, requestOptions)
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) throw new Error(response.status);
          return (response.json());
        })
        .catch((err) => userRequests.catchError(err, 'reportAdverseEvent'));

      if (typeof fhirResponse === 'string') return fhirResponse;
      return fhirResponse;
    }
}

export default userRequests;
