/* eslint-disable no-console */
import { endpoints } from 'configs';
import {
  retrieveData, setRoles, storeData,
  fetchWithTimeout, BackToLogin, verifyUnauthorized, logDebug,
} from 'utils/AuxiliarFunctions';
import xdsToken from './xdsToken';

const { Scim2 } = endpoints;

const DEBUGMODE = false;
export default class profileRequests {
  static catchError = (err, from) => {
    logDebug(`ERROR in profileRequests: ${from}: `, err);
    switch (parseInt(err.message, 10)) {
      case (500):
        return 'internal_error';
      case (504):
        return 'timeout';
      case (400):
        return 'bad_request';
      case (401):
        return 'unauthorized';
      case (403):
        return 'not_authorized';
      default:
        return 'network_error';
    }
  }

  static async getProfileData(patient_id) {
    const url = `${Scim2}/Users/.search`;
    const token = await retrieveData('token');
    const applicationToken = await xdsToken.getApplicationToken();
    if (DEBUGMODE) logDebug('token_response', token);
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${applicationToken}`,
        'X-User-Token': token,
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        schemas: [
          'urn:ietf:params:scim:api:messages:2.0:SearchRequest',
        ],
        filter: `userName Eq ${patient_id}`,
        domain: 'PRIMARY',
        startIndex: 1,
        count: 10,
      }),
      timeout: 2000,
    };

    if (DEBUGMODE) logDebug('requestOptions', requestOptions);

    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      requestOptions,
      6000,
    )
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response);
      }).catch(
        (err) => profileRequests.catchError(err, 'getProfileData'),
      );

    verifyUnauthorized(fetchWithTimeout_response);

    if (typeof fetchWithTimeout_response === 'string') return fetchWithTimeout_response;

    const responseJson = await fetchWithTimeout_response.json();
    return responseJson;
  }

  static async refreshProfileData() {
    let response = '';
    try {
      const cpf = await retrieveData('username');
      response = await profileRequests.getProfileData(cpf);
      await setRoles(response);
      const data = {
        name: response.Resources[0].name.givenName,
        birthday: response.Resources[0].title,
        patient_id: response.Resources[0].id,
        cns: response.Resources[0].EnterpriseUser.manager.$ref,
        gender: response.Resources[0].EnterpriseUser.manager.value,
        city: response.Resources[0].locale?.split('-')[0] ? response.Resources[0].locale.split('-')[0] : '',
        uf: response.Resources[0].locale?.split('-')[1] ? response.Resources[0].locale.split('-')[1] : '',
        motherName: response.Resources[0].EnterpriseUser
          .manager.displayName,
        phone: response.Resources[0].phoneNumbers ? response.Resources[0].phoneNumbers[0]?.value : '',
        email: response.Resources[0].emails[0],
      };

      let organizationIds = [];
      const { entitlements } = response.Resources[0];
      if (entitlements) {
        organizationIds = entitlements.map(
          (entitlement,
            index) => response.Resources[0].entitlements[index].value,
        );
        await storeData('cnesToUse', organizationIds[0]);
      }
      organizationIds = JSON.stringify({ ids: organizationIds });
      // TODO: GET CNES FROM PRacttitionerRole
      await storeData('cnes', organizationIds);
      await storeData('profileData', JSON.stringify(data));
    } catch (err) {
      logDebug(`Erro: setProfileData: ${err}`);
      await BackToLogin();
    }
  }

  static async setProfileData(id, phone, email) {
    const url = `${Scim2}/Users/${id}`;

    const token = await retrieveData('token');
    const applicationToken = await xdsToken.getApplicationToken();

    const data = {};
    if (phone) {
      data.phoneNumbers = [
        {
          value: phone,
          type: 'mobile',
        },
      ];
    }
    if (email) data.emails = [{ value: email }];

    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${applicationToken}`,
        'X-User-Token': token,
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
      body: JSON.stringify({
        schemas: [
          'urn:ietf:params:scim:api:messages:2.0:PatchOp',
        ],
        Operations: [
          {
            op: 'add',
            value: data,
          },
        ],
      }),
      timeout: 2000,
    };
    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      requestOptions,
    ).then((response) => {
      if (response.status !== 200) throw new Error(response.status);
      return (response);
    }).catch((err) => profileRequests.catchError(err, 'setProfileData'));

    verifyUnauthorized(fetchWithTimeout_response);

    if (typeof fetchWithTimeout_response === 'string') return fetchWithTimeout_response;

    const responseJson = await fetchWithTimeout_response.json();
    return responseJson;
  }
}
