/*
  Componente utilizado para renderizar o conteúdo da página de Login.

  Projeto: fesf
  Path destino: src/pages/Login/LoginContent/
  Path origem:
    src/projects/fesf/specificComponents/LoginContent/LoginContent.jsx

  Caso queira modificar este código, lembre-se que ele é copiado para o
  path destino durante a etapa de pré build. Portanto durante a execução,
  testes podem ser feitos no path destino que está sendo executado, mas a
  mudança deve ser replicado o componente no path de origem.

  Caso a mudança seja genérica para os outros componentes irmão, deve ser
  replicada para TODOS os outros LoginContent nos outros projetos.
  Obs.: se a mudança for genérica o ideal é que ela seja feita no
  componente acima de LoginContent a fim de generalizar melhor o uso.
*/
import React, { useState, useEffect } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Button } from 'components';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

import logo from 'theme/logo+name.png';
import { features } from 'configs';
import { loginGOVBR, loginOffice365 } from 'pages/Login/loginUtils';
import { LoginForm } from 'pages/Login/LoginForm';
import { Loading } from 'components/Loading';

import { DeployVersion } from 'components/DeployVersion';

export function LoginContent({ setPassRecovery, bypassLogin }) {
  const history = useHistory();
  const { t } = useTranslation('LoginPage', { i18n });
  const [cpfValid, setCpfValid] = useState(false);

  useEffect(() => {
    if (bypassLogin) {
      if (features.loginWithGovBr) loginGOVBR();
      else if (features.loginWithOffice365) loginOffice365();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!bypassLogin) {
    return (
      <>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <img className="Login_logo" src={logo} alt="Logo" />

          {features.regularLogin && (
            <>
              <LoginForm setCpfValid={setCpfValid} cpfValid={cpfValid} />
              <div className="Login_formContainer">
                <Button
                  variant="link"
                  onClick={() => setPassRecovery(true)}
                  color="custom"
                >
                  {t('loginContent-buttomLogin-forgetPassword')}
                </Button>
              </div>

              <div className="Login_formContainer">
                <Button
                  variant="link"
                  onClick={() => history.push('/signup')}
                  color="custom"
                >
                  {t('loginContent-buttomLogin-signInUser')}
                </Button>
              </div>
            </>
          )}

          {features.loginWithGovBr && (
          <div className="Login_formContainer">
            <Button
              className="Login_govBrButton"
              variant="govBR"
              color="custom"
              onClick={loginGOVBR}
            >
              Entrar com
              <div style={{ fontWeight: 'bold' }}>govBR</div>
            </Button>
          </div>
          )}

          {features.loginWithOffice365 && (
          <div className="Login_formContainer">
            <Button
              className="Login_Office365Button"
              variant="office365"
              color="custom"
              onClick={loginOffice365}
            >
              Acesso para funcionário com a conta
              <div style={{ fontWeight: 'bold' }}>Microsoft</div>
            </Button>
          </div>
          )}

          <DeployVersion variant="login" />
        </Grid>

        <Hidden smDown>
          <div className="login_GradientProject" />
        </Hidden>
      </>
    );
  }
  if (bypassLogin) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          minHeight: '80vh',
        }}
      >
        <Loading size="large" />
      </div>
    );
  }
}

LoginContent.propTypes = {
  setPassRecovery: PropTypes.func.isRequired,
};
