import xdsToken from 'services/xdsToken';
import { endpoints } from 'configs';
import {
  eliminateLeastSignificantNumber, fetchWithTimeout, logDebug,
  retrieveData,
} from './AuxiliarFunctions';
import { statesBrConst } from './Constants';

const {
  IBGE_domain,
  XDSB_domain,
} = endpoints;

export const statesBrRequest = async function () {
  const statesBrLink = IBGE_domain;
  let statesBr = await fetchWithTimeout(statesBrLink);
  statesBr = await statesBr.json();
  statesBr.sort((a, b) => a.nome[0].localeCompare(b.nome[0])); // Alphabetic order
  return statesBr;
};

export const citiesBrRequest = async function (ID) {
  const citiesBrLink = `${IBGE_domain}/${ID}/municipios`;
  let citiesBr = await fetchWithTimeout(citiesBrLink);
  citiesBr = await citiesBr.json();
  citiesBr.sort((a, b) => a.nome[0].localeCompare(b.nome[0])); // Alphabetic order
  // logDebug('citiesBr', citiesBr)
  return citiesBr;
};

export const getCityFromIBGE = async function (ID) {
  const citiesBrLink = `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${ID}`;
  let city = await fetchWithTimeout(citiesBrLink);
  city = await city.json();
  return city;
};

export const getIbgeCityById = async function (
  { state_acronym, city_id, state_id },
) {
  try {
    const newCityId = eliminateLeastSignificantNumber(city_id);
    let stateId = state_id;
    if (!stateId) {
      stateId = statesBrConst.filter(
        (item) => item.sigla === state_acronym,
      )[0].id;
    }
    const allCities = await citiesBrRequest(stateId);
    const cityData = allCities.filter((item) => item.id === newCityId);
    if (cityData.length === 0) return allCities.filter((item) => `${Math.floor(item.id / 10)}` === `${newCityId}`)[0];
    return cityData[0];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return null;
  }
};

const catchError = (err, from) => {
  logDebug(`ERROR in fhirRequests: ${from}: `, err);
  switch (parseInt(err.message, 10)) {
    case (500):
      return 'internal_error';
    case (504):
      return 'timeout';
    case (400):
      return 'bad_request';
    case (401):
      return 'unauthorized';
    case (403):
      return 'forbidden';
    case (409):
      return 'conflict';
    default:
      return 'network_error';
  }
};

export const getAddressByCep = async function (cep = null) {
  const token = await retrieveData('token');
  const applicationToken = await xdsToken.getApplicationToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${applicationToken}`);
  myHeaders.append('X-User-Token', token);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  if (!cep) return '';
  const url = `${XDSB_domain}/utils/cep/1.0.0/${cep}`;
  const resp = await fetchWithTimeout(url, requestOptions, 2000)
    .then((response) => response.json())
    .catch((err) => catchError(err, 'getAddressByCep'));

  return resp;
};
