/* eslint-disable func-names */
/* eslint-disable camelcase */

import base64 from 'base-64';
import { formatDateToRequest } from 'utils/dateFunctions';
import {
  storeData, fetchWithTimeout, logDebug,
} from 'utils/AuxiliarFunctions';

import { endpoints } from 'configs';

const {
  token_api,
  // Scim2,
  XDSB_domain,
  PassRecovery,
  SignUPURL,
  SignUP_c_key,
  SignUP_c_secret,
  PassRecoveryBearer,
  dataValidatorUrl,
} = endpoints;
const DEBUGMODE = false;

export const signUpAuth = async function () {
  const url = `${token_api}`;
  const basic_token = base64.encode(`${SignUP_c_key}:${SignUP_c_secret}`);

  const myHeaders = new Headers();
  myHeaders.append('Accept', '*/*');
  myHeaders.append('Authorization', `Basic ${basic_token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  const request = {
    method: 'POST',
    headers: myHeaders,
    body: 'grant_type=client_credentials',
  };
  const auth_response = await fetchWithTimeout(url, request).then(
    (response) => {
      if (response.status !== 200) throw new Error(response.status);
      return (response);
    },
  ).catch((err) => {
    logDebug(`signUpAuth ERROR: ${err}`);

    switch (err) {
      case (500):
        return 'internal_error';
      case (400):
        return 'bad_request';
      default:
        return ('network_error');
    }
  });

  if (typeof auth_response === 'string') return {};
  const responseJson = await auth_response.json();
  await storeData('scim_token', responseJson);
  return responseJson;
};

export const revokeToken = async function (token_response) {
  // eslint-disable-next-line no-undef
  const myHeaders = new Headers();
  const basic_token = base64.encode(`${SignUP_c_key}:${SignUP_c_secret}`);
  myHeaders.append('Authorization', `Basic ${basic_token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const raw = `token=${token_response.access_token}`;

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  fetchWithTimeout(`${XDSB_domain}/revoke`, requestOptions)
  // eslint-disable-next-line no-console
    .catch((error) => { logDebug(`revoke token ERROR: ${error}`); });
};

const signUpRequests = async function (
  userID, userEmail, userPhoneNumber, userPassword, fcm,
) {
  const token_response = await signUpAuth();
  if (!token_response) return 'missing_token';

  const url = SignUPURL;
  let newBody = null;

  newBody = {
    telefone: userPhoneNumber.replace(/\D/g, ''),
    email: userEmail,
    userName: userID,
    tokenFirebase: fcm,
    password: userPassword,
  };

  newBody = JSON.stringify(newBody);

  const myHeaders = new Headers();
  myHeaders.append('Accept', '*/*');
  myHeaders.append('Authorization', `Bearer ${token_response.access_token}`);
  myHeaders.append('Content-Type', 'application/json');

  const doc_request = {
    method: 'POST',
    headers: myHeaders,
    body: newBody,
  };

  logDebug('SIGN UP REQUESTS doc_request----------------------------');
  logDebug(doc_request);

  if (!DEBUGMODE) {
    const signUpResponse = await fetchWithTimeout(url, doc_request)
      .then((response) => {
        if (response.status !== 201
          && response.status !== 200
          && response.status !== 409) throw new Error(response.status);

        return (response);
      })
      .catch((err) => {
        logDebug(`signUpRequests ERROR: ${err}`);
        switch (err.message) {
          case ('500'):
            return 'internal_error';
          case ('400'):
            return 'bad_request';
          case ('401'):
            return 'Unauthorized';
          default:
            return ('network_error');
        }
      });

    return signUpResponse;
  }
  return null;
};

// export const statesBrRequest = async function () {
//   const statesBrLink = IBGE_domain;
//   let statesBr = await fetchWithTimeout(statesBrLink);
//   statesBr = await statesBr.json();
//   statesBr.sort((a, b) => a.nome[0].localeCompare(b.nome[0]));
//   return statesBr;
// };

// export const citiesBrRequest = async function (ID) {
//   const citiesBrLink = `${IBGE_domain}/${ID}/municipios`;
//   let citiesBr = await fetchWithTimeout(citiesBrLink);
//   citiesBr = await citiesBr.json();
//   citiesBr.sort((a, b) => a.nome[0].localeCompare(b.nome[0]));
//   // logDebug('citiesBr', citiesBr)
//   return citiesBr;
// };

export const recover_password = async function (username) {
  // Funcao que faz requisicao de recuperacao de senha
  const raw = JSON.stringify(
    {
      user: {
        username: `${username}`,
        realm: 'PRIMARY',
        'tenant-domain': 'carbon.super',
      },
      properties: [],
    },
  );
  const token = PassRecoveryBearer;
  // const token = '26f13b16-87dc-3663-8369-cd930e5aa727';
  const url = `${PassRecovery}/recover-password?type=email&notify=true`;
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: raw,
    redirect: 'follow',
  };

  await fetchWithTimeout(url, requestOptions)
    .then((response) => response.text())
    .then((result) => { logDebug(result); })
    .catch((error) => { logDebug('error', error); });
};

export const dataValidator = async function (name, birthdate, cpf) {
  // Funcao que valida os dados no Cartão Nacional
  const data = {
    name,
    birthdate: formatDateToRequest(birthdate),
    'user-id': cpf,
  };

  const token_response = await signUpAuth();
  const url = dataValidatorUrl;

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token_response.access_token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(data),
  };

  const resp = await fetchWithTimeout(url, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      logDebug(`dataValidator ERROR: ${err}`);
      switch (err.message) {
        case ('500'):
          return 'internal_error';
        case ('400'):
          return 'bad_request';
        case ('401'):
          return 'Unauthorized';
        default:
          return ('network_error');
      }
    });

  return resp;
};

export default signUpRequests;
