import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, SimpleModal } from 'components';
import fhirRequests from 'services/fhirRequests';
import { logDebug, capitalize_first_letters } from 'utils/AuxiliarFunctions';
import { FilesTable } from './FilesTable';

function EligibilityFileManager({
  patientName,
  patientId,
  groups,
  disabled,
  title,
  groupNames,
  cityGroups,
}) {
  const [modal, setModal] = useState({
    cancelButton: 'Fechar',
    visible: false,
  });
  const [documentReferences, setDocumentReferences] = useState({
    array: [],
    init: false,
  });

  function getGroupName(documentResource) {
    try {
      const groupObj = documentResource.meta.tag.find(
        (item) => item.system
          === 'http://www.saude.gov.br/fhir/r4/CodeSystem/BRGrupoAtendimento',
      );
      const groupName = groupObj.display;
      return groupName;
    } catch (err) {
      logDebug('EligibilityFileManager extractDocumentReferenceInfo :>> ', err);
    }

    try {
      let docReferenceGroup = documentResource.context.related.find((item) => item?.reference.includes('Group'));
      docReferenceGroup = docReferenceGroup.reference.split('/')[1];
      docReferenceGroup = cityGroups.find(
        (item) => item.id === docReferenceGroup,
      );
      const groupName = docReferenceGroup.code.coding[0].display;
      return groupName;
    } catch (err) {
      logDebug('EligibilityFileManager extractDocumentReferenceInfo :>> ', err);
    }

    return 'Grupo não encontrado';
  }

  const extractDocumentReferenceInfo = (document) => {
    const resource = document?.resource;
    const groupName = getGroupName(resource);
    const creationTime = resource?.date;
    const documentType = Array.isArray(resource?.content)
      ? resource.content[0]?.attachment?.contentType
      : null;
    const binaryReference = resource?.masterIdentifier?.value;
    const binaryId = binaryReference.split('oid:')[1];

    const documentInfos = {
      id: document?.resource?.id,
      creationTime,
      documentType,
      binaryId,
      resource,
      groupName,
    };

    return documentInfos;
  };

  const loadDocumentReferences = async () => {
    const response = await fhirRequests.getEligibilityDocumentReferences({
      patientId,
    });
    let documentsExtracted = [];
    try {
      documentsExtracted = response.entry.map((item) => extractDocumentReferenceInfo(item));
    } catch (err) {
      logDebug(err);
    }
    setDocumentReferences({ array: documentsExtracted, init: true });
  };

  const openFileManager = async () => {
    await loadDocumentReferences();
  };

  useEffect(() => {
    const openDocumentReferencesTable = () => {
      setModal({
        ...modal,
        title: <h3>Documentos Comprobatórios</h3>,
        body: (
          <>
            <h4>
              {capitalize_first_letters(patientName)}
              {groups?.length
                ? ` - ${groups.map((item) => item.code.coding[0].display)}`
                : null}
              {!groups.length && groupNames ? ` - ${groupNames}` : null}
            </h4>
            <FilesTable
              files={documentReferences.array}
              reloadTable={() => loadDocumentReferences()}
            />
          </>
        ),
        visible: true,
        maxWidth: 'sm',
      });
    };
    if (documentReferences.init) openDocumentReferencesTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentReferences]);

  return (
    <>
      <SimpleModal modal={modal} setModal={setModal} />
      <Button
        disabled={disabled}
        disableElevation
        variant="outlined"
        color={disabled ? 'default' : 'custom'}
        onClick={() => openFileManager()}
      >
        {title}
      </Button>
    </>
  );
}

EligibilityFileManager.defaultProps = {
  patientId: '',
  patientName: '',
  groups: [],
  groupNames: null,
  disabled: false,
  title: 'Arquivos',
};

EligibilityFileManager.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  groupNames: PropTypes.string,
  patientName: PropTypes.string,
  patientId: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  cityGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      // context: PropTypes.shape(
      //   related: PropTypes.arrayOf(
      //     PropTypes.shape(
      //       reference: PropTypes.
      //     ),
      //   ).isRequired,
      // ).isRequired,
    }),
  ).isRequired,
};

export { EligibilityFileManager };
