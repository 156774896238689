import React from 'react';
import { calculateAge, formatDateDisplay } from 'utils/dateFunctions';
import { capitalize_first_letters, formatName } from 'utils/AuxiliarFunctions';
import '../Visualizer.css';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { TimelineDrawer } from '../TimelineDrawer';

// import { FaUser, FaUserInjured } from 'react-icons/fa';

const Header = ({
  patientName, birthdate, gender, patientId,
  subjectId, socialName, patientOID = null, patientCNS = null,
  organizationId, selectReport, purposeOfUse, subjectToken,
  subjectRole,
}) => {
  const { t } = useTranslation('PatientSummary');
  const destructBirthdate = formatDateDisplay(birthdate).split('/');
  const day = destructBirthdate[0];
  const month = destructBirthdate[1];
  const year = destructBirthdate[2];

  return (
    <div className="Visualizer_header">
      <div className="Visualizer_row">
        {/* <div style={{ flex: 1 }} className="Visualizer_column">
          <div>
            {' '}
            <div style={{ marginLeft: '15%' }}>
              <TimelineDrawer
                patientId={patientId}
                subjectId={subjectId}
                organizationId={organizationId}
                selectReport={selectReport}
                purposeOfUse={purposeOfUse}
                subjectToken={subjectToken}
                subjectRole={subjectRole}
              />
            </div>

          </div>
        </div> */}
        <div style={{ marginLeft: '9%', marginRight: '9%' }} className="Visualizer_column">
          <p
            className="headerp"
          >
            {`${t('Header-patientSummary-name')}: ${formatName(capitalize_first_letters(patientName))}`}
          </p>
          <p className="headerp">{birthdate && `${t('Header-patientSummary-birthdate')}: ${formatDateDisplay(birthdate)} (${calculateAge(new Date(Number(year), Number(month) - 1, Number(day)))} ${t('Header-patientSummary-birthdate-age')}) `}</p>
        </div>
        <div style={{ marginRight: '9%' }} className="Visualizer_column">
          {patientCNS
            && <p className="headerp">{`${t('Header-patientSummary-healthNumber')}: ${patientCNS}`}</p>}
          {gender === 'Masculino' && <p className="headerp">{`${t('Header-patientSummary-gender')}: ${t('Header-patientSummary-gender-male')}`}</p>}
          {gender === 'Feminino' && <p className="headerp">{`${t('Header-patientSummary-gender')}: ${t('Header-patientSummary-gender-female')}`}</p>}
        </div>
        <div style={{ marginRight: '9%' }} className="Visualizer_column">
          {socialName && socialName !== 'Não Informado' ? <p className="headerp">{`${t('Header-patientSummary-preferredName')}: ${formatName(capitalize_first_letters(socialName))}`}</p> : null}
        </div>
      </div>
    </div>
  );
};

export { Header };
