import React, { Component } from 'react';
import i18n from 'i18n';
import PropTypes from 'prop-types';
import { verifyCPF } from 'utils/AuxiliarFunctions';
import { recover_password } from 'services/signUpRequests';
import './RecoverPass.css';
import { Button } from 'components';

function t(str) {
  return i18n.t(str, { ns: 'Profile' });
}

export class RecoverPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      cpf_valid: false,
      error: false,
      goBackPage: false,
      user_id: '',
    };
  }

  async passRecovery() {
    const { clicked, user_id } = this.state;
    const valid = await this.cpfValid();
    if (valid) {
      await recover_password(user_id);
      this.setState({
        clicked: !clicked,
        cpf_valid: true,
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

    renderRedirect = () => {
      const { goBackPage } = this.state;
      const { callbackFunc } = this.props;
      if (goBackPage) callbackFunc();
    }

    async cpfValid() {
      const { user_id } = this.state;
      const condCPFValid = verifyCPF(user_id);
      if (condCPFValid) {
        this.setState({ cpf_valid: true });
        return true;
      }

      this.setState({ cpf_valid: false });
      return false;
    }

    errorMsg() {
      return (
        <p className="RecoverPass_errorMsg">{t('RecoverPass-invalidCPF')}</p>
      );
    }

    successMsg() {
      return (
        <p className="RecoverPass_successMsg">
          {t('RecoverPass-confirmationMsg1')}
          <br />
          {t('RecoverPass-confirmationMsg2')}
        </p>
      );
    }

    renderButtons(type) {
      if (type === 'pass') {
        return (
          <Button
            color="custom"
            variant="contained"
            // className="RecoverPass_Button"
            onClick={async () => await this.passRecovery()}
          >
            {t('RecoverPass-recoverButton')}
          </Button>
        );
      } if (type === 'login') {
        return (
          <Button
            variant="secondary"
            className="RecoverPass_Button"
            onClick={() => this.setState({ goBackPage: true })}
          >
            {t('RecoverPass-returnButton')}
          </Button>
        );
      }
    }

    render() {
      const { clicked, cpf_valid, error } = this.state;

      return (
        <div
          className="RecoverPass_appContainer"
        >
          {this.renderRedirect()}
          <div className="RecoverPass_dataContainer">
            <h1 className="RecoverPass_title">{t('RecoverPass-title')}</h1>
            <p className="RecoverPass_text">{t('RecoverPass-body')}</p>
            <div className="RecoverPass_col">
              <input
                maxLength="50"
                onChange={(event) => this.setState({ user_id: event.target.value })}
                className="RecoverPass_inputContainer"
              />

              {error && this.errorMsg()}
              {clicked && cpf_valid && this.successMsg()}

              {!cpf_valid && this.renderButtons('pass')}
              { this.renderButtons('login')}
            </div>
          </div>

        </div>
      );
    }
}

RecoverPass.propTypes = {
  callbackFunc: PropTypes.func.isRequired,
};

export default (RecoverPass);
