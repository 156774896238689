import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { citiesBrRequest } from 'utils/externalAPIs';
import { Loading, DropDownFilter } from 'components';

const ChooseCity = ({ onChooseCity, ufID, disabled }) => {
  const [citiesList, setCitiesList] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCitiesList = async () => {
      const cities = await citiesBrRequest(ufID);
      if (!cities) return null;
      setCitiesList(cities);
      setLoading(false);
      return null;
    };
    getCitiesList();
  }, [ufID, disabled]);

  if (loading) return <Loading size="mini" />;

  return (
    <div>
      <DropDownFilter
        items={citiesList}
        handleClick={onChooseCity}
        placeholder="Escolha a Cidade"
        type="cidades"
        disabled={disabled}
      />
    </div>
  );
};
ChooseCity.propTypes = {
  onChooseCity: PropTypes.func.isRequired,
  ufID: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

ChooseCity.defaultProps = {
  disabled: false,
};

export { ChooseCity };
