import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { logDebug } from 'utils/AuxiliarFunctions';
import { useTranslation } from 'react-i18next';

function VaccinesTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const headers = ['Estabelecimento', 'Profissional', 'Data de Retorno para 2ª Dose', 'Efeitos Colaterais', 'Data'];
  const extractInfoFromVaccineDocument = (document) => {
    const info = {
      professionalName: '',
      organizationName: '',
    };
    try {
      document.forEach((item) => {
        if (item.resourceType === 'Organization') info.organizationName = item.name;
        else if (item.resourceType === 'Practitioner') info.professionalName = item.name[0].given;
      });
    } catch (err) {
      logDebug('VaccinecTabContent extractInfoFromVaccineDocument :>>', err);
    }
    return info;
  };

  const rows = documents.map((document) => {
    const info = extractInfoFromVaccineDocument(document.contained);
    return ({
      id: document.id,
      professional: info.professionalName,
      organization: info.organizationName,
      source: document.content[0].attachment.url.split('/')[1],
      reference: document.type.coding[0].code,
      type: document.type.coding[0].display,
      date: formatDateDisplay(document.date),
      returnDate: document.extension,
    });
  });

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((document) => (
            <TableRow
              key={document.id}
              hover
            >
              <Tooltip
                placement="top-start"
                title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
              >
                <TableCell
                  onClick={
                    () => {
                      selectReport(document.source,
                        document.type,
                        document.reference);
                    }
                  }
                >
                  {document.organization}
                </TableCell>

              </Tooltip>
              <Tooltip
                placement="top-start"
                title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
              >
                <TableCell
                  onClick={
                    () => selectReport(document.source,
                      document.type,
                      document.reference)
                  }
                >
                  {document.professional}
                </TableCell>
              </Tooltip>
              <Tooltip
                placement="top-start"
                title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
              >
                <TableCell
                  onClick={
                    () => selectReport(document.source,
                      document.type,
                      document.reference)
                  }
                >
                  {document.returnDate ? formatDateDisplay(document.returnDate[0].valueDateTime) : 'Não informado'}
                </TableCell>
              </Tooltip>
              <TableCell
                padding="checkbox"
              />
              <Tooltip
                placement="top"
                title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
              >
                <TableCell
                  onClick={
                    () => selectReport(document.source,
                      document.type,
                      document.reference)
                  }
                  padding="checkbox"
                >
                  {document.date}
                </TableCell>
              </Tooltip>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}

VaccinesTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { VaccinesTabContent };
