import * as yup from 'yup';
import { verifyCPF } from 'utils/AuxiliarFunctions';

function loginSchema(t) {
  return (yup.object().shape({
    password: yup
      .string()
      .required(t('loginForm-formPassword-requiredPassword')),
    // .min(6, 'Senha muito curta'),
    user: yup
      .string()
      .required(t('loginForm-formUser-requiredUser'))
      // .max(11, () => `${('CPF inválido')}`)
      .test('verifyCpf', 'CPF inválido', verifyCPF),
  })
  );
}

export default loginSchema;
