import { addHours, format } from 'date-fns';

export const setDateUTCMinus3 = (date) => {
  try {
    let newDate = new Date(date);
    newDate = newDate.toISOString();
    if (newDate.includes('Z')) newDate = addHours(new Date(date), -3);
    else newDate = new Date(newDate);
    return newDate;
  } catch (err) {
    return date;
  }
};

export const setISODateToLocale = (date) => {
  try {
    let newDate = new Date(date);
    const timezoneBrazil = 'America/Sao_Paulo';
    newDate = newDate.toLocaleString('pt-BR', { timeZone: timezoneBrazil });
    return newDate;
  } catch (err) {
    return date;
  }
};

export const replaceZToLocalTime = (ISOdate) => {
  try {
    const dateObj = new Date(ISOdate);
    const GMTtime = format(dateObj, 'XXX');
    const newISODate = ISOdate.replace('Z', GMTtime);
    return newISODate;
  } catch (err) {
    return ISOdate;
  }
};

export const getISODateLocalTime = (dateStr) => {
  try {
    const localTime = setDateUTCMinus3(dateStr);
    const ISOLocal = localTime.toISOString();
    const ISOWithCorrectGMT = replaceZToLocalTime(ISOLocal);
    return ISOWithCorrectGMT;
  } catch (err) {
    return dateStr;
  }
};

export const formatDateWhileTyping = function (date) {
  let text = date;
  text = text.replace(/\D/g, '');
  text = text.replace(/^(\d{2})(\d)/g, '$1/$2');
  text = text.replace(/(\d)\/(\d{2})(\d)/g, '$1/$2/$3');
  text = text.replace(/(\d)(\d{4})$/g, '$1/$2');
  return text;
};

export const formatDateDisplay = function (date, bypass = false) {
  if (!date) return null;
  const cond1 = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(date);
  // eslint-disable-next-line max-len
  const cond2 = /(([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))([a-z]|[A-Z])([0-9][0-9]):([0-9][0-9]):([0-9][0-9])([a-z]|[A-Z]))/.test(
    date,
  );
  if (cond1 || cond2 || bypass) {
    let text = date;
    text = text.replace(/\D/g, '');
    text = text.split('');
    text = `${text[6]}${text[7]}/${text[4]}${text[5]}/${text[0]}${text[1]}${text[2]}${text[3]}`;
    return text;
  }
  return date;
};
export const formatDate = function (date) {
  let text = date;
  text = text.replace(/\D/g, '');
  return text;
};

export const formatDateToRequest = function (date) {
  let text = date;
  text = text.replace(/\D/g, '');
  text = `${text[4]}${text[5]}${text[6]}${text[7]}${text[2]}${text[3]}${text[0]}${text[1]}`;
  return text;
};

export const formatDateTimeStr = (str) => {
  if (!str) return str;
  const dateObj = new Date(str);
  let dd = dateObj.getDate();
  if (dd < 10) dd = `0${dd}`;
  let mm = dateObj.getMonth() + 1;
  if (mm < 10) mm = `0${mm}`;
  const yy = dateObj.getFullYear();
  const date = `${dd}/${mm}/${yy}`;
  let hours = dateObj.getHours();
  if (hours < 10) hours = `0${hours}`;
  let minutes = dateObj.getMinutes();
  if (minutes < 10) minutes = `0${minutes}`;
  const time = `${hours}:${minutes}`;
  return { date, time, amPm: '' };
};

// export function addDays(date, days) {
//   const result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result;
// }

export const verifyDate = function (date) {
  if (/([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/.test(date) === false) {
    let formatedDate = date.split('/');
    formatedDate = `${formatedDate[2]}${formatedDate[1]}${formatedDate[0]}`;
    if (
      /([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/.test(formatedDate)
      === false
    ) return false;
  }
  return true;
};

// Transformação de timestamp ISO-8061 para dia/mes/ano
export const timestamp_to_date = function (timestamp) {
  const year = timestamp.substring(0, 4);
  const month = timestamp.substring(4, 6);
  const day = timestamp.substring(6, 8);
  return `${day}/${month}/${year}`;
};

// Transformação de timestamp ISO-8061 para hora:minuto
export const timestamp_to_time = function (timestamp) {
  const hour = timestamp.substring(8, 10);
  const minutes = timestamp.substring(10, 12);
  return `${hour}:${minutes}`;
};

export const date_to_iso = function (date) {
  let isoDate = date.split('/');
  isoDate = `${isoDate[2]}-${isoDate[1]}-${isoDate[0]}T00:00:00.000`;
  return isoDate;
};

// export function removeTimeOffset(dateArg) {
//   let date = dateArg;
//   if (typeof date === 'string') {
//     date = new Date(dateArg);
//     return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
//   }
//   return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
// }
export function removeTimeOffset(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function isFuture(dateChosen) {
  return new Date(dateChosen).getTime() - new Date().getTime() > 0;
}

export function calculateAge(birthday) {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/** function localDateISO
 *
 * @param {string} date - formato 2021-06-27
 * retorno - iso string - 2021-06-27T03:00:00.000Z
 */
export function localDateISO(date) {
  const dateArr = date.split('-');
  return new Date(dateArr[0], Number(dateArr[1] - 1), dateArr[2]).toISOString();
}

export function isToday(date) {
  return new Date(date).getUTCDay() === new Date().getUTCDay();
}

export function addDays({ date, days }) {
  const auxDate = new Date(date);
  auxDate.setDate(auxDate.getDate() + days);
  return auxDate;
}

export function subtractDays({ date, days }) {
  const auxDate = new Date(date);
  auxDate.setDate(auxDate.getDate() - days);
  return auxDate;
}

export function displayDateCalendarFormat(dateISO) {
  let dateOnMonth = dateISO.getDate();
  if (dateOnMonth >= 0 && dateOnMonth <= 9) dateOnMonth = `0${dateOnMonth}`;
  let month = dateISO.getMonth() + 1;
  if (month >= 0 && month <= 9) month = `0${month}`;
  const year = dateISO.getFullYear();
  return `${dateOnMonth}/${month}/${year}`;
}

export function displayTimeFormat(dateISO) {
  const auxDate = new Date(dateISO);
  let hours = auxDate.getHours();
  if (hours >= 0 && hours <= 9) hours = `0${hours}`;
  let minutes = auxDate.getMinutes();
  if (minutes >= 0 && minutes <= 9) minutes = `0${minutes}`;
  return `${hours}:${minutes}`;
}

export function fixTimezoneOffset(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * -60000);
}
