import React, {
  useContext, useState, useCallback, createContext, memo,
} from 'react';
import PropTypes from 'prop-types';

const initialState = {
  name: '',
  username: '',
  gender: '',
  birthday: '',
  cpf: '',
  cns: [],
  phone: '',
  email: '',
  address: {
    number: '',
    address: '',
    complement: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'Brasil',
    cep: '',
    neighborhood: '',
  },
  role: {},
};

export const UserContext = createContext(
  initialState,
);

export const UserContextProvider = memo(
  ({ children }) => {
    const StateGetSet = useState(initialState);
    return (
      <UserContext.Provider value={StateGetSet}>
        {children}
      </UserContext.Provider>
    );
  },
);

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserContext = () => {
  const [state, setState] = useContext(UserContext);
  const setNewState = useCallback(
    (newState) => {
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );
  const resetState = useCallback(
    () => {
      setState(initialState);
    },
    [setState],
  );

  return [state, setNewState, resetState];
};
