import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './CityGroup.css';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import scheduleRequests from 'services/scheduleRequests';
import { logDebug } from 'utils/AuxiliarFunctions';
import fhirRequests from 'services/fhirRequests';
import AdminFhirRequests from 'services/AdminFhirRequests';
import { Button, Loading } from 'components';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function CityGroup({
  cityId, currentGroup, patientId, onFinish, onlyActive, disabled,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [allGroups, setAllGroups] = useState();

  async function handleAddToGroup(groupCode) {
    try {
      let groupId;
      if (typeof allGroups !== 'string') {
        groupId = allGroups.filter((item) => item.resource.code.coding[0].code === groupCode);
        if (groupId.length > 0) groupId = groupId[0].resource.id;
        else {
          toast.error('Houve um problema');
          logDebug('Error in onFinishChoosingGroup: erro pegando id do grupo');
          return;
        }
      }

      // Verifica se já está no grupo
      const groupResponse = await fhirRequests.getGroups({ patientId, cityId, code: groupCode });
      if (typeof groupResponse !== 'string') {
        if (groupResponse.total > 0) {
          if (groupResponse.entry[0].resource.id === groupId) {
            toast.warn('Paciente já está no grupo');
            return;
          }
        }
      }

      const addingMemberResp = await AdminFhirRequests.addPatientToGroup({ patientId, groupId });
      if (typeof addingMemberResp === 'string') {
        toast.error('Houve um problema');
        logDebug('Error in onFinishChoosingGroup: Erro ao adicionar membro ao grupo');
      } else toast.success('Sucesso!');
    } catch (e) {
      logDebug('Error em onFinishChoosingGroup', e);
    }
  }

  const handleSave = async () => {
    if (!allGroups) {
      setEditable(false);
      return;
    }
    try {
      await handleAddToGroup(selectedGroup.resource.code.coding[0].code);
      setEditable(!editable);
      await onFinish(selectedGroup.resource.code.coding[0]);
    } catch (err) {
      logDebug('CityGroup handleSave :>>', err);
      setEditable(!editable);
    }
  };

  const initialize = async () => {
    try {
      if (!allGroups) {
        setLoading(true);
        const id = String(cityId);
        const cityId6Digit = id.length > 6 ? id.slice(0, -1) : id;
        const cityGroups = await scheduleRequests.getCityGroups(cityId6Digit);
        const cityActiveGroups = onlyActive ? cityGroups.entry.filter((item) => item.resource.active === true) : cityGroups.entry;
        if (typeof cityGroups !== 'string') setAllGroups(cityActiveGroups);
      }
    } catch (e) {
      logDebug('Error in initialize in CityGroup', e);
    }
  };

  useEffect(() => {
    if (editable) initialize().then(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  if (loading) return <div className="city-group-row"><Loading size="small" /></div>;

  return (
    <div className="city-group-row">
      {!editable && <p>{currentGroup?.display || 'Escolha um Grupo Atendimento'}</p>}
      {editable && !allGroups && <p>Não há grupos ativos</p>}
      {editable && allGroups && (
        <Autocomplete
          style={{ minWidth: 400 }}
          options={allGroups.sort((a, b) => -b.resource.code.coding[0].display[0].localeCompare(a.resource.code.coding[0].display[0]))}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.resource.code.coding[0].display}
          renderOption={(option) => (
            <>
              {option.resource.code.coding[0].display}
            </>
          )}
          onChange={(_, value) => setSelectedGroup(value)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Escolha um grupo de atendimento"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                // autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
      <div>
        <Button style={{ marginLeft: '10px' }} disabled={disabled} onClick={() => setEditable(!editable)} variant={editable ? 'outlined' : 'contained'} color="primary">
          {editable ? 'Cancelar' : 'Editar'}
        </Button>
        {editable && (
        <Button onClick={handleSave} variant="contained" color="primary">
          Salvar
        </Button>
        )}
      </div>
    </div>
  );
}

CityGroup.propTypes = {
  cityId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  currentGroup: PropTypes.shape({
    system: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired,
  onFinish: PropTypes.func.isRequired,
  onlyActive: PropTypes.bool,
  disabled: PropTypes.bool,
};

CityGroup.defaultProps = {
  onlyActive: false,
  disabled: false,
};

export { CityGroup };
