import { statesBrConst } from './Constants';

export const getUFList = () => {
  const ufs = statesBrConst;
  if (!ufs) return null;
  // if (ufs) ufs = ufs.filter((state) => state.regiao.sigla === 'NE'); // filtra todos menos nordeste
  return (ufs);
};

export function getStateFromSigla(sigla) {
  const uFList = getUFList();
  const state = uFList.filter((item) => item.sigla === sigla);
  return state[0];
}
