import { cpfValidator } from '../AuxiliarFunctions';
import { oids } from '../Constants';

export default class AdverseEvent {
  adverseEvent = '';

  constructor({
    name, code, username, immunobiologicalId, fromDate, id,
  }) {
    const adverseEvent = {
      resourceType: 'AdverseEvent',
      actuality: 'potential',
      event: {
        coding: [
          {
            system: '',
            code,
            display: name,
          },
        ],
        text: '',
      },
      subject: {
        reference: `Patient/${cpfValidator(username) ? oids.cpf : oids.cns}-${username}`,
      },
      date: fromDate,
      recordedDate: new Date().toISOString(),
      recorder: {
        reference: `Patient/${cpfValidator(username) ? oids.cpf : oids.cns}-${username}`,
      },
      suspectEntity: [
        {
          instance: {
            reference: `Immunization/${immunobiologicalId}`,
          },
        },
      ],
    };

    if (id) adverseEvent.id = id;

    this.adverseEvent = adverseEvent;
  }

  getJSON() {
    return this.adverseEvent;
  }

  getStringify() {
    return JSON.stringify(this.adverseEvent);
  }

  getID() {
    return this.adverseEvent.id;
  }
}
