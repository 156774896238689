import {
  logDebug, retrieveData, verifyCPF,
} from 'utils/AuxiliarFunctions';
import { oids } from 'utils/Constants';
import { endpoints } from 'configs';

const { ELIGIBILITY_api } = endpoints;
class eligibilityRequests {
    static catchError = (err, from) => {
      logDebug(`ERROR in eligibilityRequests: ${from}: `, err);
      switch (parseInt(err.message, 10)) {
        case (500):
          return 'internal_error';
        case (504):
          return 'timeout';
        case (400):
          return 'bad_request';
        case (401):
          return 'unauthorized';
        case (403):
          return 'forbidden';
        case (409):
          return 'conflict';
        default:
          return 'network_error';
      }
    }

    static async getEligibility(patientId, cityId) {
      let idAndOid = `${oids.cpf}-${patientId}`;
      if (!verifyCPF(patientId)) idAndOid = `${oids.cns}-${patientId}`;

      let url = `${ELIGIBILITY_api}/${idAndOid}`;

      if (cityId) url = `${url}?city=${cityId}`;

      const token = await retrieveData('token');
      const applicationToken = await retrieveData('application_token');
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${applicationToken}`);
      myHeaders.append('X-User-Token', token);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const responseFetch = await fetch(url, requestOptions)
        .then((response) => {
          if ((response.status !== 200)) throw new Error(response.status);
          return (response.json());
        })
        .catch((err) => eligibilityRequests.catchError(err, 'eligibilityRequests'));

      if (typeof responseFetch === 'string') return responseFetch;
      return responseFetch;
    }
}

export default eligibilityRequests;
