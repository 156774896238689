import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TimelineSeparator } from '@material-ui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading, Button } from 'components';

function LoadMoreScroll({ children, action, hasNext, length }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    action().then(() => setLoading(false));
  };

  return (
    <div
      id="scrollableDiv"
      style={{
        textAlign: 'center',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <InfiniteScroll
        className="infinity-container"
        dataLength={length || 5}
        hasMore={!!hasNext}
        next={handleClick}
        scrollThreshold={0.8}
        endMessage={(<TimelineSeparator />)} 
        scrollableTarget="scrollableDiv"
      >
        {children}
        {!!hasNext
          && (
          <Button
            variant="outlined"
            color="custom"
            disabled={loading}
            onClick={handleClick}
          >
            {loading ? (<Loading size="mini" />) : 'Carregar Mais'}
          </Button>
          )}
      </InfiniteScroll>
    </div>
  );
}

LoadMoreScroll.propTypes = {
  hasNext: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
};

export { LoadMoreScroll };
