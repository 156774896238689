// eslint-disable-next-line import/no-unresolved
import { colors } from 'theme/style';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;
export const appFontFamily = 'Roboto';

export const dimensionsApp = {
  deviceHeight,
  deviceWidth,
  mockupHeight: 813,
  mockupWidth: 375,
  inputHeight: 60,
  inputMarginVertical: 0.02,
  admDrawerWidth: 200,
};

export const font_size = {
  textBig: 40,
  textTotal: 30,
  inputText: 22,
  name: 20,
  btnText: 18,
  textModal: 17,
  header: 16,
  title1: 16,
  title2: 20,
  title3: 26,
  topMenu: 15,
  textMid: 14,
  textSmall: 11,
};

export const disabledTextInput = {
  inputRoot: {
    '&$disabled': {
      color: colors.dark_gray,
      backgroundColor: colors.light_gray,
    },
  },
  disabled: {},
};

export { colors };
