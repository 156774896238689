// Chaves nas key indica se é variável
// Guardar requisições Fhir no formato : {nome_do_recurso}-{oid}-{id}
// Se não tem oid: {nome_do_recurso}-{id}

export default {
  cnes: 'Todos os cnes do profissional logado', // Array
  cnesToUse: 'Cnes que indica a organização escolhida pelo médico para abrir o visualizador', // String
  cnesToUseOrg: 'Nome da organização escolhida pelo médico para abrir o visualizador',
  userLogged: 'Indica se está logado', // Bool
  isProfessional: 'Indica se pode fazer pesquisa', // Bool
  govBR: 'Indica se é para redirecionar para gov.br', // Bool
  username: 'Nome de usuário logado', // string
  password: 'Senha do usuário logado', // string
  Token_exp: 'Tempo exato em que o token será expirado',
  token: 'Token que vai em Authorization na maioria das requisições',
  application_token: 'Tempo exato em que o token será expirado',
  refresh_token: 'Token para obter outro quando o token for expirado',
  profileData: 'Json com dados do perfil do usuário logado', // Objeto
  siteState: 'Guarda estado da página atual para casos de reload na página permanecer no mesmo item do menu', // Objeto
  purposeOfUse: 'Usado nas requisições mhd para indicar se é caso de emergência ou atendimento', // string
  patientId: 'Id do usuário que será aberto no vizualizador', // string
  patient_idd: 'Id do usuário que será aberto no vizualizador do Sisupa', // string
  'patient-{oid}-{id}': 'Request patientjson', // exemplo: patient-2.16.840.1.113883.13.237-66839610187
  'all-appointments-main-reception': 'todos os appointments',
  'welcomed-appointments-main-reception': '',
  'booked-appointments-main-reception': '',
  'late-appointments-main-reception': '',
  'fulfilled-appointments-main-reception': '',
  'notFulfilled-appointments-main-reception': '',
  'cancelled-appointments-main-reception': '',
  'patients-main-reception': '',
  'locations-main-reception': '',
  logAgain: 'Tempo de refresh token acabou',
  sendDataComplete: 'Indica se o cadastro veio do gov.br',
  scim_token: 'Token para cadastro',
  params: 'parametros da url do sisupa',
  sisupa: 'checa se logou pelo sisupa',
  adminRole: 'Salva role de admin no formato 9911936-ROLE_Gestor_Estadual',
  oauth2_token: 'Token relacionado ao fluxo de login do GovBr',
  provider: 'Seleciona o provedor oauth a realizar o login',
};
