/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import {
  Dialog,
  List,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  TextField,
  Grid,
  Container,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete } from '@material-ui/lab';

import i18n from 'i18n';
import { getIbgeCityById, citiesBrRequest, getAddressByCep } from 'utils/externalAPIs';
import userAddressSchema from 'validators/userAddress';
import { statesBrConst } from 'utils/Constants';
import { logDebug, retrieveData } from 'utils/AuxiliarFunctions';
import userRequests from 'services/userRequests';
import { checkResponseFhir } from 'utils/fhirParser';
import { getStateFromSigla } from 'utils/cityStatesFunctions';
import { Loading, Button } from 'components';
import { useUserContext } from 'store/UserContext';
import { StyledAppBar, useStyles } from './DialogUserAddress-styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DialogUserAddress({
  open, setOpen,
}) {
  const classes = useStyles();
  const [userContext] = useUserContext();
  const userData = userContext?.userData;
  const [cityName, setCityName] = useState(null);
  const [userName] = useState(userData?.mhd?.name ? userData?.mhd?.name[0].text : '');
  const [uFList, setUFList] = useState(null);
  const [citiesList, setCitiesList] = useState([{ nome: '' }]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation('Profile', { i18n });

  const {
    register, handleSubmit, errors, control,
    getValues, trigger, reset, setValue, setError,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(userAddressSchema(t)),
  });

  useEffect(() => {
    const getUFList = () => {
      const ufs = statesBrConst;
      if (!ufs) return null;
      // if (ufs) ufs = ufs.filter((state) => state.regiao.sigla === 'NE'); // filtra todos menos nordeste
      setUFList(ufs);
      return null;
    };
    getUFList();
    getPatientAddress().then(() => setLoading(false));
    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPatientAddress() {
    const userID = await retrieveData('username');
    try {
      const responseUser = await userRequests.getPatient(userID);
      checkResponseFhir(responseUser);
      const prevAddress = responseUser?.address[0];
      const cityResponse = await getIbgeCityById({ city_id: prevAddress.city, state_acronym: prevAddress.state });
      const whereIsDistrict = 3;
      let defaultFormValues = {
        postalCode: prevAddress?.postalCode || '',
        country: 'Brasil',
        city: prevAddress?.city || '',
        state: prevAddress?.state || '',
      };
      try {
        if (prevAddress?.line) {
          defaultFormValues = {
            ...defaultFormValues,
            address: prevAddress?.line[0] || '',
            number: prevAddress?.line[1] || '',
            complement: prevAddress?.line[2] || '',
            district: prevAddress?.line[whereIsDistrict] || '',
          };
        }
      } catch (error) {
        logDebug('ERRO:', error, 'getPatientAddress->DialogUserAddress');
      }
      setCityName(cityResponse?.nome);
      reset(defaultFormValues);
      trigger();
    } catch (err) {
      console.warn(err);
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  const getCitiesList = async (stateID) => {
    const ufID = stateID;
    const cities = await citiesBrRequest(ufID);
    if (!cities) return null;
    setCitiesList(cities);
    return null;
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    const {
      state, city, postalCode, number,
      district, address, complement,
    } = formData;
    try {
      const patchResponse = await userRequests.patchPatient({
        uf: state,
        city: city.toString(),
        postalCode: postalCode.toString(),
        number: number.toString(),
        district,
        address,
        complement,
        user: userData?.mhd?.id.split('-')[1],
      });
      checkResponseFhir(patchResponse);
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
    handleClose();
  };

  const checkJSONEmpty = (json) => {
    if (!Object.keys(json).length) return true;
    return false;
  };

  const handleFormClose = async () => {
    const formData = getValues();
    const condTriggerForm = formData?.address?.length !== 0;
    if (condTriggerForm) {
      trigger();
      const condNoErrors = checkJSONEmpty(errors);
      if (condNoErrors) await onSubmit(formData);
    }
    setOpen(false);
  };

  const checkPostalCode = async (value) => {
    if (!value.match(/[0-9]{5}[-]{0,1}[\d]{3}/)) return;

    const validCep = await getAddressByCep(value);

    if (typeof validCep === 'string') {
      setError('postalCode', {
        type: 'manual',
        message: 'CEP inválido',
      });
      return;
    }

    // const cityCode = validCep.codigoIBGE;
    // const formCity = formData?.city.split('-')[0];

    // if (formData && formCity !== cityCode) {
    //   setError('postalCode', {
    //     type: 'manual',
    //     message: `CEP não pertence a ${formData?.uf.split('-')[1]}, ${formData?.city.split('-')[1]}`,
    //   });
    //   return;
    // }

    const updateForm = getValues();
    setLoading(true);

    updateForm.state = validCep?.uf;
    updateForm.city = validCep?.codigoIBGE;
    updateForm.district = validCep?.bairro;
    updateForm.address = validCep?.endereco;
    setCityName(validCep?.cidade);
    reset(updateForm);
    trigger();
    setLoading(false);
  };

  const handleEmptyCityInput = async () => {
    setLoading(true);
    try {
      const stateId = getStateFromSigla(getValues()?.state).id;
      await getCitiesList(stateId);
      setValue('city', null);
      setCityName(null);
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <StyledAppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('DialogUserAddress-modalTitle')}
              {' '}
              {userData?.mhd?.name ? userData?.mhd?.name[0]?.text : ''}
            </Typography>
            <Button variant="contained" color="custom">
              {t('DialogUserAddress-finishButton')}
            </Button>
          </Toolbar>
        </StyledAppBar>

        <List>
          <Container maxWidth="sm">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Loading size="medium" />
            </Grid>
          </Container>
        </List>
      </Dialog>

    );
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <StyledAppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('DialogUserAddress-modalTitle')}
              {' '}
              {userName}
            </Typography>
            <Button variant="contained" color="custom" onClick={handleFormClose}>
              {t('DialogUserAddress-finishButton')}
            </Button>
          </Toolbar>
        </StyledAppBar>
        <List>
          <Container maxWidth="sm">

            <Grid container spacing={3}>

              <Grid item xs={12} />
              <Grid item xs={12}>
                <Controller
                  defaultValue=""
                  name="postalCode"
                  control={control}
                  render={({ onChange }) => (
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      size="medium"
                      fullWidth
                      label={t('DialogUserAddress-postalCode')}
                      placeholder={t('DialogUserAddress-postalCode')}
                      ref={register}
                      value={getValues()?.postalCode || ''}
                      onChange={async (e) => {
                        const newValue = e.target.value;
                        onChange(newValue);
                        await checkPostalCode(newValue);
                      }}
                      error={!!errors?.['postalCode']}
                      helperText={errors?.['postalCode']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="country"
                  control={control}
                  defaultValue="Brasil"
                  render={() => (
                    <TextField
                      autoComplete="off"
                      disabled
                      variant="outlined"
                      size="medium"
                      fullWidth
                      label={t('DialogUserAddress-country')}
                      placeholder={t('DialogUserAddress-country')}
                      value="Brasil"
                      ref={register}
                    />
                  )}
                />

              </Grid>

              <Grid item xs={6}>

                <Controller
                  defaultValue=""
                  name="state"
                  control={control}
                  render={({ onChange }) => (
                    <>
                      {getValues().state
                        ? (
                          <TextField
                            autoComplete="off"
                            disabled
                            variant="outlined"
                            size="medium"
                            fullWidth
                            label={t('DialogUserAddress-state')}
                            placeholder={t('DialogUserAddress-state')}
                            ref={register}
                            value={getStateFromSigla(getValues()?.state)?.nome || ''}
                            onChange={() => {
                              setValue('city', null);
                              setCityName(null);
                              onChange(null);
                            }}
                            error={!!errors?.['state']}
                            helperText={errors?.['state']?.message}
                          />
                        )
                        : (
                          <Autocomplete
                            disabled
                            ref={register}
                            options={uFList}
                            getOptionLabel={(option) => option.nome}
                            getOptionSelected={(option) => option.nome}
                            onChange={async (_, value) => {
                              if (value) {
                                onChange(value.sigla);
                                await getCitiesList(value.id);
                              }
                            }}
                            noOptionsText="Sem opções"
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                {...params}
                                className={classes.muiInputs}
                                label={t('DialogUserAddress-state')}
                                placeholder={t('DialogUserAddress-state')}
                                fullWidth
                                variant="outlined"
                                error={!!errors?.['state']}
                                helperText={errors?.['state']?.message}
                              />
                            )}
                          />
                        )}
                    </>
                  )}
                />

              </Grid>

              <Grid item xs={6}>
                {
                  !citiesList || citiesList.length === 0
                    ? (
                      <Controller
                        defaultValue=""
                        name="city"
                        disabled
                        control={control}
                        render={() => (
                          <TextField
                            autoComplete="off"
                            disabled
                            ref={register}
                            fullWidthonChange={async () => handleEmptyCityInput()}
                            value={cityName || ''}
                            className={classes.muiInputs}
                            label={t('DialogUserAddress-city')}
                            placeholder={t('DialogUserAddress-city')}
                            variant="outlined"
                            error={!!errors?.['city']}
                            helperText={errors?.['city']?.message}
                          />
                        )}
                      />
                    )
                    : (
                      <Controller
                        defaultValue=""
                        name="city"
                        control={control}
                        render={({ onChange }) => (
                          <>
                            {getValues().city && cityName
                              ? (
                                <TextField
                                  autoComplete="off"
                                  disabled
                                  variant="outlined"
                                  size="medium"
                                  fullWidth
                                  label={t('DialogUserAddress-city')}
                                  placeholder={t('DialogUserAddress-city')}
                                  ref={register}
                                  value={cityName || ''}
                                  onChange={async () => handleEmptyCityInput()}
                                  error={!!errors?.['city']}
                                  helperText={errors?.['city']?.message}
                                />
                              )
                              : (
                                <Autocomplete
                                  disabled
                                  loading={!citiesList || citiesList.length === 0}
                                  ref={register}
                                  // disabled={!getValues('state')}
                                  options={citiesList}
                                  getOptionLabel={(option) => option.nome}
                                  getOptionSelected={(option) => option.nome}
                                  onChange={(_, value) => {
                                    if (value) {
                                      setCityName(value.nome);
                                      onChange(value.id);
                                    }
                                  }}
                                  noOptionsText="Sem opções"
                                  renderInput={(params) => (
                                    <TextField
                                      autoComplete="off"
                                      {...params}
                                      className={classes.muiInputs}
                                      label={t('DialogUserAddress-city')}
                                      placeholder={t('DialogUserAddress-city')}
                                      fullWidth
                                      variant="outlined"
                                      error={!!errors?.['city']}
                                      helperText={errors?.['city']?.message}
                                    />
                                  )}
                                />
                              )}

                          </>
                        )}
                      />
                    )
                }

              </Grid>

              <Grid item xs={12}>
                <Controller
                  defaultValue=""
                  name="address"
                  control={control}
                  render={({ onChange }) => (
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      size="medium"
                      fullWidth
                      value={getValues()?.address || ''}
                      label={t('DialogUserAddress-address')}
                      placeholder={t('DialogUserAddress-address')}
                      ref={register}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      error={!!errors?.['address']}
                      helperText={errors?.['address']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  defaultValue=""
                  name="complement"
                  control={control}
                  render={({ onChange }) => (
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      size="medium"
                      fullWidth
                      value={getValues()?.complement || ''}
                      label={t('DialogUserAddress-complement')}
                      placeholder={t('DialogUserAddress-complement')}
                      ref={register}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      error={!!errors?.['complement']}
                      helperText={errors?.['complement']?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  defaultValue=""
                  name="number"
                  control={control}
                  render={({ onChange }) => (
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      size="medium"
                      fullWidth
                      value={getValues()?.number || ''}
                      label={t('DialogUserAddress-number')}
                      placeholder={t('DialogUserAddress-number')}
                      ref={register}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      error={!!errors?.['number']}
                      helperText={errors?.['number']?.message}
                    />
                  )}
                />

              </Grid>

              <Grid item xs={6}>
                <Controller
                  defaultValue=""
                  name="district"
                  control={control}
                  render={({ onChange }) => (
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      size="medium"
                      fullWidth
                      value={getValues()?.district || ''}
                      label={t('DialogUserAddress-district')}
                      placeholder={t('DialogUserAddress-district')}
                      ref={register}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      error={!!errors?.['district']}
                      helperText={errors?.['district']?.message}
                    />
                  )}
                />

              </Grid>

              <Grid
                item xs={12}
                justify="flex-end"
              >
                <Button type="submit" color="custom" variant="contained">
                  {' '}
                  {t('DialogUserAddress-saveAddressButton')}
                  {' '}
                </Button>
              </Grid>

            </Grid>

            {/* <ListItem button>
              <ListItemText primary="Phone ringtone" secondary="Titania" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText primary="Default notification ringtone" secondary="Tethys" />
            </ListItem> */}

          </Container>

        </List>
      </form>
    </Dialog>
  );
}

DialogUserAddress.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export { DialogUserAddress };
