/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

function StandardInput({
  placeholder,
  maxLength,
  className,
  type,
  id,
  onChange,
  error,
}) {
  const handleOnChange = (e) => {
    try {
      onChange(e.target.value);
    } catch (err) {
      return null;
    }
    return null;
    // return e.target.value;
  };

  return (
    <Input
      error={error || false}
      maxLength={maxLength}
      className={className}
      type={type}
      id={id}
      onChange={(e) => handleOnChange(e)}
      placeholder={placeholder}
    />
  );
}

StandardInput.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.string,
};

export { StandardInput };
