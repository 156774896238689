
export const loadOrgs = (orgsProfessional) => (
    {
        type: "LOAD",
        selectedId: 0,
        orgs: orgsProfessional
    }
)

export const changeOrg = (newIdOrg) => (
    {
        type: "CHANGE",
        selectedId: newIdOrg
    }
)