/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as firebase from 'firebase/app';
import { Redirect } from 'react-router-dom';
import logo from 'theme/logo+name.png';
import signUpRequests from 'services/signUpRequests';
import './SignUp.css';
import { Button } from 'components';
import {
  verifyCPF,
  verifyPhone,
  verifyEmail,
  retrieveData,
  storeData,
  logDebug,
} from 'utils/AuxiliarFunctions';
import { verifyDate } from 'utils/dateFunctions';
import FirstForm from './FirstForm';
import SecondForm from './SecondForm';

import firebaseApp from './firebase';
import 'firebase/auth';

const DEBUGMODE = false;
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: '',
      phone: '',
      email: '',
      goToLogin: false,

      partOfSignUp: 1,
      dataAlert: [],
      showAlert: false,
      showIncorrectCodeModal: false,
      showPhoneVerication: false,
      govBr_logged: false,
    };
    this.windowRef = React.createRef();
    this.updateDataFirst = this.updateDataFirst.bind(this);
    this.updateDataSecond = this.updateDataSecond.bind(this);
    this.login = this.login.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  async componentDidMount() {
    let dataComplete = await retrieveData('sendDataComplete');
    await storeData('sendDataComplete', null);
    const completeFromGovBR = dataComplete !== undefined
      && dataComplete !== null
      && dataComplete !== 'null';

    if (completeFromGovBR) {
      // eslint-disable-next-line no-unused-vars
      let gov = await storeData('govBR', true);
      gov = gov === 'true';
      dataComplete = dataComplete.split(',');
      const redirectToCompleteInfo = dataComplete[0];
      const govBr_logged = dataComplete[1];
      const username = dataComplete[3];
      const phone = dataComplete[4];
      const email = dataComplete[5];

      if (DEBUGMODE) logDebug('redirectToCompleteInfo :>> ', redirectToCompleteInfo);
      if (DEBUGMODE) logDebug('govBr_logged :>> ', govBr_logged);
      if (DEBUGMODE) logDebug('dataComplete :>> ', dataComplete);

      const condComplete = redirectToCompleteInfo !== undefined && redirectToCompleteInfo;
      if (condComplete) {
        this.setState({
          partOfSignUp: 2,
          cpf: username,
          email,
          phone,
        });
      }
    }
  }

  setAlert(dataAlert) {
    this.setState({ dataAlert, showAlert: true });
  }

  async verifyDataFirst(name, birthday, cpf, phone, email) {
    logDebug('birthday', birthday);
    if (name.split(' ').length < 2) {
      this.setState({
        dataAlert: [
          'Nome incompleto',
          'É necessário digitar o nome completo para continuar!',
        ],
        showAlert: true,
      });
      return false;
    }
    if (verifyDate(birthday) === false) {
      this.setAlert([
        'Data de nascimento não informada',
        'É necessário informar a data de nascimento para continuar o cadastro!',
      ]);
      return false;
    }
    if (verifyCPF(cpf) === false) {
      this.setState({
        dataAlert: [
          'CPF não válido',
          'É necessário digitar o cpf correto para continuar!',
        ],
        showAlert: true,
      });
      return false;
    }
    if (verifyPhone(phone) === false) {
      this.setState({
        dataAlert: [
          'Celular não válido',
          'Verifique se digitou o número de celular com DDD, pois este é um dado necessário para continuar!',
        ],
        showAlert: true,
      });
      return false;
    }
    if (verifyEmail(email) === false) {
      this.setState({
        dataAlert: [
          'Email não válido',
          'Verifique se digitou seu email corretamente, pois este é um dado necessário para prosseguir com o cadastro!',
        ],
        showAlert: true,
      });
      return false;
    }
    return true;
  }

  sendSms(phoneState, captcha) {
    const phone = `+55${phoneState}`;
    firebaseApp.firebaseSendSMS(phone, captcha).catch((errArg) => {
      const err = errArg.toString();
      let errorMsg = '';
      switch (err) {
        case 'Error: We have blocked all requests from this device due to unusual activity. Try again later.':
          errorMsg = 'Múltiplas Tentativas';
          break;
        default:
          errorMsg = 'Erro Interno';
          break;
      }
      this.setState({
        dataAlert: [errorMsg, 'Tente novamente mais tarde'],
        showAlert: true,
      });
    });
  }

  async testCode() {
    // this.setState({ loading: true });
    const { token } = this.state;
    const userInput = token.toString().replace(/,/g, '');
    await firebaseApp
      .signInWithPhone(userInput)
      .then(() => this.setState({
        partOfSignUp: 2,
        dataAlert: ['Sucesso', 'Seu token foi verificado com sucesso!'],
        showAlert: true,
        showPhoneVerication: false,
      }))
      .catch((error) => {
        if (DEBUGMODE) logDebug(`Error ---  ${error}`);
        this.setState({
          dataAlert: ['Código incorreto!', 'Deseja reenviar o SMS?'],
          showIncorrectCodeModal: true,
        });
      });
  }

  // eslint-disable-next-line class-methods-use-this
  async createFirebase() {
    await firebaseApp.create();
  }

  verifyDataSecond(password, confirmPassword) {
    if (password === '') {
      this.setAlert([
        'Senha não informada',
        'É necessário informar uma senha para continuar o cadastro!',
      ]);
      return false;
    }
    if (password.length < 6) {
      this.setAlert([
        'Senha curta',
        'É necessário informar uma senha de no mínimo 6 dígitos para continuar o cadastro!',
      ]);
      return false;
    }
    if (confirmPassword === '') {
      this.setAlert([
        'Confirme a senha',
        'É necessário confirmar a senha para continuar o cadastro!',
      ]);
      return false;
    }
    if (confirmPassword !== password) {
      this.setAlert([
        'Senhas não são iguais',
        'É necessário confirmar a senha para continuar o cadastro!',
      ]);
      return false;
    }
    return true;
  }

  async updateDataFirst(name, birthday, cpf, phone, email) {
    if (
      (await this.verifyDataFirst(name, birthday, cpf, phone, email)) === true
    ) {
      this.setState({ cpf });
      this.setState({ phone });
      this.setState({ email });
      // this.setState({ showPhoneVerication: true });
      this.setState({ partOfSignUp: 2 });
    }
    // await this.createFirebase();
    // eslint-disable-next-line new-cap
    // const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   'continueSignUp',
    //   {
    //     size: 'invisible',
    //     callback() {
    //       // logDebug('callback: ', response);
    //     },
    //     'expired-callback': function () {
    //       // logDebug('expired callback: ');
    //     },
    //   },
    // );

    // const verifier = recaptchaVerifier;
    // this.sendSms(phone, verifier);
  }

  updateDataSecond(password, confirmPassword) {
    if (this.verifyDataSecond(password, confirmPassword) === true) {
      this.setState({ password });
      this.forceUpdate();

      if (DEBUGMODE) {
        // logDebug('UPDATE SECOND:\npassword, confirmPassword');
        // logDebug(password, confirmPassword);
      }
      this.signUp(password);
    }
  }

  async login() {
    const { cpf, password } = this.state;
    const cpfArg = cpf;
    const cpfUser = cpfArg.replace(/\D/g, '');
    await storeData('username', cpfUser);
    await storeData('password', password);
    this.setState({ goToLogin: true });
  }

  async signUp(password) {
    // this.setState({ loading: true });

    const { cpf, email, phone } = this.state;

    if (DEBUGMODE) logDebug('password :>> ', password);
    const ret = await signUpRequests(cpf, email, phone, password, '')
      .then((response) => response)
      .catch((response) => response);

    if (DEBUGMODE) logDebug(`This is signup return: ${ret}`);
    if (ret.status === 201) {
      this.setState({
        dataAlert: [
          'Cadastrado com sucesso!',
          'O seu cadastro foi efetuado com sucesso, agora você pode fazer login.',
        ],
        showAlert: true,
        login: true,
      });
    } else if (ret.status === 409) {
      this.setState({
        dataAlert: [
          'Este usuário já está cadastrado no sistema!',
          'Tente fazer login',
        ],
        showAlert: true,
      });
    } else {
      if (DEBUGMODE) logDebug('Essssse retorno:', ret);
      this.setState({
        dataAlert: [
          'Houve um problema ao tentar cadastrá-lo no sistema',
          'Tente novamente mais tarde.',
        ],
        showAlert: true,
      });
    }
  }

  renderForms() {
    const { partOfSignUp, govBr_logged } = this.state;
    if (partOfSignUp === 1) {
      return (
        <FirstForm
          setAlert={this.setAlert}
          triggerUpdateParent={this.updateDataFirst}
        />
      );
    }
    if (partOfSignUp === 2 && govBr_logged) {
      return (
        <SecondForm triggerUpdateParent={this.updateDataSecond} govBr_logged />
      );
    }

    if (partOfSignUp === 2) return <SecondForm triggerUpdateParent={this.updateDataSecond} />;

    return null;
  }

  render() {
    const {
      dataAlert,
      showAlert,
      showPhoneVerication,
      showIncorrectCodeModal,
      goToLogin,
      login,
      partOfSignUp,
    } = this.state;

    return (
      <div className="SignUpAppContainer">
        <div className="SignUpRow SignUpDataContainer">
          <div className="SignUpColumn center">
            <div>
              <img src={logo} alt="Logo" className="SignUpLogo" />
            </div>
            {this.renderForms()}
            <button
              type="button"
              className="SignUpLink"
              style={partOfSignUp === 1 ? { marginLeft: '6%' } : null}
              onClick={() => this.setState({ goToLogin: true })}
            >
              Login
            </button>
            {goToLogin ? <Redirect to="/" push /> : null}
            <Modal show={showAlert}>
              <Modal.Header>
                <Modal.Title>{dataAlert[0]}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{dataAlert[1]}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  color="deafault"
                  variant="contained"
                  onClick={() => this.setState({ showAlert: false })}
                >
                  Fechar
                </Button>
                {login && (
                  <Button
                    color="custom"
                    variant="contained"
                    onClick={() => this.login()}
                  >
                    Logar
                  </Button>
                )}
              </Modal.Footer>
            </Modal>

            <Modal show={showPhoneVerication}>
              <Modal.Header>
                <Modal.Title>Verificação de celular</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Um token foi enviado para o seu celular. Para continuar o
                  cadastro digite abaixo:
                </p>
                <input
                  maxLength="50"
                  type="number"
                  placeholder="Token"
                  id="tokenFirebase"
                  onChange={(event) => this.setState({ token: event.target.value })}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  color="custom"
                  variant="contained"
                  onClick={() => this.testCode()}
                >
                  Enviar
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => this.setState({ showPhoneVerication: false })}
                >
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showIncorrectCodeModal}>
              <Modal.Header>
                <Modal.Title>{dataAlert[0]}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{dataAlert[1]}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.sendSms()}
                >
                  Enviar
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => this.setState({ showIncorrectCodeModal: false })}
                >
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export { SignUp };
