// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/auth';
import { logDebug } from 'utils/AuxiliarFunctions';

const Config = {

};

// let message = '';
let confirmation = {};

export default {
  async create() {
    logDebug('Initializing apps...');
    logDebug('Initializing apps...', firebase.apps);
    // eslint-disable-next-line no-underscore-dangle
    if (!firebase.apps.find((o) => o.name_ === '[DEFAULT]')) {
      logDebug('Inicializando Firebase');
      firebase.initializeApp(Config);
    }

    try {
      await firebase.auth().signOut();
    } catch (err) {
      logDebug(`SignOut Error: ${err}`);
    }
    firebase.auth().languageCode = 'pt-BR';
  },

  logout() {
    firebase.app('ehrrunner').auth().signOut();
  },

  async firebaseSendSMS(phone, captcha) {
    confirmation = await firebase.auth().signInWithPhoneNumber(phone, captcha)
      .catch((err) => {
        throw err;
      });
  },
  async signInWithPhone(code) {
    const result = await confirmation.confirm(code)
      .catch((error) => {
        throw error;
      });
    return result;
  },
};