// eslint-disable-next-line import/no-unresolved
import { settings } from 'theme/settings';

const prodMode = process.env.REACT_APP_ENV === 'production';

// Bypass de configurações de projeto e modo
// (utilizando src/projects/.env.development)
const projects = ['fluxmed'];
let proj;
if (typeof process.env.REACT_APP_PROJ === 'undefined') proj = projects[0];
else proj = process.env.REACT_APP_PROJ;
const currentProject = proj;

const modes = ['standard', 'vacinacao', 'portal'];
let projMode;
if (typeof process.env.REACT_APP_PROJ_MODE === 'undefined') projMode = modes[0];
else projMode = process.env.REACT_APP_PROJ_MODE;
const currentMode = projMode;

function generateTestCredentials() {
  return { username: '6067866172', password: '' };
}

const currentConfigs = {
  generateEndpoints: () => settings.endpoints(currentMode),
  generateFeatures: () => settings.features(currentMode),
  generateRoles: () => settings.roles(),
  generateDocTypes: () => settings.docTypes(),
  generateOIDS: () => settings.oids(),
};

export const appDebug = process.env.REACT_APP_DEBUG;
export const endpoints = currentConfigs.generateEndpoints();
export const features = currentConfigs.generateFeatures();
export const roles = currentConfigs.generateRoles();
export const docTypes = currentConfigs.generateDocTypes();
export const testCredentials = generateTestCredentials();
export const oids = currentConfigs.generateOIDS();
export const { initialLanguage } = settings;
export { prodMode, currentProject };
