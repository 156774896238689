import * as yup from 'yup';
import { validatePostalCode } from 'utils/AuxiliarFunctions';

function userAddressSchema(t) {
  return (yup.object().shape({
    district: yup
      .string()
      .required(t('userAddress-yupValidator-necessaryField'))
      .max(60, () => `${t('userAddress-yupValidator-max60Characters')}`),
    number: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, t('userAddress-yupValidator-onlyLettersAndNumbers'))
      .max(10, () => `${t('userAddress-yupValidator-max10Characters')}`),
    address: yup
      .string()
      .required(t('userAddress-yupValidator-necessaryField'))
      .max(60, () => `${t('userAddress-yupValidator-max60Characters')}`),
    complement: yup
      .string()
      .max(60, () => `${t('userAddress-yupValidator-max60Characters')}`),
    city: yup
      .string().typeError(t('userAddress-yupValidator-validCity'))
      .required(t('userAddress-yupValidator-necessaryField'))
      .max(60, () => `${t('userAddress-yupValidator-max60Characters')}`),
    state: yup
      .string().typeError(t('userAddress-yupValidator-validState'))
      .required(t('userAddress-yupValidator-necessaryField'))
      .max(30, () => `${t('userAddress-yupValidator-max30Characters')}`),
    postalCode: yup
      .string()
      .test('len', t('userAddress-yupValidator-postalCodeMinLen'), (val) => val.length >= 8)
      .test('lenMax', t('userAddress-yupValidator-postalCodeMaxLen'), (val) => val.length <= 10)
      .required(t('userAddress-yupValidator-necessaryField'))
      .test('validatePostalCode', t('userAddress-yupValidator-invalidPostalCode'), validatePostalCode),
    country: yup
      .string() // .number()
      .required(t('userAddress-yupValidator-necessaryField')),
    // .max(999, () => `${('Máximo de 3 caracteres')}`),

  }));
}

export default userAddressSchema;
