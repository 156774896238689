import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@material-ui/lab';
import './PaginationFhir.css';

// numberOfObjects = total dentro de uma requisição fhir
function PaginationFhir({
  numberOfObjects, onPressPage, numberOfObjectsPerPage,
}) {
  const [links, setLinks] = useState([]);
  const [active, setActive] = useState(1);
  const numberOfLinks = Math.ceil(numberOfObjects / numberOfObjectsPerPage);

  useEffect(() => {
    const getLinkArr = async () => {
      let counter = 0;
      let linkArr = [];
      while (counter < numberOfLinks) {
        linkArr = [...linkArr, `_getpagesoffset=${counter * numberOfObjectsPerPage}&_bundletype=searchset`];
        counter += 1;
      }

      setLinks(linkArr);

      return linkArr;
    };
    getLinkArr();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfObjects, active]);

  return (
    <div>
      {/* <br />
      <Row className="pagination-centralize">
        {items.map((item) => <Col fluid><Pagination>{item}</Pagination></Col>)}
      </Row>
      <br /> */}
      <Pagination
        count={numberOfLinks} page={active}
        onChange={(e, page) => {
          onPressPage(links[parseInt(page, 10) - 1]);
          setActive(parseInt(page, 10));
        }}
      />
    </div>
  );
}

PaginationFhir.propTypes = {
  numberOfObjects: PropTypes.number.isRequired,
  onPressPage: PropTypes.func.isRequired,
  numberOfObjectsPerPage: PropTypes.number,
  paginationBreak: PropTypes.number,
};

PaginationFhir.defaultProps = {
  numberOfObjectsPerPage: 100,
  paginationBreak: 10,
};

export { PaginationFhir };
