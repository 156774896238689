import React from 'react';
import './ArchetypeMenu.css';

const ArchetypeButton = (selectArchetypee, item, column) => {
  return(
  <div
    // className="Index_Button"
    onClick={() => selectArchetypee(column)}
    style={{
      // backgroundColor: item === column && 'rgba(0, 0, 0, 0.8)',
      color:  item === column ? 'white' : 'gray',
      cursor: 'pointer',
      outline: 'none',
      height: '100%',
      borderRadius: '30px',
    }}
    role="button"
    tabIndex={-1}
  >
    <p>{column}</p>
  </div>
)};

export default ArchetypeButton;
