/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

import './FormInput.css';

const FormInput = ({
  errors, name, placeholder, type, ...rest
}, ref) => (
  <div className="form-input-container">
    <input
      className={errors?.[name] && 'input-error'}
      type={type} name={name}
      placeholder={placeholder} ref={ref}
      {...rest}
    />
    {errors?.[name]
    && <p className="error-input-message">{errors?.[name].message}</p>}
  </div>

);

const FormInputFR = forwardRef(FormInput);

export { FormInputFR as FormInput };
