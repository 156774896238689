/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const options2 = [
  { key: 'a', value: 'a', text: '' },
];

function formatToKeyValText(list, type) {
  if (!list) return null;
  let listFinal;
  switch (type) {
    case 'estados':
      listFinal = list.map((item) => {
        const state = {
          key: `${item.id}`, value: item.nome, text: item.nome,
        };
        return state;
      });
      break;
    case 'cidades':
      listFinal = list.map((item) => {
        const city = {
          key: `${item.id}-${item.id}`, value: item.nome, text: item.nome,
        };
        return city;
      });
      break;
    case 'organization':
      listFinal = list.map((item) => {
        const org = {
          key: `${item.id}`,
          value: item.name,
          text: item.name,
          status: item.status,
        };
        return org;
      });
      break;
    case 'especialidades':
      listFinal = list.map((item) => {
        const esp = {
          key: `${item.codigo}`,
          value: item.nome,
          item,
          text: item.nome,
        };
        return esp;
      });
      break;
    default:
      listFinal = list.map((item) => {
        const generic = {
          key: `${item.code}`,
          value: item.name,
          item,
          text: item.name,
        };
        return generic;
      });
      break;
  }
  return listFinal;
}

const DropdownLoading = () => (
  <Dropdown text="Carregando" loading />
);

const renderLabel = (label) => ({
  color: 'green',
  content: `${label.text}`,
  icon: 'check',
});

const DropdownSearchSelection = ({
  options, placeholder, parentTrig, type, disabled, preSelectedId,
}) => {
  const [val, setVal] = useState('');
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (preSelectedId) getValFromId(preSelectedId);
    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedId]);

  function getIDfromName(nome) {
    if (!nome || !options) return null;

    let option = options.filter((opt) => {
      if (nome === opt.text) return opt;
      return null;
    });
    option = option.filter((opt) => !!opt);
    try {
      return option[0].key.split('-')[0];
    } catch (err) {
      return null;
    }
  }

  function getItem(nome) {
    if (!nome || !options) return null;

    let option = options.filter((opt) => {
      if (nome === opt.text) return opt;

      return null;
    });
    option = option.filter((opt) => !!opt);
    option = option[0].item;
    try {
      return option;
    } catch (err) {
      return null;
    }
  }

  function getValFromId(id) {
    setVal(options.filter((v) => v.key === `${id}`)[0].value);
    setSearch(options.filter((v) => v.key === `${id}`)[0].value);
  }

  const handleChange = (e, { value }) => {
    // logDebug('show valueeee', value);
    setVal(value);
    setSearch(value);
    let returnval;
    if (type === 'especialidades') returnval = getItem(value);
    else if (type) returnval = getIDfromName(value);
    else returnval = getItem(value);

    parentTrig(returnval);
  };

  const handleSearchChange = (e, { searchQuery }) => {
    setSearch(searchQuery);
  };

  return (
    <Dropdown
      autoComplete="off"
      size="mini"
      type={type}
      fluid
      compact
      // multiple={type === 'organization'}
      renderLabel={renderLabel}
      clearable
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      options={options || options2}
      placeholder={placeholder}
      search
      selection
      searchQuery={search}
      value={val}
      noResultsMessage="Tente com outra busca."
      disabled={disabled}
    />
  );
};

function DropDownFilter({
  items, handleClick, placeholder, type, loading, disabled, preSelectedId,
}) {
  if (loading) return (<DropdownLoading />);

  return (
    <DropdownSearchSelection
      type={type}
      placeholder={placeholder}
      parentTrig={handleClick}
      options={formatToKeyValText(items, type)}
      disabled={disabled}
      preSelectedId={preSelectedId}
    />
  );
}

DropdownSearchSelection.propTypes = {
  options: PropTypes.any.isRequired,
  parentTrig: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  preSelectedId: PropTypes.string,
};

DropdownSearchSelection.defaultProps = {
  disabled: false,
  preSelectedId: '',
};

DropDownFilter.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  preSelectedId: PropTypes.bool,
};

DropDownFilter.defaultProps = {
  disabled: false,
  loading: false,
  preSelectedId: '',
};

export { DropDownFilter };
