/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Route, Switch, HashRouter, Redirect,
} from 'react-router-dom';
import { VisualizerContextProvider } from 'contexts/VisualizerContext';
import Visualizer from 'pages/Visualizer/Visualizer';
import { features } from 'configs';

const VisualizerBypassRoutes = () => (
  <HashRouter>
    <Switch>
      <Route path="/registros">
        <VisualizerContextProvider urlInput={features.allowVisualizerFromURL}>
          <Visualizer />
        </VisualizerContextProvider>
      </Route>
      <Route exact path="/">
        <Redirect to="/registros" />
      </Route>
    </Switch>
  </HashRouter>
);

export default VisualizerBypassRoutes;
