/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, MenuItem, TextField,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import { statesBrRequest } from 'utils/externalAPIs';
// import OrganizationSchema from 'validators/organization';
// import { colors } from 'configs';
import { Loading } from 'components';

const useStyles = makeStyles({
  muiInputs: {
    width: '100%',
    flexGrow: 1,
  },
});

const UFPicker = ({
  onChooseUF, disabled, errors, control, register, value,
}) => {
  const [uf, setUF] = useState(value);
  const [ufList, setUFList] = useState({});
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const getUFList = async () => {
      let ufs = await statesBrRequest();
      if (!ufs) return null;
      if (ufs) ufs = ufs.filter((state) => state.regiao.sigla === 'NE'); // filtra todos menos nordeste
      setUFList(ufs);
      setLoading(false);
      return null;
    };
    getUFList();
  }, [disabled]);

  if (loading) return <Loading size="mini" />;

  return (
    <div>
      <Controller
        name="uf"
        control={control}
        defaultValue=""
        render={({ onChange }) => (
          <div>
            <TextField
              autoComplete="off"
              className={classes.muiInputs}
              label="Estado"
              placeholder="Estado"
              value={uf && uf.split('-')[1]}
              ref={register}
              select={!value}
              onChange={(e) => {
                onChange(e.target.value);
                onChooseUF(e.target.value);
                setUF(e.target.value);
              }}
              disabled={disabled}
              variant="outlined"
              error={!!errors?.['uf']}
              helperText={errors?.['uf']?.message}
            >
              {!value && ufList.map((item) => <MenuItem key={item.id} value={`${item.id}-${item.nome}`}>{item.nome}</MenuItem>)}
            </TextField>
          </div>
        )}
      />
    </div>
  );
};
UFPicker.propTypes = {
  onChooseUF: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({
    uf: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  control: PropTypes.any.isRequired,
  register: PropTypes.any.isRequired,
  value: PropTypes.string,
};

UFPicker.defaultProps = {
  disabled: false,
  value: '',
};

export { UFPicker };
