import React from 'react';
import { Button } from 'react-bootstrap';
import { refreshScreen } from 'utils/AuxiliarFunctions';

import './PageError.css';

const PageError = (message, icon, tryAgain = null, goBackPage) => (
  <div>
    <div id="error-page">
      {icon}
      <p id="error-page-message">
        {message}
      </p>
      {tryAgain ? (
        <Button variant="outline-success" onClick={() => refreshScreen()}>
          Tentar Novamente
        </Button>
      )
        : (
          <Button variant="outline-success" onClick={() => goBackPage('Compartilhamentos')}>
            Voltar para Página anterior
          </Button>
        )}

    </div>
  </div>
);

export { PageError };
