/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import { retrieveData } from 'utils/AuxiliarFunctions';
import { formatDateDisplay } from 'utils/dateFunctions';
import './SearchConsents.css';

const initialState = { isLoading: false, results: [], value: '' };

const flatList = (list) => {
  const flatlist = [];
  const indexReceived = 1;
  list.map((result, index) => {
    result.map((inResult) => {
      let retur;
      if (index === indexReceived) {
        retur = {
          ...inResult, consented: true,
        };
      } else {
        retur = {
          ...inResult, consented: false,
        };
      }
      flatlist.push(retur);
      return null;
    });
    return null;
  });
  return flatlist;
};

const formatToSearch = async (list, stayInPage) => {
  if (!list) return {};
  const returnList = list.map((li) => {
    // logDebug('li :>> ', li);
    let newList = {};
    const entries = Object.entries(li);
    const idInfo = li.institution || li.pat_id;

    const displayInfo = stayInPage ? `Filtrar por ${idInfo}` : `${formatDateDisplay(li.date)} - ${idInfo}`;
    newList = { ...newList, description: displayInfo };
    entries.forEach(([key, val]) => {
      if (key === 'pat_name') newList = { ...newList, title: val === 'not_found' ? '' : val };
      else if (key === 'date') newList = { ...newList, date: formatDateDisplay(val) };
      else if (key === 'pat_id') newList = { ...newList, source: val };
      else if (key === 'institution') newList = { ...newList, idInfo: val };
      else if (key === 'consented') {
        newList = { ...newList, consented: val };
        if (val && !stayInPage) newList = { ...newList, description: `${displayInfo} | Ver Registros` };
        else if (!stayInPage) newList = { ...newList, description: `${displayInfo} | Remover Consentimento` };
      }
    });
    return newList;
  });

  if (stayInPage) {
    returnList.push({
      consented: false,
      date: '00-00-0000',
      description: 'Mostrar todos os acessos',
      source: null,
      title: 'TODOS',
    });

    const cpf = await retrieveData('username');

    returnList.push({
      consented: false,
      date: '00-00-0000',
      description: 'Mostrar todos os meus acessos',
      source: cpf,
      title: 'Meus Acessos',
    });
  }

  // logDebug('returnList :>> ', returnList);
  return returnList;
};

class SearchConsents extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleResultSelect = async (e, { result }) => {
    const { handleSearch, stayInPage } = this.props;
    if (result.consented) {
      this.setState({ value: result.title });
      await handleSearch[0](result.source);
    } else if (stayInPage) {
      this.setState({ value: result.title });
      await handleSearch[0](result.source);
    } else await handleSearch[1](result.source, null, result.idInfo);
  }

  handleSearchChange = (e, { value }) => {
    const { listToRender, stayInPage } = this.props;
    this.setState({ isLoading: true, value });
    setTimeout(async () => {
      if (this.state.value.length < 1) return this.setState(initialState);
      const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
      // logDebug('listToRender :>> ', listToRender);
      let listFlat = listToRender;
      if (!stayInPage) listFlat = flatList(listToRender);

      else listFlat = flatList([listToRender]);

      // logDebug('listFlat :>> ', listFlat);
      const filteredResults = listFlat.filter((result) => {
        const values = Object.values(result);
        // logDebug('values :>> ', values);
        return re.test(values.map((rootEntry) => rootEntry));
      });

      const formatedResults = await formatToSearch(filteredResults, stayInPage);

      this.setState({
        results: [],
      });
      this.setState({
        results: formatedResults,
        isLoading: false,
      });
      return null;
    }, 300);
  }

  render() {
    const { isLoading, value, results } = this.state;
    const {
      size, placeholder,
    } = this.props;
    return (
      <div className="searchConsents-container">
        <Search
          id="searchBox"
          // size?: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive'
          // size={'tiny'}
          size={size || 'large'}
          // category
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={_.debounce(this.handleSearchChange, 500, {
            leading: true,
          })}
          placeholder={placeholder}
          results={results}
          value={value}
        />

      </div>
    );
  }
}

SearchConsents.propTypes = {
  listToRender: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleSearch: PropTypes.any.isRequired,
  size: PropTypes.string,
  stayInPage: PropTypes.bool,
};

export { SearchConsents };
