import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { Tooltip, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.gray,
    color: 'rgb(255, 254, 254)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    margin: '-7px',
  },
}))(Tooltip);

const MenuItem = ({
  showName, activeItem, name, iconName, handleItemClick, tooltip,
}) => (
  <LightTooltip open={showName} title={tooltip} placement="right">
    <Menu.Item
      name={name}
      active={activeItem}
      onClick={handleItemClick}
    >
      <Icon
        name={iconName}
        size="big"
      />
    </Menu.Item>
  </LightTooltip>
);

MenuItem.propTypes = {
  showName: PropTypes.bool,
  activeItem: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  handleItemClick: PropTypes.func.isRequired,
};

MenuItem.defaultProps = {
  showName: false,
};

export { MenuItem };
