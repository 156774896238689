import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DropdownSearchOrganization } from 'components/DropdownSearchOrganization';
import {
  ChooseUF, ChooseCity,
} from 'components';

const OrganizationByName = ({ onChooseOrg, preSelectedUf }) => {
  const [uf, setUF] = useState(null);
  const [city, setCity] = useState(null);
  return (
    <div className="obc-container">
      <br />
      <Row>
        <Col>
          <ChooseUF preSelectedUf={preSelectedUf} onChooseUF={(ufId) => setUF(ufId)} />
        </Col>
        <Col>
          <ChooseCity
            ufID={uf} onChooseCity={(cityId) => {
              setCity(cityId);
            }}
            disabled={!uf}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <DropdownSearchOrganization
            onChoose={(org) => {
              const [id, name] = org[0].split('-');
              onChooseOrg(id, name);
            }} cityId={city?.slice(0, -1)}
            disabled={!city || !uf}
            placeholder="Digite o nome ou CNES da organização"
          />
        </Col>
      </Row>
    </div>
  );
};

OrganizationByName.propTypes = {
  onChooseOrg: PropTypes.func.isRequired,
  preSelectedUf: PropTypes.string,
};

OrganizationByName.defaultProps = {
  preSelectedUf: '',
};

export { OrganizationByName };
