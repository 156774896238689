import { StyleSheet } from 'aphrodite';

export const colors = {
  archetypeMenuContainer: 'white',
  level1Title: '#08824A',
  level2Title: 'white',
  line: '#08824A',
  level3Title: '#08824A',
  navbarContainer: 'rgba(255,255,255,0.6)',
  dark_green: 'rgb(4, 63, 35)',
  patientName: 'black',
  yearContainer: 'rgb(180, 180, 180)',
  opaque_white: 'rgba(255,255,255,0.6)',
  timeline: 'rgb(4, 63, 35)',
  reportsTitle: 'white',
  yearTitle: 'rgb(45, 62, 80)',
  dark_green_haoc: 'rgb(0, 60, 60)',
  medium_green_haoc: 'rgb(0, 116, 129)',
  light_green: '#08824A',
  bg_gray: 'rgb(245, 245, 245)',
  navbar_blue: 'rgb(45, 62, 80)',
  navbar_btn: 'rgb(54, 72, 94)',
  light_gray: 'rgb(180, 180, 180)',
  index_container: 'rgb(4, 63, 35)',
  index_btn_container: 'rgba(255, 255, 255, 0.2)',
  index_btn_selected: 'rgba(255, 255, 255, 0.7)',
};

export const font_sizes = {
  level1Title: 20,
  level2Title: 18,
  level3Title: 16,
  level4Title: 15,
};

export default StyleSheet.create({
  appContainer: {
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundColor: colors.bg_gray,
    width: '100%',
  },
  level4label: {
    marginTop: '3%',
    fontSize: font_sizes.level4Title,
    fontWeight: 'bold',
    marginBottom: 4,
    textAlign: 'left',
  },
  level4value: {
    fontSize: font_sizes.level4Title,
    marginLeft: 10,
    margin: '2%',
    textAlign: 'left',
  },
  archetypeMenuContainer: {
    margin: '2%',
    padding: '1%',
    paddingLeft: '4%',
    paddingRight: '4%',
    border: '1px solid',
    borderColor: 'rgb(230, 230, 230)',
    borderRadius: 5,
  },
  level1Container: {
    paddingTop: '0.5%',
  },
  sidebarContainer: {
    height: window.innerHeight,
    position: 'fixed',
    backgroundColor: colors.navbar_blue,
  },
  patientDataContainer: {
    textAlign: 'center',
    backgroundColor: colors.navbar_btn,
  },
  sideBarTitle: {
    backgroundColor: colors.navbar_blue,
    marginTop: 0,
    textAlign: 'center',
    padding: '5%',
    color: colors.light_gray,
    marginBottom: 0,
  },
  signOutText: {
    color: colors.light_green,
    fontWeight: 'bold',
    fontSize: 18,
  },
  signOutContainer: {
    backgroundColor: 'white',
    flex: 0.1,
    height: '100%',
    justifyContent: 'center',
    paddingRight: '1%',
    paddingLeft: '1%',
  },
});
