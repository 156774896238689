/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { colors } from 'configs';

function NewButton({
  children, variant, color, onClick, ...rest
}) {
  let customize = { root: {} };
  if (variant === 'contained') {
    customize = {
      root: {
        backgroundColor: colors.accentColor,
        borderColor: `${colors.accentColor}`,
        color: 'white',
        '&:hover': {
          // filter: 'brightness(50%)',
          backgroundColor: colors.accentColorSoft,
          color: 'white',
        },
        '&:focus': {
          filter: 'brightness(100%)',
          borderColor: colors.accentColor,
        },
        '&:active': {
          filter: 'brightness(100%)',
          borderColor: colors.accentColor,
        },
      },
    };
  } else if (variant === 'outlined') {
    customize = {
      root: {
        backgroundColor: 'white',
        boxSizing: 'border-box',
        // '-moz-box-sizing': 'border-box',
        // '-webkit-box-sizing': 'border-box',
        border: `1px solid ${colors.accentColor}`,
        color: colors.accentColor,
        '&:hover': {
          filter: 'constrast(70%) saturation(100%)',
        },
        '&:focus': {
          filter: 'constrast(70%) saturation(100%)',
          // borderColor: colors.accentColor,
        },
        '&:active': {
          filter: 'constrast(70%) saturation(100%)',
          // borderColor: colors.accentColor,
        },
      },
    };
  } else if (variant === 'link') {
    customize = {
      root: {
        height: '6%',
        backgroundColor: 'white',
        // boxSizing: 'border-box',
        // '-moz-box-sizing': 'border-box',
        // '-webkit-box-sizing': 'border-box',
        // border: `1px solid ${colors.accentColor}`,
        color: colors.accentColorSoft,
        '&:hover': {
          filter: 'constrast(70%) saturation(100%)',
          color: colors.accentColorDarker,
          backgroundColor: 'white',
        },
        '&:focus': {
          filter: 'constrast(70%) saturation(100%)',
        },
        '&:active': {
          filter: 'constrast(70%) saturation(100%)',
        },
      },
    };
  } else if (variant === 'govBR') {
    customize = {
      root: {
        backgroundColor: 'white',
        boxSizing: 'border-box',
        // '-moz-box-sizing': 'border-box',
        // '-webkit-box-sizing': 'border-box',
        border: `1px solid ${colors.govBr}`,
        color: colors.govBr,
        '&:hover': {
          filter: 'constrast(10%) saturation(100%)',
          backgroundColor: colors.govBr,
        },
        '&:focus': {
          filter: 'constrast(70%) saturation(100%)',
          // borderColor: colors.accentColor,
        },
        '&:active': {
          filter: 'constrast(70%) saturation(100%)',
          // borderColor: colors.accentColor,
        },
      },
    };
  }

  const useStyles = makeStyles(customize);

  const classes = useStyles();

  if (color === 'custom') {
    return (
      <Button
        {...rest}
        onClick={onClick}
        classes={{
          root: classes.root, // class name, e.g. `classes-nesting-root-x`
          // label: classes.label, // class name, e.g. `classes-nesting-label-x`
        }}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      {...rest}
      variant={variant}
      color={color}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export { NewButton as Button };
