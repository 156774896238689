import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/named
import { languagesList } from 'theme/settings';

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    ns: [
      'SearchPatient',
      'Region',
      'LoginPage',
      'PageMask',
      'PolicyPage',
      'Profile',
      'ConsentsPage',
      'PatientSummary',
      'Months',
    ],
    supportedLngs: languagesList,
    detection: {
      order: ['navigator', 'htmlTag'],
      caches: ['sessionStorage'],
    },

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: process.env.REACT_APP_i18N_TRANSLATION_PATH,
    },
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged',
    },
  });

export default i18n;
