import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Alert from '@material-ui/lab/Alert';

import { toast } from 'react-toastify';
import { add, addDays } from 'date-fns';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import {
  CNSisValid, formatName, logDebug, verifyCPF,
} from 'utils/AuxiliarFunctions';
import Appointment from 'utils/fhirObjects/Appointment';
import { oids } from 'utils/Constants';
import MHDRequests from 'services/MHDRequests';
import fhirRequests from 'services/fhirRequests';
import { features } from 'configs';
import eligibilityRequests from 'services/vaccination/eligibilityRequests';
import { localDateISO } from 'utils/dateFunctions';
import {
  Button, CityGroup, Loading, SimpleModal,
} from 'components';
import { useUserContext } from 'store/UserContext';
import { getEligibilityCode } from 'utils/eligibilityFunctions';

const useStyles = makeStyles(() => ({
  container: {
    padding: '3%',
  },
  idAndButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchButton: {
    height: '30px',
    margin: '5px',
    marginLeft: '10px',
  },
  dialogContent: {
    minHeight: '350px',
  },
  alertEleg1Dose: {
    fontSize: '1.125rem',
  },
}));

function ScheduleNow({
  locationID, onFinish, className, cityId,
}) {
  const [userContext] = useUserContext();
  const { userData } = userContext;
  const [open, setOpen] = useState(false);
  const [dose, setDose] = useState('1');
  const [patientId, setPatientId] = useState('');
  const [idValid, setIdValid] = useState(false);
  const [firstIdChange, setFirstIdChange] = useState(false);
  const [patientData, setPatientData] = useState('');
  const [eligibility, setEligibility] = useState({});
  const [loading, setLoading] = useState(false);
  const [idExist, setIdExist] = useState(false);
  const [hasAppointment, setHasAppointment] = useState(false);
  const [group, setGroup] = useState({});
  const classes = useStyles();
  const [alertToWithSchedule, setAlertToWithSchedule] = useState(false);
  const [modal, setModal] = useState({});

  const resetState = () => {
    setDose('1');
    setPatientId('');
    setIdValid(false);
    setFirstIdChange(false);
    setPatientData('');
    setLoading(false);
    setIdExist(false);
    setHasAppointment(false);
    setGroup({});
  };

  useEffect(() => {
  }, [alertToWithSchedule]);

  const getPatient = async () => {
    setIdExist(false);
    const patientJson = await MHDRequests.getPatientJson({ patientId });
    if (typeof patientJson === 'string') {
      setPatientData('Paciente não encontrado na base do Cartão Nacional de Saúde');
      setIdExist(false);
      return false;
    }
    const name = patientJson.name[0].text;
    const { birthDate, gender } = patientJson;
    const date = birthDate.split('T')[0].split('-');

    setPatientData(`${formatName(name)} - ${date[2]}/${date[1]}/${date[0]} - ${gender}`);
    setIdExist(true);
    return true;
  };

  useEffect(() => {
    if (!open) {
      setDose('1');
      setPatientId('');
      setIdValid(false);
      setFirstIdChange(false);
      setPatientData('');
      setLoading(false);
      setIdExist(false);
      setHasAppointment(false);
    }
  }, [open]);

  const checkAppointments = async () => {
    const start = localDateISO(new Date().toISOString().split('T')[0]);
    const end = localDateISO(addDays(new Date(), 1).toISOString().split('T')[0]);
    const appointmentResp = await fhirRequests.getAppointments({
      location: locationID, patientId, start, end,
    });
    if (typeof appointmentResp === 'string') toast.error('Houve um erro');
    else if (appointmentResp.total === 0) setHasAppointment(false);
    else {
      const filteredAppointments = appointmentResp?.entry?.filter((item) => item.resource.status !== 'cancelled');
      if (filteredAppointments.length > 0) {
        toast.warn('O paciente já foi agendado hoje');
        setHasAppointment(true);
      } else setHasAppointment(false);
    }
  };

  // const getGroupByCode = async (code) => {
  //   let groupCode = code;
  //   if (!code) {
  //     groupCode = await fhirRequests.getGroups({ cityId, patientId });
  //     if (typeof groupCode !== 'string') {
  //       if (groupCode.entry) {
  //         groupCode = groupCode.entry[0].resource.code.coding[0];
  //         setGroup(groupCode);
  //         return;
  //       } return;
  //     } toast.error('Houve um problema ao obter grupos do paciente');
  //   }

  //   const allGroups = await fhirRequests.getTerminology('1.3.6.1.4.1.54413.1.1.6.15');

  //   if (typeof allGroups === 'string') {
  //     toast.error('Houve um problema');
  //     logDebug('Problema ao pega terminologia de grupo');
  //     return;
  //   }
  //   const groupObj = allGroups.filter((item) => item.code === groupCode)[0];
  //   setGroup(groupObj);
  // };

  const verifyEligibility = async () => {
    const eligibilityResp = features.mockedEligibility.enabled
      ? features.mockedEligibility.value
      : await eligibilityRequests.getEligibility(patientId, cityId);
    if (typeof eligibilityResp !== 'string') {
      setEligibility(eligibilityResp);
      try {
        if (eligibilityResp?.grupoAtendimento) {
          setGroup({
            code: eligibilityResp?.grupoAtendimento,
            system: 'http://www.saude.gov.br/fhir/r4/CodeSystem/BRGrupoAtendimento',
          });
        } else setGroup(getEligibilityCode(eligibilityResp));
      } catch (err) {
        logDebug(`${err}: verifyEligibility->ScheduleNow`);
      }
      if (eligibilityResp.code === 50) setDose('2');
    } else toast.error('Houve um erro pesquisar elegibilidade do paciente');
  };

  const handleSubmitUserId = async () => {
    setLoading(true);
    const gotPatient = await getPatient();
    let hasScheduleToday;
    if (gotPatient) hasScheduleToday = await checkAppointments();
    else return;
    if (!hasScheduleToday) await verifyEligibility();
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetState();
    setOpen(false);
  };

  const handleChangeDose = (event) => {
    const selectedDose = event.target.value;
    setDose(selectedDose);
  };

  const handleFinishChoosingGroup = async (value) => {
    setLoading(true);
    setGroup(value);
    await verifyEligibility();
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      const userId = `${verifyCPF(patientId) ? oids.cpf : oids.cns}-${patientId}`;

      const eligibilityDescription = eligibility.description;
      if (eligibilityDescription === 'Já existe agendamento atual') {
        const cancelAppointment = {
          ...eligibility.resources[0],
          status: 'cancelled',
        };
        const responseCancelAppointment = await fhirRequests.putResource({
          id: cancelAppointment.id,
          type: 'Appointment',
          objectString: JSON.stringify(cancelAppointment),
        });
        if (typeof responseAppointment === 'string') {
          toast.error('Houve um problema ao tentar cancelar a vaga anterior');
          logDebug('Error ao cancelar o acolhimento anterior: ', responseCancelAppointment);
          return;
        }
      }
      // Não há slot neste caso, só para criar appointment corretamente
      const slot = {
        resource: {
          start: new Date().toISOString(),
          end: add(new Date(), { minutes: 30 }).toISOString(),
          status: 'busy',
        },
      };

      const appointmentObj = new Appointment({
        patientID: userId,
        locationID,
        dose,
        slot,
        group,
        adm: userData.mhd.id.split('-')[1],
        admExtension: eligibility.code === 30,
      });

      const appointmentResp = await appointmentObj.bookAppointment();
      if (typeof appointmentResp === 'string') {
        toast.error('Houve um problema ao tentar ocupar a vaga');
        logDebug('Error ao escrever slot');
        return;
      }
      toast.success('Agendamento realizado');
      onFinish();
    } catch (err) {
      logDebug('Error in handleSubmit', err);
    }
  };

  const verifySchedule = () => {
    try {
      const eligibilityDescription = eligibility.description;

      if (eligibilityDescription === 'Já existe agendamento atual') {
        setAlertToWithSchedule(true);
        setModal({
          title: 'Atenção!',
          body: 'Este paciente já possui agendamento. Se continuar, isto levará ao cancelamento do agendamento anterior.',
          footer: (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Continuar
            </Button>
          ),
          visible: true,
        });
      } else handleSubmit();
    } catch (err) {
      logDebug('modal erro: ', err);
    }
  };

  return (
    <div className={className}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Incluir na Lista de Atendimento
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={classes.container}>
          <DialogTitle id="form-dialog-title">Incluir na Lista de Atendimento</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {alertToWithSchedule && (
              <SimpleModal
                fullWidth={false}
                onCloseModal={false}
                setModal={() => setAlertToWithSchedule(false)}
                modal={modal}
              />
            )}
            <DialogContentText>
              Este tipo de agendamento não necessita de uma vaga.
            </DialogContentText>
            <div className={classes.idAndButton}>
              <form onSubmit={(event) => {
                event.preventDefault();
                handleSubmitUserId();
              }}
              >
                <TextField
                  id="outlined-basic"
                  autoFocus
                  margin="dense"
                  label="CPF ou CNS"
                  variant="outlined"
                  type="number"
                  fullWidth
                  onChange={(e) => {
                    setHasAppointment(true);
                    setPatientData('');
                    setEligibility({});
                    setGroup({});
                    if (!firstIdChange) setFirstIdChange(true);
                    if (!(!verifyCPF(e.target.value) && !CNSisValid(e.target.value))) {
                      setPatientId(String(e.target.value).trim());
                      setIdValid(true);
                    } else setIdValid(false);
                  }}
                  error={firstIdChange && !idValid}
                />

                <Button
                  color="custom"
                  type="submit"
                  disabled={!idValid || loading}
                  variant="contained"
                >
                  Buscar
                </Button>
              </form>
            </div>
            {/* </form> */}
            <br />
            <Divider variant="middle" />
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend">Dose</FormLabel>
              <RadioGroup aria-label="dose" name="dose" value={dose} onChange={handleChangeDose}>
                <FormControlLabel disabled={!eligibility.description} value="1" control={<Radio />} label="1ª Dose" />
                <FormControlLabel disabled={!eligibility.description} value="2" control={<Radio />} label="2ª Dose" />
              </RadioGroup>
            </FormControl>
            {eligibility.code === 40 && dose === '2'
            && (
            <Alert severity="warning" className={classes.alertEleg1Dose}>
              O paciente está elegível para tomar
              a 1ª dose. Deseja continuar?
            </Alert>
            )}
            <br />
            <Divider variant="middle" />
            <br />
            <CityGroup
              cityId={cityId}
              patientId={patientId}
              currentGroup={group}
              onFinish={handleFinishChoosingGroup}
              disabled={eligibility.code === 20 || eligibility.code === 40 || !eligibility.description}
            />
            {idValid && !loading && <p>{patientData}</p>}
            {idValid && !loading && eligibility.description && <p>{eligibility.description}</p>}
            {loading && <div style={{ textAlign: 'center', width: '100%' }}><Loading size="small" /></div>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button disabled={!idValid || !idExist || hasAppointment || loading || !eligibility.description || !group.code} onClick={verifySchedule} variant="contained" color="primary">
              Agendar
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

ScheduleNow.propTypes = {
  locationID: PropTypes.string.isRequired,
  className: PropTypes.string,
  cityId: PropTypes.string.isRequired,
  onFinish: PropTypes.func,
};

ScheduleNow.defaultProps = {
  onFinish: () => { },
  className: '',
};

export { ScheduleNow };
// { code: '1', name: '1a Dose', value: '1a Dose' }
