import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Column, Row } from 'simple-flexbox';
import { colors, font_sizes } from './Style';

const styles = StyleSheet.create({
  line: {
    backgroundColor: colors.line,
    height: 1,
    alignSelf: 'center',
    marginTop: '1%',
  },
  title: {
    color: colors.level3Title,
    fontSize: font_sizes.level3Title,
    marginTop: '5%',
    marginBottom: '2%',
  },
});

const DocContentTitle = (title) => (
  <Column style={{ width: '100%', marginTop: '1%' }}>
    <Row
      flexGrow={1}
      vertical="center"
      horizontal="spaced"
    >
      <Column flexGrow={1} className={css(styles.line)}>
        <div />
      </Column>
      <Column
        flexGrow={-1}
        style={{ marginLeft: '2%', marginRight: '2%' }}
      >
        <p className={css(styles.title)}>{title}</p>
      </Column>
      <Column flexGrow={1} className={css(styles.line)}>
        <div />
      </Column>
    </Row>
  </Column>
);

export default DocContentTitle;
