const INITIAL_STATE = {
    selectedId: 0,
    orgs: []
}

const professionalOrgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "LOAD":
            let loadedOrgs = {
                ...state,
                orgs: action.orgs
            };
            return loadedOrgs;
        case "CHANGE":
            return {
                ...state,
                selectedId: action.selectedId
            }
        default:
            return state;
    }
}

export default professionalOrgReducer;