import React, { useEffect } from 'react';

import i18n from 'i18n';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { currentProject, initialLanguage, features } from 'configs';
import Routes from 'navigation/Routes';
import VisualizerBypassRoutes from 'navigation/VisualizerBypassRoutes';
import { UserContextProvider } from 'store/UserContext';
import 'theme/global.css';

const getFaviconEl = () => document.getElementById('favicon');

const App = () => {
  const favicon = getFaviconEl();

  favicon.href = `${currentProject}/favicon.ico`;

  useEffect(() => {
    if (initialLanguage) i18n.changeLanguage(initialLanguage);
    else i18n.changeLanguage('pt-BR');
  }, []);

  return (
    <div className="container">
      <UserContextProvider>
        {features.bypassLoginPageWithVisualizer
          ? (<VisualizerBypassRoutes />) : (<Routes />)}
        <ToastContainer />
      </UserContextProvider>
    </div>
  );
};

export default App;
