/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import hash from 'object-hash';
import base64 from 'base-64';
import uuid from 'uuid';
import {
  retrieveData,
  fetchWithTimeout,
  verifyCPF,
  unformatCPFCNS,
  verifyUnauthorized,
  logDebug,
} from 'utils/AuxiliarFunctions';
// import MHDRequests from './MHDRequests';

import { filtersHistoryData } from 'utils/fhirParser';
import { endpoints, oids } from 'configs';
import {
  appc_consent,
  // appc_consent_request,
  appc_consent_request_fhir,
  appc_consent_request_fhir_org,
} from './requestTemplate';
import xdsToken from './xdsToken';

const { CONSENT_api, APPCFHIR } = endpoints;
const DEBUGMODE = false;
export default class consentRequests {
  static catchError = (err, from) => {
    logDebug(`ERROR in consentRequests: ${from}: `, err);
    switch (parseInt(err.message, 10)) {
      case 500:
        return 'internal_error';
      case 400:
        return 'bad_request';
      case 401:
        return 'unauthorized';
      case 403:
        return 'not_authorized';
      default:
        return 'network_error';
    }
  };

  static async getPatientCNES(patiendId) {
    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (DEBUGMODE) logDebug('consentRequests: getPatientCNES: token :', token);

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', `${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const url = `${CONSENT_api}/recurso/${patiendId}/instituicao`;
    const resp = fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => consentRequests.catchError(err, 'getPatientCNES'));
    verifyUnauthorized(resp);
    return resp;
  }

  static deepCopy(oldObj) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
      newObj =
        Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
      for (const i in oldObj) newObj[i] = consentRequests.deepCopy(oldObj[i]);
    }
    return newObj;
  }

  static async appc_request_org(rule, patient_id, subject_id, organization_id, organization_oid, cnes = null) {
    // console.log('(consentRequests)appc_request_org');
    // eslint-disable-next-line prefer-destructuring
    if (cnes) cnes = cnes[0];
    // eslint-disable-next-line prefer-destructuring
    if (subject_id) subject_id = subject_id[0];

    if (subject_id) subject_id = unformatCPFCNS(subject_id);

    // logDebug('cnes :>> ', cnes);
    // let multipleConsents = [];
    // if (subject_id) subject_id.map((elem) => multipleConsents.push(elem));
    // if (cnes) cnes.map((elem) => multipleConsents.push(elem));

    // multipleConsents = multipleConsents.filter((noNull) => noNull !== null);
    if (patient_id) patient_id = unformatCPFCNS(patient_id);

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = APPCFHIR;
    if (!token) return 'missing_token';

    const appc_doc = consentRequests.deepCopy(appc_consent);

    // const rootVal = `${verifyCPF(patient_id) ? oids.cpf : oids.cns}`;
    // const extensionVal = patient_id;

    const rootVal = `${verifyCPF(subject_id) ? oids.cpf : oids.cns}`;
    const extensionVal = subject_id;

    const resource_patient_id = [
      {
        Resource: {
          ResourceMatch: {
            $: {
              MatchId: 'urn:hl7-org:v3:function:II-equal',
            },
            AttributeValue: [
              {
                $: {
                  DataType: 'urn:hl7-org:v3#II',
                },
                'hl7:InstanceIdentifier': [
                  {
                    $: {
                      root: rootVal,
                      extension: extensionVal,
                    },
                  },
                ],
              },
            ],
            ResourceAttributeDesignator: [
              {
                $: {
                  DataType: 'urn:hl7-org:v3#II',
                  AttributeId: 'urn:ihe:iti:ser:2016:patient-id',
                },
              },
            ],
          },
        },
      },
    ];

    const subjects = [
      {
        Subject: {
          SubjectMatch: {
            $: {
              MatchId: 'urn:hl7-org:v3:function:II-equal',
            },
            AttributeValue: [
              {
                // _: subject_id,
                $: {
                  DataType: 'urn:hl7-org:v3#II',
                },
                'hl7:InstanceIdentifier': [
                  {
                    $: {
                      extension: organization_id,
                      root: organization_oid,
                    },
                  },
                ],
              },
            ],
            SubjectAttributeDesignator: [
              {
                $: {
                  AttributeId: organization_id
                    ? 'urn:oasis:names:tc:xspa:1.0:subject:organization-id'
                    : 'urn:oasis:names:tc:xacml:1.0:subject:subject-id',
                  DataType: 'http://www.w3.org/2001/XMLSchema#anyURI',
                },
              },
            ],
          },
        },
      },
    ];

    appc_doc.PolicySet.Target[0].Subjects[0] = subjects;
    // appc_doc.PolicySet.Target[0].Subjects[0] = subjectVect;
    appc_doc.PolicySet.Target[0].Resources[0] = resource_patient_id;

    const appc_uuid = `urn:uuid:${uuid.v1()}`;

    appc_doc.PolicySet.$.PolicySetId = appc_uuid;
    appc_doc.PolicySet.Description = '';
    appc_doc.PolicySet.Policy[0].$.PolicyId = `urn:uuid:${uuid.v1()}`;
    appc_doc.PolicySet.Policy[0].Rule[0].$.RuleId = `urn:uuid:${uuid.v1()}`;
    appc_doc.PolicySet.Policy[0].Rule[0].$.Effect = rule;

    const xml2js = require('xml2js');
    const builder = new xml2js.Builder();
    let xml = builder.buildObject(appc_doc);

    const content_id_doc = uuid.v1();
    xml = xml.replace(
      '<REPLACE_ME/>',
      `<urn:Document id="doc1"><inc:Include href="cid:${content_id_doc}" xmlns:inc="http://www.w3.org/2004/08/xop/include"/></urn:Document>`,
    );

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-token', `${token}`);
    myHeaders.append('Content-Type', 'application/fhir+xml');
    myHeaders.append('organization-id', `${organization_id}`);

    let newBody = appc_consent_request_fhir_org;
    const timeMili = new Date().getTime();
    let currentDateTimeISO = new Date();
    currentDateTimeISO = currentDateTimeISO.toISOString();

    const documentManifestUUID = uuid.v1(timeMili + Math.random() * 9999);
    const documentReferenceUUID = uuid.v1(timeMili + Math.random() * 9999);
    const encodedDocument = base64.encode(xml);

    const hashh = hash(encodedDocument);
    const docsize = hashh.length;

    const organizationId = organization_id;
    const organizationOID = oids.organization;

    const professionalId = patient_id;
    const substValues = [
      rootVal,
      subject_id,
      documentManifestUUID,
      organizationOID,
      organizationId,
      timeMili,
      currentDateTimeISO,
      documentReferenceUUID,
      docsize,
      hashh,
      encodedDocument,
      professionalId,
    ];

    for (let i = 12; i > 0; i -= 1) {
      const ra = new RegExp(`(${'\\$'}${i})+`, 'g');
      newBody = String(newBody).replace(ra, substValues[i - 1]);
    }

    const request = {
      method: 'POST',
      headers: myHeaders,
      body: newBody,
    };

    const APPC_response = await fetchWithTimeout(url, request)
      .then(async (response) => {
        if (response.status !== 200) throw new Error(response.status);
        const re = await response;
        return re;
      })
      .catch((err) => consentRequests.catchError(err, 'appc_request'));
    verifyUnauthorized(APPC_response);

    if (typeof APPC_response === 'string') return APPC_response;
    const data = await APPC_response.text();

    return { response: data };
  }

  static async appc_request(rule, patient_id, subject_id, cnes = null) {
    // eslint-disable-next-line prefer-destructuring
    if (cnes) cnes = cnes[0];
    // eslint-disable-next-line prefer-destructuring
    if (subject_id) subject_id = subject_id[0];

    if (subject_id) subject_id = unformatCPFCNS(subject_id);

    // logDebug('cnes :>> ', cnes);
    // let multipleConsents = [];
    // if (subject_id) subject_id.map((elem) => multipleConsents.push(elem));
    // if (cnes) cnes.map((elem) => multipleConsents.push(elem));

    // multipleConsents = multipleConsents.filter((noNull) => noNull !== null);
    if (patient_id) patient_id = unformatCPFCNS(patient_id);

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const url = APPCFHIR;
    if (!token) return 'missing_token';

    const appc_doc = consentRequests.deepCopy(appc_consent);

    const rootVal = `${verifyCPF(patient_id) ? oids.cpf : oids.cns}`;
    const extensionVal = patient_id;

    const resource_patient_id = [
      {
        Resource: {
          ResourceMatch: {
            $: {
              MatchId: 'urn:hl7-org:v3:function:II-equal',
            },
            AttributeValue: [
              {
                $: {
                  DataType: 'urn:hl7-org:v3#II',
                },
                'hl7:InstanceIdentifier': [
                  {
                    $: {
                      root: rootVal,
                      extension: extensionVal,
                    },
                  },
                ],
              },
            ],
            ResourceAttributeDesignator: [
              {
                $: {
                  DataType: 'urn:hl7-org:v3#II',
                  AttributeId: 'urn:ihe:iti:ser:2016:patient-id',
                },
              },
            ],
          },
        },
      },
    ];

    const subjects = [
      {
        Subject: {
          SubjectMatch: {
            $: {
              MatchId: 'urn:hl7-org:v3:function:II-equal',
            },
            AttributeValue: [
              {
                // _: subject_id,
                $: {
                  DataType: 'urn:hl7-org:v3#II',
                },
                'hl7:InstanceIdentifier': [
                  {
                    $: {
                      extension: cnes || subject_id,
                      root: cnes ? oids.organization : rootVal,
                    },
                  },
                ],
              },
            ],
            SubjectAttributeDesignator: [
              {
                $: {
                  AttributeId: cnes
                    ? 'urn:oasis:names:tc:xspa:1.0:subject:organization-id'
                    : 'urn:oasis:names:tc:xacml:1.0:subject:subject-id',
                  DataType: 'http://www.w3.org/2001/XMLSchema#anyURI',
                },
              },
            ],
          },
        },
      },
    ];

    appc_doc.PolicySet.Target[0].Subjects[0] = subjects;
    // appc_doc.PolicySet.Target[0].Subjects[0] = subjectVect;
    appc_doc.PolicySet.Target[0].Resources[0] = resource_patient_id;

    const appc_uuid = `urn:uuid:${uuid.v1()}`;

    appc_doc.PolicySet.$.PolicySetId = appc_uuid;
    appc_doc.PolicySet.Description = '';
    appc_doc.PolicySet.Policy[0].$.PolicyId = `urn:uuid:${uuid.v1()}`;
    appc_doc.PolicySet.Policy[0].Rule[0].$.RuleId = `urn:uuid:${uuid.v1()}`;
    appc_doc.PolicySet.Policy[0].Rule[0].$.Effect = rule;

    const xml2js = require('xml2js');
    const builder = new xml2js.Builder();
    let xml = builder.buildObject(appc_doc);

    const content_id_doc = uuid.v1();
    xml = xml.replace(
      '<REPLACE_ME/>',
      `<urn:Document id="doc1"><inc:Include href="cid:${content_id_doc}" xmlns:inc="http://www.w3.org/2004/08/xop/include"/></urn:Document>`,
    );

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-token', `${token}`);
    myHeaders.append('Content-Type', 'application/fhir+xml');

    let newBody = appc_consent_request_fhir;
    const timeMili = new Date().getTime();
    let currentDateTimeISO = new Date();
    currentDateTimeISO = currentDateTimeISO.toISOString();

    const documentManifestUUID = uuid.v1(timeMili + Math.random() * 9999);
    const documentReferenceUUID = uuid.v1(timeMili + Math.random() * 9999);
    const encodedDocument = base64.encode(xml);

    const hashh = hash(encodedDocument);
    const docsize = hashh.length;

    const organizationId = cnes;
    const organizationOID = oids.organization;

    const substValues = [
      rootVal,
      patient_id,
      documentManifestUUID,
      organizationOID,
      organizationId,
      timeMili,
      currentDateTimeISO,
      documentReferenceUUID,
      docsize,
      hashh,
      encodedDocument,
    ];

    for (let i = 11; i > 0; i -= 1) {
      const ra = new RegExp(`(${'\\$'}${i})+`, 'g');
      newBody = String(newBody).replace(ra, substValues[i - 1]);
    }

    const request = {
      method: 'POST',
      headers: myHeaders,
      body: newBody,
    };

    const APPC_response = await fetchWithTimeout(url, request)
      .then(async (response) => {
        if (response.status !== 200) throw new Error(response.status);
        const re = await response;
        return re;
      })
      .catch((err) => consentRequests.catchError(err, 'appc_request'));
    verifyUnauthorized(APPC_response);

    if (typeof APPC_response === 'string') return APPC_response;
    const data = await APPC_response.text();

    return { response: data };
  }

  static async getConsentReceived(patient_id) {
    const url = `${CONSENT_api}/sujeito/${patient_id}/consentimento`;
    if (DEBUGMODE) logDebug('url', url);

    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', `${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const consent_request = {
      headers: myHeaders,
    };

    if (DEBUGMODE) logDebug('consent_request', consent_request);
    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      consent_request,
    )
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => consentRequests.catchError(err, 'getConsentReceived'));
    verifyUnauthorized(fetchWithTimeout_response);

    if (typeof fetchWithTimeout_response === 'string')
      return fetchWithTimeout_response;

    const responseJson = await fetchWithTimeout_response.json();
    const consents = [];
    const consentsInstitution = [];

    for (const i in responseJson) {
      let consent;
      if (responseJson[i].acao === 'Autorizar') {
        const pat_id = responseJson[i].recurso.id;
        const year = responseJson[i].hora.split('-')[0];
        const month = responseJson[i].hora.split('-')[1];
        const day = responseJson[i].hora.split('-')[2].split('T')[0];
        const date = `${day}/${month}/${year}`;

        const pat_name = null;
        let institution = null;
        if (responseJson[i].recurso.id === null) {
          // pat_name = await MHDRequests.getOrganizationName(responseJson[i].contexto.instituicao);
          institution = responseJson[i].contexto.instituicao;
          consent = {
            pat_name,
            pat_id,
            date,
            institution,
          };
          consentsInstitution.push(consent);
        } else {
          // pat_name = await MHDRequests.getPatientJson(pat_id);
          // if (typeof pat_name !== 'string') {
          //   pat_name = pat_name.name[0].text;
          // }
          consent = {
            pat_name,
            pat_id,
            date,
          };
          consents.push(consent);
        }
      }
    }

    return [consents, consentsInstitution];
  }

  static async getReceivedConsents(patient_id) {
    const url = `${CONSENT_api}/sujeito/${patient_id}/consentimento`;
    if (DEBUGMODE) logDebug('url', url);

    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', `${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const consent_request = {
      headers: myHeaders,
    };

    if (DEBUGMODE) logDebug('consent_request', consent_request);
    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      consent_request,
    )
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => consentRequests.catchError(err, 'getConsentReceived'));
    verifyUnauthorized(fetchWithTimeout_response);

    if (typeof fetchWithTimeout_response === 'string')
      return fetchWithTimeout_response;

    return await fetchWithTimeout_response.json();
  }

  static async getConsents(patient_id) {
    const url = `${CONSENT_api}/recurso/${patient_id}/consentimento`;
    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (DEBUGMODE) logDebug(`CONSENT TOKEN: ${token}`);
    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', `${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const consent_request = {
      headers: myHeaders,
    };

    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      consent_request,
    )
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => consentRequests.catchError(err, 'getConsentGranted'));
    verifyUnauthorized(fetchWithTimeout_response);

    if (typeof fetchWithTimeout_response === 'string')
      return fetchWithTimeout_response;

    return await fetchWithTimeout_response.json();
  }

  static async getConsentGranted(patient_id) {
    const url = `${CONSENT_api}/recurso/${patient_id}/consentimento`;
    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (DEBUGMODE) logDebug(`CONSENT TOKEN: ${token}`);
    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', `${token}`);
    myHeaders.append('Content-Type', 'application/json');

    const consent_request = {
      headers: myHeaders,
    };

    const fetchWithTimeout_response = await fetchWithTimeout(
      url,
      consent_request,
    )
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response;
      })
      .catch((err) => consentRequests.catchError(err, 'getConsentGranted'));
    verifyUnauthorized(fetchWithTimeout_response);

    if (typeof fetchWithTimeout_response === 'string')
      return fetchWithTimeout_response;

    const responseJson = await fetchWithTimeout_response.json();

    const consents = [];
    const consentsInstitution = [];

    for (const i in responseJson) {
      let consent;
      if (responseJson[i].acao === 'Autorizar') {
        const pat_id = responseJson[i].sujeito.id;
        const year = responseJson[i].hora.split('-')[0];
        const month = responseJson[i].hora.split('-')[1];
        const day = responseJson[i].hora.split('-')[2].split('T')[0];
        const date = `${day}/${month}/${year}`;

        const pat_name = null;
        let institution = null;
        if (responseJson[i].sujeito.id === null) {
          // pat_name = await MHDRequests.getOrganizationName(responseJson[i].contexto.instituicao);
          institution = responseJson[i].contexto.instituicao;
          consent = {
            pat_name,
            pat_id: institution,
            date,
            institution,
          };
          consentsInstitution.push(consent);
        } else {
          // pat_name = await MHDRequests.getPatientJson(pat_id);
          // if (typeof pat_name !== 'string') {
          //   pat_name = pat_name.name[0].text;
          // }
          consent = {
            pat_name,
            pat_id,
            date,
          };
          consents.push(consent);
        }
      }
    }
    return [consents, consentsInstitution];
  }

  static async getHistory(
    subjectId = null,
    purpose = null,
    timeInit = null,
    timeEnd = null,
    count = null,
    offset = null,
    mine = false,
  ) {
    await xdsToken.getBearerToken();

    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', `${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const patientId = await retrieveData('username');

    let url = `${CONSENT_api}/recurso/${patientId}/autorizacao?resultado=Autorizado`;

    if (subjectId) url = `${url}&sujeitoId=${subjectId}`;
    if (purpose) url = `${url}&=${purpose}`;
    if (timeInit) url = `${url}&hora=gt${timeInit}`;
    if (timeEnd) url = `${url}&hora=lt${timeEnd}`;
    if (count) url = `${url}&count=${count}`;
    if (offset) url = `${url}&offset=${offset}`;

    let resp = await fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => consentRequests.catchError(err, 'getHistory'));
    verifyUnauthorized(resp);

    if (typeof resp === 'string') return [resp];

    if (!mine) resp = filtersHistoryData(resp);
    return [resp, offset + count];
  }

  static async getSelfHistory(
    subjectId = null,
    purpose = null,
    timeInit = null,
    timeEnd = null,
    count = null,
    offset = null,
  ) {
    return consentRequests.getHistory(
      subjectId,
      purpose,
      timeInit,
      timeEnd,
      count,
      offset,
      true,
    );
  }
}
