/* eslint-disable max-len */
/* eslint-disable camelcase */
// export const DEBUGMODE = false; // change to false when finishing DEBUG
export const DEBUGMODE = false; // change to false when finishing DEBUG

export const namesLowerCase = ['De', 'Da', 'Do', 'E', 'Dos', 'Das'];

export const doc = [
  { id: '0', value: 'Resumo de consulta ab_CN1_v2.0.oet', display: 'Resumo de consulta' },
  { id: '1', value: 'Sumário de alta de internação_CN2_v.2', display: 'Sumário de alta de internação' },
  { id: '2', value: 'Sumário de saúde do paciente_CN3_v2.0', display: 'Sumário de saúde do paciente' },
  { id: '3', value: 'RegistroAtendimentoClinico_v2', display: 'Registro Atendimento Clínico' },
  { id: '4', value: 'Referencia_CN5_v2', display: 'Referência' },
  { id: '5', value: 'Registro de Imunobiológico_CN6_v2', display: 'Registro de Imunobiológico' },
  { id: '6', value: 'Resultado de Exames_CN7_v2', display: 'Resultado de Exames' },
];

export const oids = {
  organization: '2.16.840.1.113883.13.36',
  cpf: '2.16.840.1.113883.13.237',
  cns: '2.16.840.1.113883.13.236',
  practitionerRoles: '1.3.6.1.4.1.54413.1.1.2.1',
  programHealthCareService: '1.3.6.1.4.1.54413.1.1.2.2',
  categoryHealthCareService: '1.3.6.1.4.1.54413.1.1.6.8',
  susAttendance: '1.3.6.1.4.1.54413.1.1.6.7',
  admType: '1.3.6.1.4.1.54413.1.1.6.6',
  legalNature: '1.3.6.1.4.1.54413.1.1.6.4',
  orgType: '1.3.6.1.4.1.54413.1.1.6.3',
  immunobiological: '1.3.6.1.4.1.54413.1.1.6.10',
  doseCovidVaccine: '1.3.6.1.4.1.54413.1.1.6.14',
  immunizationEffects: '1.3.6.1.4.1.54413.1.1.6.13',
  documentPdf: '1.3.6.1.4.1.54413.1.1.2.3.1',
  council: '2.16.840.1.113883.13.243',
};

export const vaccineCodes = ['85', '86', '87', '88'];
export const vaccineManufacturers = [
  {
    code: '85',
    manufacturer: 'Astrazeneca do Brasil LTDA',
    org: '60318797000100',
  },
  {
    code: '86',
    manufacturer: 'Instituto Butantan',
    org: '61821344000156',
  },
  {
    code: '87',
    manufacturer: 'Laboratorios Pfizer LTDA',
    org: '46070868000169',
  },
  {
    code: '88',
    manufacturer: 'Janssen-cilag Farmaceutica LTDA',
    org: '51780468000187',
  },
];

export const statesBrConst = [
  {
    id: 11,
    sigla: 'RO',
    nome: 'Rondônia',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 12,
    sigla: 'AC',
    nome: 'Acre',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 13,
    sigla: 'AM',
    nome: 'Amazonas',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 14,
    sigla: 'RR',
    nome: 'Roraima',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 15,
    sigla: 'PA',
    nome: 'Pará',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 16,
    sigla: 'AP',
    nome: 'Amapá',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 17,
    sigla: 'TO',
    nome: 'Tocantins',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 21,
    sigla: 'MA',
    nome: 'Maranhão',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 22,
    sigla: 'PI',
    nome: 'Piauí',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 23,
    sigla: 'CE',
    nome: 'Ceará',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 24,
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 25,
    sigla: 'PB',
    nome: 'Paraíba',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 26,
    sigla: 'PE',
    nome: 'Pernambuco',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 27,
    sigla: 'AL',
    nome: 'Alagoas',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 28,
    sigla: 'SE',
    nome: 'Sergipe',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 29,
    sigla: 'BA',
    nome: 'Bahia',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 31,
    sigla: 'MG',
    nome: 'Minas Gerais',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 32,
    sigla: 'ES',
    nome: 'Espírito Santo',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 33,
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 35,
    sigla: 'SP',
    nome: 'São Paulo',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 41,
    sigla: 'PR',
    nome: 'Paraná',
    regiao: {
      id: 4,
      sigla: 'S',
      nome: 'Sul',
    },
  },
  {
    id: 42,
    sigla: 'SC',
    nome: 'Santa Catarina',
    regiao: {
      id: 4,
      sigla: 'S',
      nome: 'Sul',
    },
  },
  {
    id: 43,
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
    regiao: {
      id: 4,
      sigla: 'S',
      nome: 'Sul',
    },
  },
  {
    id: 50,
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
  {
    id: 51,
    sigla: 'MT',
    nome: 'Mato Grosso',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
  {
    id: 52,
    sigla: 'GO',
    nome: 'Goiás',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
  {
    id: 53,
    sigla: 'DF',
    nome: 'Distrito Federal',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
];
