import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { logDebug } from 'utils/AuxiliarFunctions';
import { useTranslation } from 'react-i18next';

function HospitalizationsTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const headers = [t('myRegisters-patientSummary-healthSummary-tab-column-code'), t('myRegisters-patientSummary-healthSummary-tab-column-description'), t('myRegisters-patientSummary-healthSummary-tab-column-date')];
  const rows = documents.map((document) => {
    let description = 'Não Informado';
    let code = null;
    try {
      const name = document.extension[0].valueCodeableConcept;
      description = name.text;
      const cid10 = name.coding[0].code;
      code = cid10;
    } catch (err) {
      description = 'Não Informado';
      logDebug('HospitalizationsTab :>>', err);
    }
    return ({
      id: document.id,
      description,
      code,
      source: document.masterIdentifier.value,
      date: formatDateDisplay(document.context.period.start),
    });
  });

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((document) => (
            <Tooltip
              key={document.id}
              placement="top"
              arrow
              title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
            >
              <TableRow
                hover
                onClick={() => selectReport(document.source)}
              >
                <TableCell padding="checkbox">{document.code}</TableCell>
                <TableCell>{document.description || t('tabsInfo-patientSummary-healthSummary-tab-hospitalizations-description-not-found')}</TableCell>
                <TableCell padding="checkbox">{document.date}</TableCell>
              </TableRow>
            </Tooltip>
          ))
        }
      </TableBody>
    </Table>
  );
}

HospitalizationsTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { HospitalizationsTabContent };
