import React from 'react';
import { IconContext } from 'react-icons';
import { FiFileMinus } from 'react-icons/fi';
import './NoReports.css';
import { colors } from 'configs';
import { useTranslation } from 'react-i18next';

const NoReports = () => {
  const { t } = useTranslation('PatientSummary');

  return (
    <div className="no-reports-container" style={{ color: colors.accentColor }}>
      <IconContext.Provider className="no-reports-icon" value={{ color: colors.accentColor, size: '25%' }}>
        <FiFileMinus />
      </IconContext.Provider>
      <p>{t('PatientSummary-noReports')}</p>
    </div>
  );
};

export { NoReports };
