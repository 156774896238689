/**
 * This function is responsible for making the fetch
 * @param {string} path - url for the request
 * @param {string} doc_req - method and options for the request
 */

import { endpoints } from 'configs';

const { ADMFHIR } = endpoints;

const makeRequest = async function (path, doc_req, access_token) {
  const url = `${ADMFHIR}/${path}`;
  doc_req.headers.Authorization = `Bearer ${access_token}`;
  const res = await fetch(url, doc_req)
    .then((response) => {
      if ((response.status !== 200 && response.status !== 204 && response.status !== 201)
      || !response.ok) throw new Error(response.status);
      return (response.json());
    })
    .catch((err) => {
      switch (err.message) {
        case ('500'):
          return 'internal_error';
        case ('504'):
          return 'timeout';
        case ('400'):
          return 'bad_request';
        case ('403'):
          return 'forbidden';
        case ('404'):
          return 'not_found';
        case ('409'):
          return 'conflict';
        case ('410'):
          return 'gone';
        default:
          return ('network_error');
      }
    });
  return res;
};

export default makeRequest;
