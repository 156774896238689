/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, MenuItem, TextField,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { citiesBrRequest } from 'utils/externalAPIs';
import { Loading } from 'components';

const useStyles = makeStyles({
  muiInputs: {
    width: '100%',
    flexGrow: 1,
  },
});

const CityPicker = ({
  onChooseCity, ufID, disabled, errors, control, register, value,
}) => {
  const [citiesList, setCitiesList] = useState([]);
  const [disabledDrop, setDisabledDrop] = useState(disabled);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [city, setCity] = useState(value);

  useEffect(() => {
    const getCitiesList = async () => {
      const cities = await citiesBrRequest(ufID);
      if (!cities) return null;
      setCitiesList(cities);
      setDisabledDrop(disabled);
      setLoading(false);
      return null;
    };
    if (ufID) getCitiesList();
    else {
      setDisabledDrop(true);
      setLoading(false);
    }
  }, [ufID, disabled]);

  if (loading) return <Loading size="mini" />;

  return (
    <div>
      <Controller
        name="city"
        control={control}
        defaultValue=""
        render={({ onChange }) => (
          <div>
            <TextField
              autoComplete="off"
              className={classes.muiInputs}
              label="Cidade"
              placeholder="Cidade"
              value={city && city.split('-')[1]}
              ref={register}
              select={!value}
              onChange={(e) => {
                onChange(e.target.value);
                onChooseCity(e.target.value);
                setCity(e.target.value);
              }}
              disabled={disabledDrop}
              variant="outlined"
              error={!!errors?.['city']}
              helperText={errors?.['city']?.message}
            >
              {!value && citiesList.map((item) => <MenuItem key={item.id} value={`${item.id}-${item.nome}`}>{item.nome}</MenuItem>)}
            </TextField>
          </div>
        )}
      />
    </div>
  );
};
CityPicker.propTypes = {
  onChooseCity: PropTypes.func.isRequired,
  ufID: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({
    city: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  control: PropTypes.any.isRequired,
  register: PropTypes.any.isRequired,
  value: PropTypes.string,
};

CityPicker.defaultProps = {
  disabled: false,
  value: '',
};

export { CityPicker };
