import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { RecoverPass } from '../RecoverPass/RecoverPass';
import { LoginContent } from './LoginContent';

import './Login.css';

function Login({ bypassLogin }) {
  const [passRecovery, setPassRecovery] = useState();

  function callBackLogin() {
    setPassRecovery(false);
  }

  if (passRecovery) return <RecoverPass callbackFunc={() => callBackLogin()} />;

  return (
    <Grid container spacing={0}>
      <LoginContent setPassRecovery={setPassRecovery} bypassLogin={bypassLogin} />
    </Grid>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export { Login };
