/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Route, Switch, HashRouter, Redirect,
} from 'react-router-dom';
import { SignUp } from 'pages/SignUp';
import { Login } from 'pages/Login';
import { OAuthCallback } from 'pages/OAuthCallback';
import LoggedUserPortal from './LoggedUserPortal';


const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/login/oauth" component={() => <Login bypassLogin />} />
      <Route exact path="/callback" component={() => OAuthCallback('govbr')} />
      <Route exact path="/conectarecife/callback" component={() => OAuthCallback('conectarecife')} />      
      <Route exact path="/signup" component={SignUp} />

      <Route path={['/', '/:pageId']}>
        {() => (sessionStorage.getItem('userLogged') === 'true' ? (
          <LoggedUserPortal path="/" />
        ) : (
          <Redirect to="/login" />
        ))}
      </Route>
    </Switch>
  </HashRouter>
);

export default Routes;
