export const registroImunoBiologico = (
  {
    cnes,
    practitionerName,
    practitionerCns,
    timestamp,
    manufacturer,
    vaccine,
    dosage = {
      value: '1a Dose',
      code: '1',
    },
    grupoAtendimento = {
      value: 'Outros',
      code: '000926',
    },
    CBOCode = '',
    cnpjManufacturer,
    lote,
    creationDate,
  },
) => (
  `<?xml version="1.0" encoding="UTF-8"?>
  <ot:Registro_de_Imunobiológico xmlns:oe="http://schemas.openehr.org/v1"
      xmlns:ot="http://schemas.oceanehr.com/templates"
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      template_id="RegistroImunobiologico_FESF_v1.0">
      <ot:name>
          <ot:value>Registro de Imunobiológico</ot:value>
      </ot:name>
      <ot:language>
          <ot:terminology_id>
              <ot:value>ISO_639-1</ot:value>
          </ot:terminology_id>
          <ot:code_string>pt</ot:code_string>
      </ot:language>
      <ot:territory>
          <ot:terminology_id>
              <ot:value>ISO_3166-1</ot:value>
          </ot:terminology_id>
          <ot:code_string>BR</ot:code_string>
      </ot:territory>
      <ot:category>
          <ot:value>event</ot:value>
          <ot:defining_code>
              <ot:terminology_id>
                  <ot:value>openehr</ot:value>
              </ot:terminology_id>
              <ot:code_string>433</ot:code_string>
          </ot:defining_code>
      </ot:category>
      <ot:composer xsi:type="oe:PARTY_IDENTIFIED">
          <oe:identifiers>
              <oe:issuer>2.16.840.1.113883.13.236</oe:issuer>
              <oe:assigner>2.16.840.1.113883.13.236</oe:assigner>
              <oe:id>${practitionerCns}</oe:id>
              <oe:type>CNS</oe:type>
          </oe:identifiers>
      </ot:composer>
      <ot:context>
          <ot:start_time>
              <oe:value>${timestamp}</oe:value>
          </ot:start_time>
          <ot:setting>
              <oe:value>primary medical care</oe:value>
              <oe:defining_code>
                  <oe:terminology_id>
                      <oe:value>openehr</oe:value>
                  </oe:terminology_id>
                  <oe:code_string>228</oe:code_string>
              </oe:defining_code>
          </ot:setting>
      </ot:context>
      <ot:Caracterização_do_atendimento>
          <ot:name>
              <ot:value>Caracterização do atendimento</ot:value>
          </ot:name>
          <ot:Admissão_do_paciente>
              <ot:name>
                  <ot:value>Admissão do paciente</ot:value>
              </ot:name>
              <ot:language>
                  <ot:terminology_id>
                      <ot:value>ISO_639-1</ot:value>
                  </ot:terminology_id>
                  <ot:code_string>pt</ot:code_string>
              </ot:language>
              <ot:encoding>
                  <ot:terminology_id>
                      <ot:value>IANA_character-sets</ot:value>
                  </ot:terminology_id>
                  <ot:code_string>UTF-8</ot:code_string>
              </ot:encoding>
              <ot:subject xsi:type="oe:PARTY_SELF"/>
              <ot:data>
                  <ot:Modalidade_assistencial>
                      <ot:name>
                          <ot:value>Modalidade assistencial</ot:value>
                      </ot:name>
                      <ot:value>
                          <ot:value>Atenção primária</ot:value>
                          <ot:defining_code>
                              <ot:terminology_id>
                                  <ot:value>local</ot:value>
                              </ot:terminology_id>
                              <ot:code_string>at0.136</ot:code_string>
                          </ot:defining_code>
                      </ot:value>
                  </ot:Modalidade_assistencial>
                  <ot:Tipo_de_atendimento>
                      <ot:name>
                          <ot:value>Tipo de atendimento</ot:value>
                      </ot:name>
                      <ot:value>
                          <ot:value>Consulta agendada</ot:value>
                          <ot:defining_code>
                              <ot:terminology_id>
                                  <ot:value>local</ot:value>
                              </ot:terminology_id>
                              <ot:code_string>at0.144</ot:code_string>
                          </ot:defining_code>
                      </ot:value>
                  </ot:Tipo_de_atendimento>
                  <ot:Localização_atribuída_ao_paciente>
                      <ot:name>
                          <ot:value>Localização atribuída ao paciente</ot:value>
                      </ot:name>
                      <ot:Local_de_atendimento>
                          <ot:name>
                              <ot:value>Local de atendimento</ot:value>
                          </ot:name>
                          <ot:value>
                              <ot:value>Unidade básica de saúde (UBS)</ot:value>
                              <ot:defining_code>
                                  <ot:terminology_id>
                                      <ot:value>local</ot:value>
                                  </ot:terminology_id>
                                  <ot:code_string>at0.148</ot:code_string>
                              </ot:defining_code>
                          </ot:value>
                      </ot:Local_de_atendimento>
                      <ot:Instituição>
                          <ot:name>
                              <ot:value>Instituição</ot:value>
                          </ot:name>
                          <ot:CNES>
                              <ot:name>
                                  <ot:value>CNES</ot:value>
                              </ot:name>
                              <ot:value>
                                  <oe:value>${cnes}</oe:value>
                              </ot:value>
                          </ot:CNES>
                      </ot:Instituição>
                  </ot:Localização_atribuída_ao_paciente>
                  <ot:Profissional_responsável>
                      <ot:name>
                          <ot:value>Profissional responsável</ot:value>
                      </ot:name>
                      <ot:Nome_do_profissional_responsável>
                          <ot:name>
                              <ot:value>Nome do profissional responsável</ot:value>
                          </ot:name>
                          <ot:value>
                              <oe:value>${practitionerName}</oe:value>
                          </ot:value>
                      </ot:Nome_do_profissional_responsável>
                      <ot:CNS_do_profissional_responsável>
                          <ot:name>
                              <ot:value>CNS do profissional responsável</ot:value>
                          </ot:name>
                          <ot:value>
                              <oe:value>${practitionerCns}</oe:value>
                          </ot:value>
                      </ot:CNS_do_profissional_responsável>
                      <ot:CBO>
                          <ot:name>
                              <ot:value>CBO</ot:value>
                          </ot:name>
                          <ot:value>
                              <oe:value>${CBOCode || ''}</oe:value>
                          </ot:value>
                      </ot:CBO>
                  </ot:Profissional_responsável>
                  <ot:Hora_do_atendimento>
                      <ot:name>
                          <ot:value>Hora do atendimento</ot:value>
                      </ot:name>
                      <ot:value>
                          <oe:value>${creationDate}</oe:value>
                      </ot:value>
                  </ot:Hora_do_atendimento>
              </ot:data>
          </ot:Admissão_do_paciente>
      </ot:Caracterização_do_atendimento>
      <ot:Registo_da_imunização>
          <ot:name>
              <ot:value>Registo da imunização</ot:value>
          </ot:name>
          <ot:Gestão_de_medicação>
              <ot:name>
                  <ot:value>Gestão de medicação</ot:value>
              </ot:name>
              <ot:language>
                  <ot:terminology_id>
                      <ot:value>ISO_639-1</ot:value>
                  </ot:terminology_id>
                  <ot:code_string>pt</ot:code_string>
              </ot:language>
              <ot:encoding>
                  <ot:terminology_id>
                      <ot:value>IANA_character-sets</ot:value>
                  </ot:terminology_id>
                  <ot:code_string>UTF-8</ot:code_string>
              </ot:encoding>
              <ot:subject xsi:type="oe:PARTY_SELF"/>
              <ot:time>
                  <oe:value>${timestamp}</oe:value>
              </ot:time>
              <ot:description>
                  <ot:Medicamento>
                      <ot:name>
                          <ot:value>Medicamento</ot:value>
                      </ot:name>
                      <ot:Imunobiológico>
                          <ot:name>
                              <ot:value>Imunobiológico</ot:value>
                          </ot:name>
                          <ot:value>
                              <ot:value>${vaccine.display}</ot:value>
                              <ot:defining_code>
                                  <ot:terminology_id>
                                      <ot:value>1.3.6.1.4.1.54413.1.1.6.10</ot:value>
                                  </ot:terminology_id>
                                  <ot:code_string>${vaccine.code}</ot:code_string>
                              </ot:defining_code>
                          </ot:value>
                      </ot:Imunobiológico>
                      <ot:Fabricante_do_imunobiológico>
                          <ot:name>
                              <ot:value>Fabricante do imunobiológico</ot:value>
                          </ot:name>
                          <ot:value>
                              <oe:value>${manufacturer}</oe:value>
                          </ot:value>
                      </ot:Fabricante_do_imunobiológico>
                      <ot:CNPJ_do_fabricante>
                          <ot:name>
                              <ot:value>CNPJ do fabricante</ot:value>
                          </ot:name>
                          <ot:value>
                              <oe:value>${cnpjManufacturer}</oe:value>
                          </ot:value>
                      </ot:CNPJ_do_fabricante>
                      <ot:Código_do_lote_do_imunobiológico valueType="DV_TEXT">
                          <ot:name>
                              <ot:value>Código do lote do imunobiológico</ot:value>
                          </ot:name>
                          <ot:text_value>
                              <oe:value>${lote}</oe:value>
                          </ot:text_value>
                      </ot:Código_do_lote_do_imunobiológico>
                      <ot:Dose>
                          <ot:name>
                              <ot:value>Dose</ot:value>
                          </ot:name>
                          <ot:Dose_administrada_do_imunobiológico>
                              <ot:name>
                                  <ot:value>Dose administrada do imunobiológico</ot:value>
                              </ot:name>
                              <ot:value>
                                  <ot:value>${dosage.value}</ot:value>
                                  <ot:defining_code>
                                      <ot:terminology_id>
                                          <ot:value>1.3.6.1.4.1.54413.1.1.6.14</ot:value>
                                      </ot:terminology_id>
                                      <ot:code_string>${dosage.code}</ot:code_string>
                                  </ot:defining_code>
                              </ot:value>
                          </ot:Dose_administrada_do_imunobiológico>
                      </ot:Dose>
                  </ot:Medicamento>
              </ot:description>
              <ot:ism_transition>
                  <ot:current_state>
                      <oe:value>completed</oe:value>
                      <oe:defining_code>
                          <oe:terminology_id>
                              <oe:value>openehr</oe:value>
                          </oe:terminology_id>
                          <oe:code_string>532</oe:code_string>
                      </oe:defining_code>
                  </ot:current_state>
              </ot:ism_transition>
          </ot:Gestão_de_medicação>
          <ot:Sumário_de_imunização>
              <ot:name>
                  <ot:value>Sumário de imunização</ot:value>
              </ot:name>
              <ot:language>
                  <ot:terminology_id>
                      <ot:value>ISO_639-1</ot:value>
                  </ot:terminology_id>
                  <ot:code_string>pt</ot:code_string>
              </ot:language>
              <ot:encoding>
                  <ot:terminology_id>
                      <ot:value>IANA_character-sets</ot:value>
                  </ot:terminology_id>
                  <ot:code_string>UTF-8</ot:code_string>
              </ot:encoding>
              <ot:subject xsi:type="oe:PARTY_SELF"/>
              <ot:data>
                  <ot:Doença_infecciosa_ou_agente>
                      <ot:name>
                          <ot:value>Doença infecciosa ou agente</ot:value>
                      </ot:name>
                      <ot:value>
                          <oe:value>COVID-19</oe:value>
                      </ot:value>
                  </ot:Doença_infecciosa_ou_agente>
                  <ot:Grupo_de_atendimento>
                      <ot:name>
                          <ot:value>Grupo de atendimento</ot:value>
                      </ot:name>
                      <ot:value>
                          <ot:value>${grupoAtendimento.display}</ot:value>
                          <ot:defining_code>
                              <ot:terminology_id>
                                  <ot:value>1.3.6.1.4.1.54413.1.1.6.15</ot:value>
                              </ot:terminology_id>
                              <ot:code_string>${grupoAtendimento.code}</ot:code_string>
                          </ot:defining_code>
                      </ot:value>
                  </ot:Grupo_de_atendimento>
              </ot:data>
          </ot:Sumário_de_imunização>
      </ot:Registo_da_imunização>
  </ot:Registro_de_Imunobiológico>`

);

export const bundleVaccine = (
  patientIDOID,
  patientID,
  timestamp,
  practitionerCns,
  documentManifestUUID,
  documentReferenceUUID,
  practitionerName,
  cnes,
  cnesName,
  systemId,
  timemilli,
  RI1,
  returnDate,
  creationDate,
  mode,
  recoveredDocumentReferenceId,
) => (`<fhir:Bundle xmlns:fhir="http://hl7.org/fhir">
<fhir:meta>
   <fhir:profile value="http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle"/>
</fhir:meta>
<fhir:type value="transaction"/>
<fhir:entry>
   <fhir:resource>
      <fhir:Patient>
         <fhir:id value="${patientIDOID}-${patientID}"/>
         <fhir:identifier>
            <fhir:system value="urn:oid:${patientIDOID}"/>
            <fhir:value value="${patientID}"/>
         </fhir:identifier>
      </fhir:Patient>
   </fhir:resource>
   <fhir:request>
      <fhir:method value="PUT"/>
      <fhir:url value="Patient/${patientIDOID}-${patientID}"/>
   </fhir:request>
</fhir:entry>
<fhir:entry>
   <fhir:resource>
      <fhir:Practitioner>
         <fhir:id value="2.16.840.1.113883.13.236-${practitionerCns}"/>
         <fhir:identifier>
            <fhir:system value="urn:oid:2.16.840.1.113883.13.236"/>
            <fhir:value value="${practitionerCns}"/>
         </fhir:identifier>
      </fhir:Practitioner>
   </fhir:resource>
   <fhir:request>
      <fhir:method value="PUT"/>
      <fhir:url value="Practitioner/2.16.840.1.113883.13.236-${practitionerCns}"/>
   </fhir:request>
</fhir:entry>
<fhir:entry>
   <fhir:resource>
      <fhir:DocumentManifest>
         <fhir:id value="${documentManifestUUID}"/>
         <fhir:contained>
            <fhir:Practitioner>
               <fhir:id value="practitioner1"/>
               <fhir:identifier>
                  <fhir:system value="urn:oid:2.16.840.1.113883.13.236"/>
                  <fhir:value value="${practitionerCns}"/>
               </fhir:identifier>
               <fhir:name>
                  <fhir:given value="${practitionerName}"/>
               </fhir:name>
            </fhir:Practitioner>
         </fhir:contained>
         <fhir:contained>
            <fhir:Organization>
               <fhir:id value="organization1"/>
               <fhir:identifier>
                  <fhir:system value="urn:oid:2.16.840.1.113883.13.36"/>
                  <fhir:value value="${cnes}"/>
               </fhir:identifier>
               <fhir:name value="${cnesName}"/>
            </fhir:Organization>
         </fhir:contained>
         <fhir:masterIdentifier>
            <fhir:system value="urn:ietf:rfc:3986"/>
            <fhir:value value="urn:uuid:${systemId}.${cnes}.3.${timemilli}.1"/>
         </fhir:masterIdentifier>
         <fhir:identifier>
            <fhir:use value="official"/>
            <fhir:system value="urn:ietf:rfc:3986"/>
            <fhir:value value="urn:uuid:${documentManifestUUID}"/>
         </fhir:identifier>
         <fhir:status value="current"/>
         <fhir:type>
            <fhir:coding>
               <fhir:system value="2.16.840.1.113883.6.96"/>
               <fhir:code value="404684003"/>
               <fhir:display value="Clinical finding"/>
            </fhir:coding>
            <fhir:text value="Clinical finding"/>
         </fhir:type>
         <fhir:subject>
            <fhir:reference value="Patient/${patientIDOID}-${patientID}"/>
         </fhir:subject>
         <fhir:created value="${timestamp}"/>
         <fhir:author>
            <fhir:reference value="#practitioner1"/>
         </fhir:author>
         <fhir:author>
            <fhir:reference value="#organization1"/>
         </fhir:author>
         <fhir:source value="urn:oid:1.3.6.1.4.1.54413.1.1.5.7"/>
         <fhir:content>
            <fhir:reference value="DocumentReference/${documentReferenceUUID}"/>
         </fhir:content>
      </fhir:DocumentManifest>
   </fhir:resource>
   <fhir:request>
      <fhir:method value="PUT"/>
      <fhir:url value="DocumentManifest/${documentManifestUUID}"/>
   </fhir:request>
</fhir:entry>
<fhir:entry>
   <fhir:resource>
      <fhir:DocumentReference>
         <fhir:id value="${documentReferenceUUID}"/>
         <fhir:contained>
            <fhir:Practitioner>
               <fhir:id value="practitioner1"/>
               <fhir:identifier>
                  <fhir:system value="urn:oid:2.16.840.1.113883.13.236"/>
                  <fhir:value value="${practitionerCns}"/>
               </fhir:identifier>
               <fhir:name>
                  <fhir:given value="${practitionerName}"/>
               </fhir:name>
            </fhir:Practitioner>
         </fhir:contained>
         <fhir:contained>
            <fhir:Organization>
               <fhir:id value="organization1"/>
               <fhir:identifier>
                  <fhir:system value="urn:oid:2.16.840.1.113883.13.36"/>
                  <fhir:value value="${cnes}"/>
               </fhir:identifier>
               <fhir:name value="${cnesName}"/>
            </fhir:Organization>
         </fhir:contained>
         <fhir:contained>
            <fhir:Patient>
               <fhir:id value="patient1"/>
               <fhir:identifier>
                  <fhir:system value="urn:oid:${patientIDOID}"/>
                  <fhir:value value="${patientID}"/>
               </fhir:identifier>
            </fhir:Patient>
         </fhir:contained>${returnDate ? (`
         <extension url="https://ehrrunner.com/fhir/extensions/returnDate">
            <valueDateTime value="${returnDate}"/>
         </extension>`) : ''}
         <fhir:masterIdentifier>
            <fhir:system value="urn:ietf:rfc:3986"/>
            <fhir:value value="urn:uuid:${systemId}.${cnes}.3.${timemilli}.2"/>
         </fhir:masterIdentifier>
         <fhir:identifier>
            <fhir:use value="official"/>
            <fhir:system value="urn:ietf:rfc:3986"/>
            <fhir:value value="urn:uuid:${documentReferenceUUID}"/>
         </fhir:identifier>
         <fhir:status value="current"/>
         <fhir:type>
            <fhir:coding>
               <fhir:system value="1.3.6.1.4.1.54413.1.1.6.2"/>
               <fhir:code value="RegistroImunobiologico_FESF_v1.0"/>
               <fhir:display value="Template Data Schema"/>
            </fhir:coding>
         </fhir:type>
         <fhir:category>
            <fhir:coding>
               <fhir:system value="1.3.6.1.4.1.54413.1.1.6.2"/>
               <fhir:code value="RegistroImunobiologico_FESF_v1.0"/>
               <fhir:display value="Template Data Schema"/>
            </fhir:coding>
         </fhir:category>
         <fhir:subject>
            <fhir:reference value="Patient/${patientIDOID}-${patientID}"/>
         </fhir:subject>
         <fhir:date value="${creationDate}"/>
         <fhir:author>
            <fhir:reference value="#practitioner1"/>
         </fhir:author>
         <fhir:author>
            <fhir:reference value="Practitioner/2.16.840.1.113883.13.236-${practitionerCns}"/>
         </fhir:author>
         <fhir:author>
            <fhir:reference value="#organization1"/>
         </fhir:author>
         <fhir:securityLabel>${mode !== 'editing' ? '' : `
         <relatesTo>
            <code value="replaces"/>
            <target>
                <reference value="DocumentReference/${recoveredDocumentReferenceId}"/>
            </target>
        </relatesTo>`}
            <fhir:coding>
               <fhir:system value="2.16.840.1.113883.5.25"/>
               <fhir:code value="N"/>
               <fhir:display value="Normal"/>
            </fhir:coding>
         </fhir:securityLabel>
         <fhir:content>
            <fhir:attachment>
               <fhir:contentType value="text/xml"/>
               <fhir:language value="pt-BR"/>
               <fhir:url value="Binary/${systemId}.${cnes}.3.${timemilli}.2"/>
               <fhir:creation value="${timestamp}"/>
            </fhir:attachment>
            <fhir:format>
               <fhir:system value="1.3.6.1.4.1.54413.1.1.6.2"/>
               <fhir:code value="tdd"/>
               <fhir:display value="Template Data Document"/>
            </fhir:format>
         </fhir:content>
         <fhir:context>
            <fhir:period>
               <fhir:start value="${timestamp}"/>
            </fhir:period>
            <fhir:sourcePatientInfo>
               <fhir:reference value="#patient1"/>
            </fhir:sourcePatientInfo>
         </fhir:context>
      </fhir:DocumentReference>
   </fhir:resource>
   <fhir:request>
      <fhir:method value="PUT"/>
      <fhir:url value="DocumentReference/${documentReferenceUUID}"/>
   </fhir:request>
</fhir:entry>
<fhir:entry>
   <fhir:resource>
      <fhir:Binary>
         <fhir:id value="${systemId}.${cnes}.3.${timemilli}.2"/>
         <fhir:contentType value="text/xml"/>
         <fhir:data value="${RI1}"/>
      </fhir:Binary>
   </fhir:resource>
   <fhir:request>
      <fhir:method value="PUT"/>
      <fhir:url value="Binary/${systemId}.${cnes}.3.${timemilli}.2"/>
   </fhir:request>
</fhir:entry>
</fhir:Bundle>`);
