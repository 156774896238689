import lodash from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import { formatDateDisplay } from 'utils/dateFunctions';
import { logDebug, translate } from 'utils/AuxiliarFunctions';
import './SearchBlock.css';

const initialState = { isLoading: false, results: [], value: '' };

const flatList = (list) => {
  const flatlist = list.map((result) => {
    let entries = {};
    Object.entries(result).forEach(([key, val]) => {
      if (typeof val === 'string') {
        entries = {
          ...entries, [key]: val,
        };
      } else if (typeof val === 'object') {
        Object.entries(val).forEach(([, valI]) => {
          Object.entries(valI).forEach(([lilKey, lilVal]) => {
            entries = {
              ...entries, [lilKey]: lilVal,
            };
          });
        });
      } else if (val) {
        val.forEach((v) => {
          Object.entries(v).forEach(([keyI, valI]) => {
            entries = {
              ...entries, [keyI]: valI,
            };
          });
        });
      }
    });
    return entries;
  });
  return flatlist;
};

const formatToSearch = (list) => {
  if (!list) return {};
  return list.map((li) => {
    let newList = {};
    const entries = Object.entries(li);
    newList = { ...newList, description: `${formatDateDisplay(li.date)} - ${translate(li.status)}` };
    entries.forEach(([key, val]) => {
      if (key === 'display') newList = { ...newList, title: val };
      else if (key === 'code') newList = { ...newList, code: val };
      else if (key === 'date') newList = { ...newList, date: formatDateDisplay(val) };
      else if (key === 'source') newList = { ...newList, source: val };
      else if (key === 'system') newList = { ...newList, system: val };
    });
    return newList;
  });
};

class SearchBlock extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleResultSelect = (e, { result }) => {
    const { selectReport } = this.props;
    this.setState({ value: result.title });
    selectReport(result.source, null, null);
  };

  handleSearchChange = (e, { value }) => {
    try {
      const { listToRender } = this.props;
      this.setState({ isLoading: true, value });
      setTimeout(() => {
        if (this.state.value.length < 1) return this.setState(initialState);

        const re = new RegExp(lodash._.escapeRegExp(this.state.value), 'i');
        const listFlat = flatList(listToRender);
        const filteredResults = listFlat.filter((result) => {
          const values = Object.values(result);
          return re.test(values.map((rootEntry) => rootEntry));
        });

        const formatedResults = formatToSearch(filteredResults);
        this.setState({
          results: [],
        });
        this.setState({
          results: formatedResults,
          isLoading: false,
        });
      }, 300);
    } catch (err) {
      logDebug('err :>> ', err);
    }
  }

  render() {
    const { isLoading, value, results } = this.state;
    const {
      size, placeholder,
    } = this.props;
    return (
      <Search
        // size?: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive'
        // size={'tiny'}
        size={size | 'tiny'}
        // category
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={lodash._.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        placeholder={placeholder}
        results={results}
        value={value}
      />
    );
  }
}

SearchBlock.propTypes = {
  listToRender: PropTypes.any.isRequired,
  selectReport: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export { SearchBlock };
